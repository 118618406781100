import React from 'react'
import Icon from '../icon'
import Text from '../text';

const PartnerServiceCard = (props) => {
    const {icon, text, description} = props;
    return (
        <div className="partner-service-card">
            <Icon icon={icon} />
            <Text type="H4" className="partner-service-text" text={text} />
            <Text type="PARAGRAPH" className="partner-service-desc" text={description} />
        </div>
    )
}

export default PartnerServiceCard