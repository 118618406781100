import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import ChevronDownIcon from "../../../../components/svg/chevronDownIcon";
import CloseIcon from "../../../../components/svg/closeIcon";
import SelectStyle from "../../../../components/input/selectStyle";
import Input from "../../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import {
  addBankAccountsFetch,
  getBankAccountsListFetch,
} from "../../../../redux/withdrawal/reducer";
import { saveBankDetails } from "../../../../redux/withdrawal/persistReducer";
import { FORMIK_REGEX } from "../../../../utils/constants";
import Loading from "../../../../components/svg/loading";

const accountTypeOption = [
  { value: "Saving Account", label: "Saving Account" },
  { value: "Current Account", label: "Current Account" },
];

const AddBank = (props) => {
  const dispatch = useDispatch();
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const handleAccountTypeChange = (selectedAccountType) => {
    setSelectedAccountType(selectedAccountType);
  };
  const { phoneNumber } = useSelector(
    (state) => state?.withdrawalPersistReducer
  );

  const { isLoading } = useSelector((state) => state?.withdrawalReducer);

  const getAccountsCallBack = () => {
    props.handleSubmit()
    dispatch(
      getBankAccountsListFetch({
        payload: { limit: 10, offset: 0 },
      })
    );
  };
  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      nameofCX: "",
      ifscCode: "",
      accountType: "",
      bankName: "",
      branchName: "",
    },
    validationSchema: Yup.object({
      accountNumber: Yup.string()
        .required("Please enter a account number")
        .matches(FORMIK_REGEX.ACCOUNT_NUMBER, "Enter valid Account Number"),
      nameofCX: Yup.string().required("Please enter a name of cx"),
      ifscCode: Yup.string()
        .required("Please enter a IFSC code")
        .matches(FORMIK_REGEX.IFSC_CODE, "Enter Valid IFSC Code"),
      accountType: Yup.string().required("Please enter a account type"),
      bankName: Yup.string().required("Please enter a bank name"),
      branchName: Yup.string().required("Please enter a branch name"),
    }),
    onSubmit: (values) => {
      dispatch(
        addBankAccountsFetch({
          values: {
            accountNumber: values?.accountNumber.toString(),
            IFSC: values?.ifscCode,
            accountHolderName: values?.nameofCX,
            bankName: values?.bankName,
            phoneNumber: phoneNumber?.mobileNumber,
            accountType: values?.accountType,
            branchName: values?.branchName,
            cx: values?.nameofCX,
          },
          callback: getAccountsCallBack,
        })
      );
      dispatch(saveBankDetails(values));
    },
  });
  return (
    <div className="flow-box">
      <form onSubmit={formik.handleSubmit} className="height-100">
        <Flex flexColumn justifyContent="between" className="p-4 height-100">
          <Flex flexColumn>
            <Button
              type="button"
              linkDark
              iconLeft={<ChevronDownIcon left={true} width="15" height="9" />}
              className="mback-btn"
              onClick={() => {
                props.goToBack()
              }}
            />
            <Button
              linkDark
              onClick={() => {
                props.goToBack()
              }}
              className="modal-close"
              text={<CloseIcon width={15} height={15} />}
            />
            <Text
              type="H3"
              text="Add Bank Account"
              className="text-center mb-30"
            />
            <Text type="LEGEND" text="Add Bank" />
            <Flex flexColumn>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-box">
                    <Input
                      label="Name"
                      type="text"
                      name="nameofCX"
                      placeholder=""
                      value={formik.values.nameofCX}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      mandatory
                      errormessage={
                        formik.touched.nameofCX && formik.errors.nameofCX
                          ? formik.errors.nameofCX
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-box">
                    <Input
                      label="Account Number"
                      name="accountNumber"
                      placeholder=""
                      value={formik.values.accountNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      mandatory
                      errormessage={
                        formik.touched.accountNumber &&
                          formik.errors.accountNumber
                          ? formik.errors.accountNumber
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-box">
                    <Input
                      label="IFSC Code"
                      type="text"
                      name="ifscCode"
                      placeholder=""
                      value={formik.values.ifscCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      mandatory
                      errormessage={
                        formik.touched.ifscCode && formik.errors.ifscCode
                          ? formik.errors.ifscCode
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-box">
                    <SelectStyle
                      mb0
                      isSearchable={true}
                      label="Account Type"
                      name="accountType"
                      value={selectedAccountType}
                      onChange={(selectedOption) => {
                        handleAccountTypeChange(selectedOption);
                        formik.handleChange("accountType")(
                          selectedOption.value
                        );
                      }}
                      options={accountTypeOption}
                      errormessage={
                        formik.touched.accountType && formik.errors.accountType
                          ? formik.errors.accountType
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-box">
                    <Input
                      label="Bank Name"
                      type="text"
                      name="bankName"
                      placeholder=""
                      value={formik.values.bankName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      mandatory
                      errormessage={
                        formik.touched.bankName && formik.errors.bankName
                          ? formik.errors.bankName
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-box">
                    <Input
                      label="Branch Name"
                      type="text"
                      name="branchName"
                      placeholder=""
                      value={formik.values.branchName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      mandatory
                      errormessage={
                        formik.touched.branchName && formik.errors.branchName
                          ? formik.errors.branchName
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </Flex>
          </Flex>
          <Flex flexColumn>
            <Flex className="mb-10" flexColumn>
              <Button type="submit" text={isLoading ? <Loading /> : "Add Bank & Proceed"} disabled={isLoading} />
            </Flex>
          </Flex>
        </Flex>
      </form>
    </div>
  );
};
export default AddBank;
