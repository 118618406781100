import React, { useEffect } from 'react'
import HomeView from './views/HomeView'
import Layout from '../include/layout'

const HomeController = (props) => {
    useEffect(() => {
        document.body.classList.add('home-page')
        return () => {
          document.body.classList.remove('home-page')
        }
    }, [])
    return (
        <Layout>
            <HomeView />
        </Layout>
    )
}

export default HomeController