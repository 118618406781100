
import React from 'react'

const ExportIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 12 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M6.50323 11.8768C6.50323 12.0878 6.33224 12.2588 6.1213 12.2588H5.43437C5.22343 12.2588 5.05244 12.0878 5.05244 11.8768V4.49339C5.05244 3.9697 4.41476 3.7125 4.05142 4.08965L1.71955 6.51013C1.56933 6.66606 1.31967 6.66606 1.16945 6.51013L0.662449 5.98386C0.519947 5.83595 0.519948 5.60181 0.66245 5.4539L5.50278 0.429637C5.653 0.273709 5.90267 0.273709 6.05289 0.429637L10.8932 5.4539C11.0357 5.60181 11.0357 5.83595 10.8932 5.98386L10.3862 6.51013C10.236 6.66606 9.98633 6.66606 9.83612 6.51013L7.50424 4.08965C7.14091 3.7125 6.50323 3.9697 6.50323 4.49339V11.8768Z" 
                fill="currentColor" 
                stroke="#5A3CAE" 
                strokeWidth="0.2"
            />
            <path 
                d="M0.481928 14.3866H11.0736C11.2846 14.3866 11.4556 14.5576 11.4556 14.7685V15.5182C11.4556 15.7291 11.2846 15.9001 11.0736 15.9001H0.481929C0.270996 15.9001 0.1 15.7291 0.1 15.5182V14.7685C0.1 14.5576 0.270995 14.3866 0.481928 14.3866Z" 
                fill="currentColor" 
                stroke="#5A3CAE" 
                strokeWidth="0.2"
            />
        </svg>
    )
}

export default ExportIcon

ExportIcon.defaultProps = {
    width: '12',
    height: '16',
}