import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import Flex from '../../../components/flex';
import Text from '../../../components/text';
import Input from '../../../components/input';
import Button from '../../../components/button';
import File from '../../../components/input/file';
import SelectStyle from '../../../components/input/selectStyle';
import Textarea from '../../../components/input/textarea';
// validations
import { useFormik } from 'formik';
import * as Yup from 'yup';
// redux
import { addTicket } from '../../../redux/withdrawal/reducer';

const issueTypeOption = [
  { value: 'Buy Crypto', label: 'Buy Crypto' },
  { value: 'Sell Crypto', label: 'Sell Crypto' },
  { value: 'Login Issue', label: 'Login Issue' },
  { value: 'KYC Issue', label: 'KYC Issue' },
  { value: 'Account Deletion', label: 'Account Deletion' },
  { value: 'Others', label: 'Others' },
];

const AddRaiseTicket = (props) => {
  const dispatch = useDispatch();
  const { profileDetails } = useSelector((state) => state?.userReducer);

  const [selectedIssueType, setSelectedIssueType] = useState('');
  const handleIssueTypeChange = (selectedIssueType) =>
    setSelectedIssueType(selectedIssueType);
    const handleFileChange = (event) => {
      const file = event.currentTarget.files[0];
      formik.setFieldValue("addAttachment", file);
    };
  const formik = useFormik({
    initialValues: {
      name: profileDetails?.userInfo?.fullName,
      issueType: '',
      phoneNumber: profileDetails?.userInfo?.phoneNumber,
      description: '',
      addAttachment: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter a your name'),
      issueType: Yup.string().required('Please enter a issue type'),
      phoneNumber: Yup.string().required('Please enter a phone number'),
      description: Yup.string().required('Please enter a description'),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("attachment", values.addAttachment);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("issueType", values.issueType);
      formData.append("description", values.description);
      formData.append("name", values.name);
      dispatch(
        addTicket({
          formData,
        })
      );
      props.handleSubmit();
    },
  });
  return (
    <Flex flexColumn alignItems="center" justifyContent="center">
      <Flex className="p-4">
        <Text type="H3" text="Raise Ticket" />
      </Flex>
      <div className="flow-box">
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column w-100 height-100"
        >
          <Flex flexColumn className="px-4">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-box">
                  <Input
                    label="Phone Number"
                    type="text"
                    name="phoneNumber"
                    placeholder="91 - 90000 10001"
                    value={ profileDetails?.userInfo?.phoneNumber || formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errormessage={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                    disabled={profileDetails?.userInfo?.phoneNumber}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-box">
                  <Input
                    label="Your Name"
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    value={profileDetails?.userInfo?.fullName || formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errormessage={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : null
                    }
                    disabled={profileDetails?.userInfo?.fullName}
                  />
                </div>
              </div>
            </div>
            <div className="form-box select-checkbox-field">
              <SelectStyle
                label="Please choose your issue type"
                isSearchable={true}
                name="issueType"
                value={selectedIssueType}
                onChange={(selectedOption) => {
                  handleIssueTypeChange(selectedOption);
                  formik.handleChange('issueType')(selectedOption.value);
                }}
                options={issueTypeOption}
                errormessage={
                  formik.touched.issueType && formik.errors.issueType
                    ? formik.errors.issueType
                    : null
                }
              />
            </div>
            <div className="form-box">
              <Textarea
                label="Description"
                rows={5}
                cols={10}
                name="description"
                placeholder="Write what your issue is about.."
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                resize="none"
                errormessage={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : null
                }
              />
            </div>
            <div className="form-box">
              <File
                label="Add attachment"
                type="file"
                name="addAttachment"
                placeholder=""
                value=""
                onChange={(event) => {
                  handleFileChange(event)
                }}               
                onBlur={formik.handleBlur}
                mandatory
                className="file-add-attachment"
              />
            </div>
          </Flex>
          <Flex flexColumn className="form-action-button p-4">
            <Button type="submit" text="Raise Ticket" />
          </Flex>
        </form>
      </div>
    </Flex>
  );
};

export default AddRaiseTicket;
