import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getPartnerDetailsFetch } from "../../redux/user/reducers";
import { FORMIK_REGEX } from "../../utils/constants";
import Button from "../button";
import Flex from "../flex";
import Input from "../input";
import Select from "../input/select";
import Textarea from "../input/textarea";
import Text from "../text";

const BecomeAPartnerForm = () => {
  const dispatch = useDispatch();

  const resetForm = () => {
    formik.resetForm();
    setTimeout(() => {
      formik.setErrors({});
    }, 1000);
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      companyName: "",
      companyWebsiteLink: "",
      companyEmail: "",
      contactDetails: "",
      integrateOption: "",
      primaryBlockchain: "",
      comment: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Please enter your Name")
        .min(3, "Minimum 3 letters needed"),
      companyName: Yup.string().required("Please enter your Company Name"),
      companyWebsiteLink: Yup.string().required(
        "Please enter your Company Website Link"
      ),
      companyEmail: Yup.string()
        .required("Please enter your Company Email")
        .matches(FORMIK_REGEX.EMAIL_REGEX, "Please enter a valid email"),
      contactDetails: Yup.string()
        .required("Please enter your Contact Details")
        .matches(
          FORMIK_REGEX.PHONE_NUMBER_REGEX,
          "Please enter a valid Phone Number"
        ),
      integrateOption: Yup.string(),
      primaryBlockchain: Yup.string().required(
        "Please enter your Primary Blockchain"
      ),
      comment: Yup.string(),
    }),
    onSubmit: (values) => {
      partnerSubmit(values);
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const partnerSubmit = (values) => {
    dispatch(
      getPartnerDetailsFetch({
        userDetails: {
          firstName: values?.firstName,
          contactDetails: values?.contactDetails,
        },
        companyDetails: {
          companyName: values?.companyName,
          companyWebsiteLink: values?.companyWebsiteLink,
          companyEmail: values?.companyEmail,
        },
        generalDetails: {
          integrationTimeLine: values?.integrateOption,
          primaryOperationalBlockchain: values?.primaryBlockchain,
          query: values?.comment,
        },
      })
    );
    resetForm();
  };

  const options = [
    {
      value: "",
      label: "Select",
    },
    {
      value: "1 Week",
      label: "1 Week",
    },
    {
      value: "3-4 Weeks",
      label: "3-4 Weeks",
    },
    {
      value: "1 Month",
      label: "1 Month",
    },
    {
      value: "3 Months",
      label: "3 Months",
    },
    {
      value: "6 Months - 1 Year",
      label: "6 Months - 1 Year",
    },
  ];
  return (
    <Flex flexColumn justifyContent="center">
      <div style={{ backgroundColor: "white", padding: 20, borderRadius: 20 }}>
        <Text
          type="H3"
          className="supprt-search-title"
          text="Interested in becoming a PayOnRamp partner?"
        />
        <form onSubmit={formik.handleSubmit}>
          <Input
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            mandatory
            errormessage={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          />
          <div className="row">
            <div className="col">
              <Input
                label="Company Name"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                mandatory
                errormessage={
                  formik.touched.companyName && formik.errors.companyName
                    ? formik.errors.companyName
                    : null
                }
              />
            </div>
            <div className="col">
              <Input
                label="Company Website Link"
                name="companyWebsiteLink"
                value={formik.values.companyWebsiteLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                mandatory
                errormessage={
                  formik.touched.companyWebsiteLink &&
                  formik.errors.companyWebsiteLink
                    ? formik.errors.companyWebsiteLink
                    : null
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                label="Company Email"
                name="companyEmail"
                value={formik.values.companyEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                mandatory
                errormessage={
                  formik.touched.companyEmail && formik.errors.companyEmail
                    ? formik.errors.companyEmail
                    : null
                }
              />
            </div>
            <div className="col">
              <Input
                label="Contact Details (Phone)"
                name="contactDetails"
                value={formik.values.contactDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="tel"
                mandatory
                errormessage={
                  formik.touched.contactDetails && formik.errors.contactDetails
                    ? formik.errors.contactDetails
                    : null
                }
              />
            </div>
          </div>
          <Select
            label="When can we integrate?"
            options={options}
            name="integrateOption"
            value={formik.values.integrateOption}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="What is the primary blockchain you operate on?"
            name="primaryBlockchain"
            value={formik.values.primaryBlockchain}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            mandatory
            errormessage={
              formik.touched.primaryBlockchain &&
              formik.errors.primaryBlockchain
                ? formik.errors.primaryBlockchain
                : null
            }
          />
          <Textarea
            label="How can we help your business? / Anything else we should know?"
            rows={5}
            cols={10}
            name="comment"
            type="text"
            value={formik.values.comment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            resize="none"
          />
          <Button text="Submit" type="submit" style={{ width: "100%" }} />
        </form>
      </div>
    </Flex>
  );
};

export default BecomeAPartnerForm;
