import React from "react";

const MediumIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={width}
      height={height}
      fill="currentColor"
    >
      <path d="M 17.011719 9.9824219 C 10.665719 10.025422 10.025422 10.502578 9.9824219 18.017578 L 10.003906 32.009766 C 10.025906 39.503766 10.502094 39.979953 17.996094 40.001953 L 32.009766 40.001953 C 39.394766 39.979953 39.979031 39.351766 39.957031 32.009766 L 40 17.996094 C 40 10.632094 39.396719 9.9863281 32.011719 9.9863281 L 17.011719 9.9824219 z M 16.011719 10.988281 L 33.011719 10.988281 C 38.447719 10.988281 39.003906 11.497078 39.003906 16.955078 L 39.003906 33.308594 C 39.003906 38.441594 38.527344 39.003906 33.027344 39.003906 L 17.021484 38.960938 C 11.498484 39.003938 11.043516 38.483422 10.978516 32.982422 L 11.021484 16.003906 C 10.999484 11.714906 11.788719 10.945281 16.011719 10.988281 z M 13.074219 15 L 13.074219 15.412109 L 14.607422 17.478516 C 14.683422 17.569516 14.724609 17.681828 14.724609 17.798828 L 14.724609 30.283203 C 14.724609 30.465203 14.662875 30.643156 14.546875 30.785156 L 12.25 33.625 L 12.25 34 L 18.849609 34 L 18.849609 33.623047 L 16.552734 30.767578 C 16.438734 30.626578 16.375 30.448578 16.375 30.267578 L 16.375 19.439453 L 23.226562 34 L 24.498047 34 L 24.505859 33.982422 L 30.400391 19.029297 L 30.400391 31.314453 C 30.400391 31.447453 30.346953 31.575922 30.251953 31.669922 L 27.925781 33.625 L 27.925781 34 L 37 34 L 37 33.625 L 35.498047 31.669922 C 35.403047 31.574922 35.349609 31.448406 35.349609 31.316406 L 35.349609 17.582031 C 35.349609 17.449031 35.403047 17.322516 35.498047 17.228516 L 37 15.412109 L 37 15 L 30.214844 15 L 25.447266 27.095703 L 19.755859 15 L 13.074219 15 z" />
    </svg>
  );
};

export default MediumIcon;

MediumIcon.defaultProps = {
  width: "25",
  height: "25",
};
