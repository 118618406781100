import React from 'react'
import Text from '../text';

const ViewBreakupCard = (props) => {
    const {lists, active} = props;
    return (
        <div className={`view-breakup-card ${active ? 'active' : ''}`}>
            <div className="view-breakup-main">
                {lists.map((item, id) => (
                    <div className="vb-row" key={id}>
                        <Text type="SPAN" className="vb-row-text" text={item.text} />
                        <Text type="SPAN" className="vb-row-number" text={item.number} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ViewBreakupCard