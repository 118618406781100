import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import ChevronDownIcon from "../../../../components/svg/chevronDownIcon";
import PhoneNumberInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import {
  initiateTransactionFetch,
  getPhoneNumberOtpFetch,
} from "../../../../redux/withdrawal/reducer";
import { APPLICATION_ID, FORMIK_REGEX } from "../../../../utils/constants";
import { saveUserLoginDetails } from "../../../../redux/user/persistReducer";

const BuyLogin = (props) => {
  const dispatch = useDispatch();
  const { sellData } = useSelector((state) => state?.withdrawalPersistReducer);
  const { sellReceiveData } = useSelector(
    (state) => state?.withdrawalPersistReducer

  );
  const { exchangeRateSell, sellMarkup, applicationId, dexQuote } = useSelector((state) => state?.userReducer);
  const { userPhoneNumber } = useSelector((state) => state?.withdrawalPersistReducer);
  const callback = (res) => {
    if (res.status === 200) {
      if (res?.data?.verifyUser === false) {
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        localStorage.setItem('expiry', res.data.data.expiry);
        dispatch(saveUserLoginDetails());
      } else {
        dispatch(
          getPhoneNumberOtpFetch({
            values: {
              phonenumber: res.data.phoneNumber || userPhoneNumber,
            },
          })
        );
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required("Please enter a valid Phone Number")
        .matches(
          FORMIK_REGEX.PHONE_NUMBER_REGEX,
          "Please enter a valid Phone Number"
        ),
    }),
    onSubmit: (values) => {
      dispatch(
        initiateTransactionFetch({
          values: {
            amount: sellData?.receiveCurrencyAmount,
            blockchain: sellReceiveData?.value || "TRON",
            applicationId: applicationId || APPLICATION_ID,
            referenceId: "6e2d2626-55bb-41d5-9892-318e48a45f4b",
            transferType: "SELL",
            phoneNumber: values?.phoneNumber,
            currency: sellReceiveData?.token || "USDT",
            coinPrice: (exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup))),
            dexQuote: {}
          },
          callback,
        })
      );
      props.handleSubmit();
    },
  });


  return (
    <div className="flow-box">
      <form onSubmit={formik.handleSubmit} className="height-100">
        <Flex flexColumn justifyContent="between" className="p-4 height-100">
          <Flex flexColumn>
            {props.goToBack && (
              <Button
                type="button"
                linkDark
                iconLeft={<ChevronDownIcon left={true} width="15" height="9" />}
                className="mback-btn"
                onClick={() => {
                  props.goToBack();
                }}
              />
            )}
            <Text type="H3" text="Sell Crypto" className="text-center mb-30" />
            <Text type="LEGEND" text="Login" />
            <div className="form-box">
              <div className="form-field">
                <Text
                  type="LABEL"
                  text={"Enter your phone number"}
                  className="form-label"
                />
                <PhoneNumberInput
                  className="phone-number-field my-0"
                  defaultCountry={"IN"}
                  international
                  countries={["IN"]}
                  countryCallingCodeEditable={false}
                  addInternationalOption={false}
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  value={formik.values.phoneNumber}
                  onChange={(value) =>
                    formik.setFieldValue("phoneNumber", value)
                  }
                />
                <div className="message-error">
                  <small>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? formik.errors.phoneNumber
                      : null}
                  </small>
                </div>
              </div>
            </div>
          </Flex>
          <Flex flexColumn className="mt-3">
            <Button type="submit" text="Login" />
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

export default BuyLogin;
