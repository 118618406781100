import React from 'react'

const Section = ({children, className, containerWidth1100}) => {
    return (
        <section className={`section-wrapper ${className ? className : ''}`}>
            <div className={`container ${containerWidth1100 ? 'container-width-1100' : ''}`}>
                {children}
            </div>
        </section>
    )
}

export default Section