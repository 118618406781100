import React from 'react'
import Text from '../text'

const NavTextIconList = ({lists}) => {
    return (
        <div className="nav-text-icon-list-wrp">
            <ul className="nav-til-wrapper">
                {lists.map((item, id) => (
                    <li key={id} className="nav-til-item">
                        <div className="nav-til-link" onClick={item.onClick}>
                            <div className="nav-til-icon">
                                {item.icon}
                            </div>
                            <Text type="SPAN" className="nav-til-text" text={item.text} />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default NavTextIconList