import React from 'react'
import Image from '../image'
import Text from '../text';
import Button from '../button';

const IconTextDescCard = (props) => {
const { src, text, description, btnText, onClick } = props;
  return (
    <div className="itd-card">
      <div className="itd-top">
        <div className="itd-img">
          <Image src={src} alt={text} width='74' height='74'/>
        </div>
        <Text type="H3" className="itd-title" text={text} />
        <Text type="PARAGRAPH" className="itd-desc" text={description} />
      </div>
      <div className="itd-bottom">
        <Button type="button" text={btnText} onClick={onClick} />
      </div>
    </div>
  )
}

export default IconTextDescCard