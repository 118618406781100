import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import UserAgreementView from './views/UserAgreementView';
import Layout from '../include/layout';

const UserAgreementController = (props) => {
  useEffect(() => {
    document.body.classList.add('agreement-page');
    return () => {
      document.body.classList.remove('agreement-page');
    };
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>User Agreement - PayOnRamp</title>
        <meta
          name="description"
          content="Review PayOnRamp's User Agreement for a clear understanding of our partnership terms. We're dedicated to providing a seamless and reliable user experience, and this agreement outlines the expectations and commitments."
        />
        <link rel="canonical" href="https://payonramp.com/userAgreement" />
      </Helmet>
      <UserAgreementView />
    </Layout>
  );
};

export default UserAgreementController;
