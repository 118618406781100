import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import {  useNavigate, NavLink } from "react-router-dom";

import useHeader from '../../pages/include/header/hooks/header.hook';
import {
    logoutFetch,
  } from "../../redux/user/reducers";


const NavbarNav = ({ lists }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isMobileWithTablet } = useHeader();

  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);

  const logoutCallback = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <ul className="navbar-nav-list">
        {lists.map((item, id) => (
          <li key={id} className="nav-item">
            <NavLink className="nav-link" to={item.url}>
              {item.text}
            </NavLink>
          </li>
        ))}
      </ul>
      {isMobileWithTablet && isLoggedIn ? (
        <button
          className="btn nav-right-btn btn-primary"
          type="button"
          onClick={
            () => dispatch(logoutFetch({
            logoutCallback 
           }
        ))}>
          Logout
        </button>
      ) : (
        <></>
      )}
    </>
  );
};

export default NavbarNav;
