import React from 'react'
import Image from '../image'
import Text from '../text'

const IconTextCard = (props) => {
    const {text, icon} = props;
    return (
        <div className="icon-text-card">
            <div className="itc-img">
                <Image src={icon} alt={text} />
            </div>
            <Text type="H3" className="itc-text" text={text} />
        </div>
    )
}

export default IconTextCard