import React, { useState } from 'react'
import Button from '../button'
import DownArrowIcon from '../svg/dropdownIcon'
import NavList from '../navList'

const Dropdown = (props) => {
    const {text, list} = props;
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const handleClick = () => {
        setIsOpenDropdown(state=>!state);
    }
    return (
        <div className="dropdown-wrap"
            onMouseEnter={() => setIsOpenDropdown(true)}
            onMouseLeave={() => setIsOpenDropdown(false)}
        >
            <Button 
                type="button" 
                outline 
                text={text} 
                icon={<DownArrowIcon opacity="1" up={isOpenDropdown} width={10} height={6} />}
                onClick={handleClick}
            />
            {isOpenDropdown && 
                <div className="dropdown-menu">
                    <div className="dropdown-main">
                        <NavList
                            lists={list}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default Dropdown