import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import Layout from '../include/layout';

const PrivacyPolicyController = (props) => {
  useEffect(() => {
    document.body.classList.add('agreement-page');
    return () => {
      document.body.classList.remove('agreement-page');
    };
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Privacy Policy - PayOnRamp</title>
        <meta
          name="description"
          content="Your privacy matters to us. Explore PayOnRamp's Privacy Policy to understand how we safeguard your data and ensure a secure and trustworthy payment experience."
        />
        <link rel="canonical" href="https://payonramp.com/privacyPolicy" />
      </Helmet>
      <PrivacyPolicyView />
    </Layout>
  );
};

export default PrivacyPolicyController;
