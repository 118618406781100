import React from 'react'

const TickTwoIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 13 10" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M3.61654 9.07166L3.61757 9.07269C3.77391 9.22903 3.97263 9.26981 4.13506 9.26981C4.21788 9.26981 4.30441 9.25946 4.3913 9.22604C4.47839 9.19254 4.55295 9.14085 4.61952 9.07474L12.0294 1.97449L12.0295 1.97453L12.0333 1.97076C12.3143 1.68967 12.3137 1.26446 12.0795 0.983475L12.0722 0.974763L12.0642 0.966744C11.7831 0.685661 11.3579 0.686335 11.0769 0.92049L11.0704 0.925964L11.0642 0.931873L4.13896 7.55508L1.96662 5.38274C1.68314 5.09926 1.24608 5.09926 0.962608 5.38274C0.679309 5.66603 0.679131 6.10272 0.962072 6.38621C0.962251 6.38639 0.962429 6.38657 0.962608 6.38675L3.61654 9.07166Z" 
                fill="white" 
                stroke="white" 
                strokeWidth="0.5"
            />
        </svg>
    )
}

export default TickTwoIcon

TickTwoIcon.defaultProps = {
    width: '13',
    height: '10',
}