import React from "react";
import Section from "../../../components/section";
import Text from "../../../components/text";
import classes from "./UserAgreementView.module.css";

const UserAgreementView = () => {
  const data = [
    {
      header: "Eligibility",
      text: [
        "You must be at least 18 years old and capable of entering into a legally binding agreement to use our Services. By using our Services, you represent and warrant that you meet these eligibility requirements.",
      ],
    },
    {
      header: "Account Registration",
      text: [
        "You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to provide accurate, complete, and up-to-date information during the registration process.",
      ],
    },
    {
      header: "Use of Services",
      text: [
        "You agree to use our Services for lawful purposes only and in compliance with all applicable laws and regulations. You will not use our Services to engage in any fraudulent, illegal, or unauthorized activities.",
      ],
    },
    {
      header: "Transactions",
      text: [
        "When using our Services to perform transactions, you agree to provide accurate and complete information. You acknowledge that cryptocurrency transactions are irreversible, and you are solely responsible for ensuring the accuracy of recipient addresses.",
      ],
    },
    {
      header: "Fees",
      text: [
        "You agree to pay any fees associated with the use of our Services as described on our platform. These fees may include transaction fees, conversion fees, and other applicable charges.",
      ],
    },
    {
      header: "Content",
      text: [
        "Any content you post or share through our Services must not infringe upon the intellectual property rights of others or violate any laws. We reserve the right to remove or suspend content that we believe violates this Agreement.",
      ],
    },
    {
      header: "Security",
      text: [
        "You are responsible for maintaining the security of your account, including safeguarding your login credentials and securing your device. You agree not to share your account information with others.",
      ],
    },
    {
      header: "Intellectual Property",
      text: [
        "You must be at least 18 years old and capable of entering into a legally binding agreement to use our Services. By using our Services, you represent and warrant that you meet these eligibility requirements.",
      ],
    },
    {
      header: "Limitation of Liability",
      text: [
        "You must be at least 18 years old and capable of entering into a legally binding agreement to use our Services. By using our Services, you represent and warrant that you meet these eligibility requirements.",
      ],
    },
    {
      header: "Modification of Agreement",
      text: [
        "You must be at least 18 years old and capable of entering into a legally binding agreement to use our Services. By using our Services, you represent and warrant that you meet these eligibility requirements.",
      ],
    },
  ];

  return (
    <>
      <Section className="">
        <div>
          <Text type="H1" className="" text="User Agreement" />
        </div>
        <div className={`row ${classes.mt}`}>
          <div className="col-12 col-md-4" style={{}}>
            <div style={{ backgroundColor: "#f1eaff", padding: 10 }}>
              <ol>
                {data.map((item) => (
                  <li key={item.header} className="" aria-current="true">
                    <a
                      href={`#${item.header}`}
                      style={{ textDecoration: "none", color: "#160546" }}
                    >
                      <Text type="H5" className="" text={item.header} />
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <div
            className="col-12 col-md-8"
          >
            <Text
              type="PARAGRAPH"
              text="This User Agreement ('Agreement') outlines the terms and conditions that govern your use of the PayOnRamp platform and services ('Services'). By accessing or using our Services, you agree to comply with this Agreement. If you do not agree with these terms, please refrain from using our Services."
            />
            {data.map((item) => (
              <>
                <div id={item.header} key={item.header}>
                  <Text type="H2" className="" text={item.header} />
                  {item.text.map((text) => (
                    <Text key={item.header} type="PARAGRAPH" text={text} />
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
};

export default UserAgreementView;
