import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import SelectStyle from "../../../../components/input/selectStyle";
import { useDispatch, useSelector } from "react-redux";
import useHome from "../../../home/hooks/home.hook";
import {
  getBankAccountsListFetch,
  verifyBankAccountsFetch,
} from "../../../../redux/withdrawal/reducer";
import { saveUserBank } from "../../../../redux/withdrawal/persistReducer";

const SelectBank = (props) => {
  const { selectBankDetails, handleBankDetails } = useHome();
  const dispatch = useDispatch();
  const [bankType, setBankType] = useState();
  const { handleAddBank } = props;
  const { userBankDetails } = useSelector(
    (state) => state.withdrawalPersistReducer
  );
  const { bankAccountsDetails } = useSelector(
    (state) => state?.withdrawalReducer
  );
  const formik = useFormik({
    initialValues: {
      bank: "",
    },
    onSubmit: (values) => {
      props.handleSubmit();
      dispatch(saveUserBank(bankType || selectBankDetails));
      dispatch(
        verifyBankAccountsFetch({
          bankId: bankType?.bankId || selectBankDetails?.bankId,
        })
      );
    },
  });

  useEffect(() => {
    if (bankAccountsDetails === null) {
      dispatch(
        getBankAccountsListFetch({
          payload: { limit: 10, offset: 0 },
        })
      );
    }
  }, []);

  useEffect(() => {
    handleBankDetails(userBankDetails)
  }, [userBankDetails]);

  let bankOptions = [];
  bankAccountsDetails?.data?.map((item, index) => {
    bankOptions.push({
      label: item?.accountNumber + " - " + item?.bankName,
      value: item?.accountNumber + " - " + item?.bankName,
      bankId: item?.bankId,
      key: index,
    });
  });
  return (
    <div className="flow-box">
      <form onSubmit={formik.handleSubmit} className="height-100">
        <Flex flexColumn justifyContent="between" className="p-4 height-100">
          <Flex flexColumn>
            <Text type="H3" text="Sell Crypto" className="text-center mb-30" />
            <Text type="LEGEND" text="Select bank" />
            <Flex flexColumn>
              <SelectStyle
                mb0
                secondary
                isSearchable={true}
                value={selectBankDetails}
                onChange={(selectedOption) => {
                  handleBankDetails(selectedOption);
                  setBankType(selectedOption);
                }}
                options={bankOptions}
                errormessage={
                  formik.touched.bank && formik.errors.bank
                    ? formik.errors.bank
                    : null
                }
              />
            </Flex>
          </Flex>
          <Flex flexColumn>
            <Flex className="mb-10" flexColumn>
              <Button
                type="button"
                outline
                text="+ Add Bank"
                disabled={bankAccountsDetails?.count >= 3}
                onClick={handleAddBank}
              />
            </Flex>
            <Flex className="mb-10" flexColumn>
              <Button
                type="submit"
                text="Proceed"
                disabled={!selectBankDetails}
              />
            </Flex>
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

export default SelectBank;
