import React from 'react'
import Text from '../text'

const Input1 = ({
    secondary,
    mb0,
    label, 
    noLabel,
    type, 
    value, 
    name, 
    id, 
    placeholder, 
    onChange, 
    readonly, 
    disabled, 
    required, 
    autoFocus, 
    maxlength,
    errormessage,
}) => {
  return (
    <div className={`form-field1 ${secondary ? 'form-field-secondary1' : ''} ${mb0 ? 'mb-0' : ''}`}>
        {!noLabel && <Text type="LABEL" text={label} className="form-label1" />}
        <input
          className={`form-control1 ${value ? "form-control-value1" : ""} ${errormessage ? "error" : ""}`}
          type={type}
          name={name}
          value={value}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          readOnly={readonly}
          disabled={disabled}
          required={required}
          autoFocus={autoFocus}
          maxLength={maxlength}
        />
        {errormessage && type != "checkbox" && (
            <div className="message-error">
                <small>{errormessage}</small>
            </div>
        )}
    </div>
  )
}

export default Input1