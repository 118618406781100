import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import PhoneNumberInput from "react-phone-number-input";
import { useDispatch } from "react-redux";

import { getUserOTPFetch } from "../../../../redux/user/reducers";
import { saveUserPhoneNUmber } from "../../../../redux/user/persistReducer";
import { FORMIK_REGEX, STATUS_CODE } from "../../../../utils/constants";

const BuyLogin = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required("Please enter a valid Phone Number")
        .matches(
          FORMIK_REGEX.PHONE_NUMBER_REGEX,
          "Please enter a valid Phone Number"
        ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const callback = (response) => {
        setLoading(false);
        if (response.status === STATUS_CODE.successful) {
          props.handleSubmit();
        }
      };
      console.log('before calling otp');
      dispatch(getUserOTPFetch({ phonenumber: values?.phoneNumber, callback }));
      dispatch(saveUserPhoneNUmber(values));
    },
  });

  return (
    <div className="flow-box">
      <form onSubmit={formik.handleSubmit} className="height-100">
        <Flex flexColumn justifyContent="between" className="p-4 height-100">
          <Flex flexColumn>
            <Text type="H3" text="Buy Crypto" className="text-center mb-30" />
            <Text type="LEGEND" text="Login" />
            <div className="form-box">
              <div className="form-field">
                <Text
                  type="LABEL"
                  text={"Enter your phone number"}
                  className="form-label"
                />
                <PhoneNumberInput
                  className="phone-number-field my-0"
                  defaultCountry={"IN"}
                  countries={["IN"]}
                  international
                  addInternationalOption={false}
                  countryCallingCodeEditable={false}
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  value={formik.values.phoneNumber}
                  onChange={(value) =>
                    formik.setFieldValue("phoneNumber", value)
                  }
                />
                <div className="message-error">
                  <small>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? formik.errors.phoneNumber
                      : null}
                  </small>
                </div>
              </div>
            </div>
          </Flex>
          <Flex flexColumn className="mt-3">
            <Button type="submit" text="Login" disabled={loading} />
          </Flex>
        </Flex>
      </form>
    </div>
  );
};
export default BuyLogin;
