import React from 'react'
import SplashScreen from "../../components/loading/usdtBitCoinLoading";

const Loader = () => {
    return (
      <div>
        <SplashScreen />
      </div>
    )
}

export default Loader