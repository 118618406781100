import React from 'react';

const TwitterIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1772_4269)">
        <path
          d="M8.7916 6.25479L14.291 0H12.9882L8.21114 5.42983L4.39851 0H0L5.7667 8.21165L0 14.77H1.30271L6.34421 9.03464L10.3715 14.77H14.77M1.77289 0.961215H3.77423L12.9873 13.856H10.9854"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1772_4269">
          <rect width="14.77" height="14.77" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterIcon;

TwitterIcon.defaultProps = {
  width: '16',
  height: '15',
};
