import React from 'react'
import Text from '../text';
import Image from '../image';

const SeizeCard = (props) => {
    const {title, icon, description, className} = props;
    return (
        <div className={`seize-box ${className ? className : '' }`}>
            <div className="seize-card">
                <div className="seize-top">
                    <div className="seize-heading">
                        <Text type="H4" className="seize-title" text={title} />
                    </div>
                    <div className="seize-icon">
                        <Image src={icon} alt={title} />
                    </div>
                </div>
                <Text type="PARAGRAPH" className="seize-desc" text={description} />
            </div>
        </div>
    )
}

export default SeizeCard