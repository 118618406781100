import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SupportView from './views/SupportView';
import Layout from '../include/layout';

const SupportController = (props) => {
  useEffect(() => {
    document.body.classList.add('support-page');
    return () => {
      document.body.classList.remove('support-page');
    };
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Get Expert Support for Your Payment Needs - PayOnRamp</title>
        <meta
          name="description"
          content="Receive expert support tailored to your payment requirements with PayOnRamp. Our dedicated team is here to assist you, ensuring seamless and reliable payment solutions for your business."
        />
        <link rel="canonical" href="https://payonramp.com/support" />
      </Helmet>
      <SupportView />
    </Layout>
  );
};

export default SupportController;
