import React from "react";
import Flex from "../flex";
import Loading from "../../assets/img/gif/usd-to-bitcoin.gif";
import Image from "../image";

const UsdtBitCoinLoading = () => {
  return (
    <Flex
      flexColumn
      justifyContent="center"
      alignItems="center"
      className="h-100"
    >
      <Image src={Loading} alt={"Loading"} />
    </Flex>
  );
};

export default UsdtBitCoinLoading;