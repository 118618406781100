import React from 'react'
import Text from '../text';

const RadioButton = (props) => {
    const {value, name, isChecked, onChange, icon, disabled} = props;
    return (
        <div className={`radio-button-card ${isChecked ? "active" : ""}`}>
            <input
                className="radio-button-field"
                type="radio"
                value={value}
                id={name}
                name={name}
                checked={isChecked}
                onChange={onChange}
                disabled={disabled}
            />
            <div className="rb-card">
                <div className="rb-icon">
                    {icon}
                </div>
                <Text type="SPAN" className="radio-title" text={value} />
            </div>
        </div>
    )
}

export default RadioButton