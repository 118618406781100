import React from 'react'
import Flex from '../flex';
import Text from '../text';

const Stepper = (props) => {
    const {steps, currentStep, complete} = props;
    return (
        <>
            <Flex justifyContent="between" flexColumn className="mb-5">
                <Flex>
                    {steps?.map((step, i) => (
                        <div key={i}
                            className={`step-item ${currentStep === i + 1 && "active"} ${(i + 1 < currentStep || complete) && "complete"} `}
                        >
                            <div className="step">
                                <div className="dot"></div>
                            </div>
                            <Text type="H5" text={step.text} className="step-title" />
                        </div>
                    ))}
                </Flex>
            </Flex>
        </>
    )
}

export default Stepper