import React from 'react'
import Text from '../text';

const ServiceCard = (props) => {
    const {icon, text, odd} = props;
    return (
        <div className={`service-card ${odd ? 'service-odd' : ''}`}>
            <div className="service-main">
                <div className="service-icon">
                    {icon}
                </div>
                <Text type="H4" className="service-text" text={text} />
            </div>
        </div>
    )
}

export default ServiceCard