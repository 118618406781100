
import React from 'react'

const MenuIcon = ({width, height, close}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        >
            {close ? (
                <>
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </>
            ) : (
                <>
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                </>
            )}
        </svg>
    )
}

export default MenuIcon

MenuIcon.defaultProps = {
    width: '24',
    height: '24',
}