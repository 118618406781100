
import React from 'react'

const CopyIcon = ({width, height, opacity}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 15 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path d="M10.7246 3C11.4229 3 12 3.57914 12 4.27734V16.7246C12 17.4228 11.4229 18 10.7246 18H1.27539C0.577139 18 0 17.4228 0 16.7246V4.27734C0 3.57914 0.577139 3.00195 1.27539 3.00195L10.7246 3ZM10.7246 3.97266H1.27539C1.09878 3.97266 0.972656 4.10074 0.972656 4.27734V16.7246C0.972656 16.9012 1.09878 17.0293 1.27539 17.0293H10.7246C10.9012 17.0293 11.0273 16.9012 11.0273 16.7246V4.27734C11.0273 4.10074 10.9012 3.97266 10.7246 3.97266Z" fill="currentColor"/>
                <path d="M13.7246 0C14.4229 0 15 0.579144 15 1.27734V13.7246C15 14.4228 14.4229 15 13.7246 15H12.5V14.0293H13.7246C13.9012 14.0293 14.0273 13.9012 14.0273 13.7246V1.27734C14.0273 1.10074 13.9012 0.972656 13.7246 0.972656H4.27539C4.09878 0.972656 3.97266 1.10074 3.97266 1.27734V2.5H3V1.27734C3 0.579144 3.57714 0.00195312 4.27539 0.00195312L13.7246 0Z" fill="currentColor"/>
            </g>
        </svg>
    )
}

export default CopyIcon

CopyIcon.defaultProps = {
    width: '15',
    height: '18',
    opacity: '0.5',
}