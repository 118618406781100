import React from "react";
import { NavLink } from "react-router-dom";
import FacebookIcon from "../svg/facebookIcon";
import TwitterIcon from "../svg/twitterIcon";
import InstagramIcon from "../svg/instagramIcon";
import LinkedinIcon from "../svg/linkedinIcon";
import TelegramIcon from "../svg/telegramIcon";
import MediumIcon from "../svg/mediumIcon";
import YoutubeIcon from "../svg/youtubeIcon";

const SocialMedia = () => {
  return (
    <div className="social-media-wap">
      <NavLink to="https://www.facebook.com/payonramp" className="sm-item-link">
        <FacebookIcon />
      </NavLink>
      <NavLink to="https://twitter.com/PayOnRamp " className="sm-item-link">
        <TwitterIcon />
      </NavLink>
      <NavLink
        to="https://www.instagram.com/payonramp/ "
        className="sm-item-link"
      >
        <InstagramIcon />
      </NavLink>
      <NavLink
        to="https://www.linkedin.com/company/payonramp"
        className="sm-item-link"
      >
        <LinkedinIcon />
      </NavLink>
      <NavLink to="https://t.me/PayOnRamp" className="sm-item-link">
        <TelegramIcon />
      </NavLink>
      <NavLink to="https://medium.com/@PayOnRamp" className="sm-item-link">
        <MediumIcon />
      </NavLink>
      <NavLink
        to="https://www.youtube.com/channel/UCgRhXomcacbfiYFAh_ACL4A"
        className="sm-item-link"
      >
        <YoutubeIcon />
      </NavLink>
    </div>
  );
};

export default SocialMedia;
