import { createSlice } from "@reduxjs/toolkit";

const withdrawalPersistReducerState = {
  isVerified: false,
  accessToken: null,
  refreshToken: null,
  kycVerified: false,
  sdkDetails: null,
  transactionDetails: null,
  rules: null,
  sellData: null,
  otpReferenceDetails: null,
  phoneNumber: null,
  userBankDetails: null,
  sellReceiveData: null,
  sellNetworkFee: null,
};

export const withdrawalSlice = createSlice({
  name: "withdrawalPersistReducer",
  initialState: withdrawalPersistReducerState,
  reducers: {
    // login successful
    saveWithdrawalLoginDaetails: (state, action) => {
      state.isVerified = true;
      state.accessToken = action.payload.accessToken ? action.payload.accessToken : action.payload.accessAuthToken;;
      state.refreshToken = action.payload.refreshToken ? action.payload.refreshToken : action.payload.refreshAuthToken;
      state.kycVerified = action.payload?.kycApproved;
    },

    clearWithdrwalLoginDaetails: (state) => {
      state.isVerified = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.kycVerified = false;
    },
    updateWithdrawalTokenDetails: (state, action) => {
      state.accessToken = action.payload?.accessToken;
      state.refreshToken = action.payload?.refreshToken;
    },

    // refresh token successful
   saveWithdrwalTokenDetails : (state, action) => {
      state.accessToken = action.payload.accessAuthToken ? action.payload.accessAuthToken : action.payload.accessToken; 
      state.refreshToken = action.payload.refreshAuthToken ?  action.payload.refreshAuthToken : action.payload.refreshToken;
    },

    updateWithdrawalSDKDetails: (state, action) => {
      state.sdkDetails = action.payload;
    },

    updateWithdrawalTransactionDetails: (state, action) => {
      state.transactionDetails = action.payload;
    },

    updateWithdrawalRules: (state, action) => {
      state.rules = action.payload;
    },

    saveOTPReferenceDetails: (state, action) => {
      state.otpReferenceDetails = action.payload;
    },
    saveSellData: (state, action) => {
      state.sellData = action.payload;
    },
    saveSellReceiveData: (state, action) => {
      state.sellReceiveData = action.payload;
    },
    savePhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    saveBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    saveUserBank: (state, action) => {
      state.userBankDetails = action.payload;
    },
    saveSellNetworkFee: (state, action) => {
      state.sellNetworkFee = action.payload;
    }
  },
});

export const {
  saveWithdrawalLoginDaetails,
  clearWithdrwalLoginDaetails,
  saveWithdrwalTokenDetails,
  updateWithdrawalSDKDetails,
  updateWithdrawalTransactionDetails,
  updateWithdrawalRules,
  saveSellData,
  saveOTPReferenceDetails,
  savePhoneNumber,
  saveBankDetails,
  saveUserBank,
  updateWithdrawalTokenDetails,
  saveSellReceiveData,
  saveSellNetworkFee,
} = withdrawalSlice.actions;
export default withdrawalSlice.reducer;
