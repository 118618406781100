import React from "react";
import Section from "../../../components/section";
import Text from "../../../components/text";
import SearchForm from "../../../components/searchForm";
import Flex from "../../../components/flex";
import Button from "../../../components/button";
import Accordion from "../../../components/accordion";
import RadioButton from "../../../components/radioButton";
import NavTab from "../../../components/navTab";
import Image from "../../../components/image";
import PartnershipImg from "../../../assets/img/partnership-img.png";
import EligibilityCard from "../../../components/eligibilityCard";
import DAppsImg from "../../../assets/img/DApps-img.png";
import ExchangeImg from "../../../assets/img/exchange-img.png";
import Web33DappsImg from "../../../assets/img/web33-dapps-img.png";
import WalletsImg from "../../../assets/img/wallets-img.png";
import PartnerServiceCard from "../../../components/partnerServiceCard";
import GetAccessIcon from "../../../components/svg/getAccessIcon";
import GrowthIncentivesIcon from "../../../components/svg/growthIncentivesIcon";
import ExpandReachIcon from "../../../components/svg/expandReachIcon";
import ShakeIcon from "../../../components/svg/shakeIcon";
import TechnicalIntegrationSupportIcon from "../../../components/svg/technicalIntegrationSupportIcon";
import { Link } from "react-router-dom";

const PartnershipView = () => {
  return (
    <>
      <Section containerWidth1100 className="section-hero-partnership">
        <Flex flexColumn>
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-7">
              <div className="hero-partnership-caption">
                <Text
                  type="H1"
                  className="supprt-search-title"
                  text="Get Powered with the PayOnRamp Payment Gateway!"
                />
                <Text
                  type="PARAGRAPH"
                  text="At PayOnRamp, we prioritize our commitment to fostering collaborations that help us redefine the space of digital transactions. With the aim to join forces with some of the most prominent and global organizations, we can establish groundbreaking payment experiences that empower both businesses and customers. We actively seek partnerships with companies that align with our vision and have the potential to enhance our services. By partnering with PayOnRamp, you can unlock opportunities for your business and enable us to deliver inclusive payment solutions to our shared customers."
                />
                <Link to={"/becomeAPartner"}>
                  <Button type="button" text="Become a Partner" />
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="hero-partnership-img">
                <Image src={PartnershipImg} alt="Image" />
              </div>
            </div>
          </div>
        </Flex>
      </Section>
      <Section containerWidth1100 className="section-why-partner-us">
        <div className="row">
          <div className="col-12 col-md-4 mx-auto mb-5">
            <Text
              type="H2"
              className="text-center mb-5"
              text="Why Partner with PayOnRamp?"
            />
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-12 col-md-4">
            <PartnerServiceCard
              icon={<GetAccessIcon />}
              text="Get access to our secure infrastructure"
              description=" Get access to our robust and secure payment gateway, with advanced fraud prevention and multiple payment methods, ensuring seamless and safe transactions for your customers."
            />
          </div>
          <div className="col-12 col-md-4">
            <PartnerServiceCard
              icon={<ExpandReachIcon />}
              text="Expand Your Reach"
              description="Partner with us and open doors to new markets and customers. Join our diverse network across industries such as e-commerce, retail, travel, and hospitality to increase your market presence."
            />
          </div>
          <div className="col-12 col-md-4">
            <PartnerServiceCard
              icon={<TechnicalIntegrationSupportIcon />}
              text="Technical Integration and Support"
              description="Our dedicated technical team ensures a smooth integration process, providing documentation, APIs, and ongoing support. We value your success and are committed to assisting you."
            />
          </div>
          <div className="col-12 col-md-4">
            <PartnerServiceCard
              icon={<ShakeIcon />}
              text="Co-Branding for you and your business"
              description="Collaborate with us on co-branded marketing initiatives, including webinars, case studies, thought leadership content, and promotions. We support your growth by showcasing your expertise and raising awareness about your solutions."
            />
          </div>
          <div className="col-12 col-md-4">
            <PartnerServiceCard
              icon={<GrowthIncentivesIcon />}
              text="Growth & Incentives"
              description="Partnering with us will unlock the potential growth of your business. Our competitive revenue-sharing models and incentive programs are designed to be mutually beneficial and rewarding."
            />
          </div>
        </div>
      </Section>
      <Section className="section-eligibility">
        <div className="row">
          <div className="col-12 col-md-5 mx-auto">
            <Text
              type="H2"
              className="text-center mb-4"
              text="The PayOnRamp eligibility"
            />
            <Text
              type="PARAGRAPH"
              className="text-center mb-80"
              text="A wide and dynamic range of organizations who match our mission & values, and can propel the evolution of the payment ecosystem."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 mx-auto">
            <div className="row">
              <div className="col-12 col-md-6">
                <EligibilityCard
                  icon={DAppsImg}
                  text="DApps (Decentralized Applications)"
                  description="Our payment features seamlessly work with decentralized applications (DApps) created by individuals and companies on blockchain platforms. Whether it's finance, gaming, or any other industry, our payment gateway enhances user experience and simplifies transactions."
                />
              </div>
              <div className="col-12 col-md-6">
                <EligibilityCard
                  icon={ExchangeImg}
                  text="Exchanges"
                  description="By collaborating with PayOnRamp, exchanges can provide their users with a user-friendly and secure payment gateway for easy fiat-to-crypto and crypto-to-fiat transactions. This makes our payment infrastructure reliable and ideal for improving exchange services."
                />
              </div>
              <div className="col-12 col-md-6">
                <EligibilityCard
                  icon={Web33DappsImg}
                  text="Web3 DApps"
                  description="With the rise of Web3 technologies and the decentralized web, supporting Web3 DApps is essential. Partnering with PayOnRamp allows Web3 DApps to utilize our payment gateway for seamless payments within their decentralized ecosystems. Together, we can drive the growth and adoption of Web3 technologies."
                />
              </div>
              <div className="col-12 col-md-6">
                <EligibilityCard
                  icon={WalletsImg}
                  text="Wallets"
                  description="As cryptocurrency becomes more popular, digital wallets are becoming essential in the payment landscape. By teaming up with PayOnRamp, wallet providers can offer users a smooth process for converting fiat to crypto, simplifying their entry into the world of digital currencies. Our secure payment gateway integration ensures safe and efficient transactions."
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default PartnershipView;
