import React from 'react'

const PartnerIcon = ({width, height}) => {
    return (
        <svg 
        width={width} 
        height={height} 
        clipRule="evenodd" 
        fillRule="evenodd" 
        imageRendering="optimizeQuality" 
        shapeRendering="geometricPrecision" 
        textRendering="geometricPrecision" 
        viewBox="0 0 1707 1707" xmlns="http://www.w3.org/2000/svg">
            <g id="Layer_x0020_1"><path d="m1657 1248-226-4c-14 0-26-7-33-18l-79 29c-26 70-70 109-128 102-46 44-95 55-140 46-79 77-146 43-160 42-15 3-30 5-45 5-200 0-433-273-577-475h-15l-222-40c-21-4-35-25-31-46l111-604c4-21 24-35 45-31l222 41c19 3 32 21 32 40l309 56c19-9 39-17 60-25 180-93 484 153 547 207l77 14c2-20 19-35 39-34l225 4c22 0 39 18 39 39l-11 614c-1 21-18 38-39 38zm-885 109c-73-84-122-194-130-212-20-47 51-78 71-31 39 90 162 306 259 252-106-78-180-245-190-268-20-47 51-78 71-31 31 72 156 307 258 253-103-78-195-252-218-295-23-46 47-81 70-36 52 103 179 296 242 290 14-1 28-18 39-48l-358-495c-160 82-294 99-370 48-40-27-60-72-54-123 8-72 65-147 154-209l-219-41-89 484c174 251 338 413 464 462zm622-213 8-478-99-18c-7-1-14-4-19-9-125-109-362-261-470-203l-73 32c-154 72-247 207-181 252 40 27 148 25 319-68 17-10 39-5 50 11l373 515zm-1310-279 145 27 97-527-145-27zm1387 302 147 3 10-536-147-3z" fill="rgb(0,0,0)"></path></g>
        </svg>
    )
}

export default PartnerIcon

PartnerIcon.defaultProps = {
    width: '34',
    height: '34'
}