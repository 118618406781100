import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BuyCryptoView from './views/BuyCryptoView';

const BuyCrypto = () => {
  useEffect(() => {
    document.body.classList.add('buycrypto-page');
    return () => {
      document.body.classList.remove('buycrypto-page');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Buy Crypto in INR- Secure and Hassle-Free Transactions - PayOnRamp</title>
        <meta
          name="description"
          content="Purchase Crypto with INR: Secure and Effortless Transactions with PayOnRamp. Simplify your cryptocurrency acquisitions with our reliable services."
        />
        <link rel="canonical" href="https://payonramp.com" />
      </Helmet>
      <BuyCryptoView />
    </>
  );
};

export default BuyCrypto;
