import React from 'react'
import { Navigate } from "react-router-dom";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.error('Uncaught error:', error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return <Navigate to="/error" />;
      }
  
      return this.props.children;
    }
}