import React, { useState } from 'react'
import Button from '../button'
import Icon from '../icon'
import CloseAlertIcon from '../svg/closeAlertIcon';

const AlertMessage = (props) => {
    const {message, icon, type} = props;
    const [isShow, setIsShow] = useState(true);
    const handleClose = (e) => {
        e.preventDefault();
        setIsShow(false);
      };
    return (
        <>
            {isShow && (
                <div className={`alert-wrapper ${type === "success" ? 'alert-success' : type === "error" ? 'alert-error' : type === "primary" ? 'alert-primary' : ''}`}>
                    <div className="container">
                        <div className="alert-main">
                            {icon && <Icon icon={icon} /> }
                            {message}
                            {props.children}
                            <Button linkDark 
                                onClick={handleClose}
                                className="alert-close" 
                                text={
                                    <CloseAlertIcon width={15} height={15} />
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AlertMessage