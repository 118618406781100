import React from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from '../input';
import Button from '../button';
import SearchIcon from '../svg/searchIcon';

const SearchForm = (props) => {
    const formik = useFormik({
        initialValues: {
            search: '',
        },
        validationSchema: Yup.object({
            search: Yup.string().required('Please enter a search'),
        }),
        onSubmit: (values) => {
            console.log(values);
        }
    });
    return (
        <div className="search-form">
            <form onSubmit={formik.handleSubmit}>
                <div className="form-search-box">
                    <Input
                        mb0
                        noLabel
                        type="text"
                        name="search"
                        placeholder="Search"
                        value={formik.values.search}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={
                            formik.touched.search && formik.errors.search ? (
                                formik.errors.search
                            ) : null
                        }
                    />
                    <Button type="submit" text={<SearchIcon />} />
                </div>
            </form>
        </div>
    )
}

export default SearchForm