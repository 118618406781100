import React from 'react'

const CardTable = ({children}) => {
  return (
    <div className="card-table-wrapper">
        {children}
    </div>
  )
}

export default CardTable