import React from 'react'

const ResetIcon = ({width, height}) => {
    return (
        <svg 
            width={width} 
            height={height}
            viewBox="0 0 12 12" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C8.775 12 11.1 10.05 12 7.5H10.5C9.6 9.225 7.95 10.5 6 10.5C3.525 10.5 1.5 8.475 1.5 6C1.5 3.525 3.525 1.5 6 1.5C7.275 1.5 8.05697 2.19085 8.91441 3.08565L6.75 5.25H12V0L10.0189 1.95523C8.89256 0.909779 7.65 0 6 0Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default ResetIcon

ResetIcon.defaultProps = {
    width: '18',
    height: '18'
}