import React from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
// components
import Flex from '../../../../../components/flex';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Image from '../../../../../components/image';
import LabelText from '../../../../../components/labelText';
// Images
import TransactionFailedImg from '../../../../../assets/img/transaction-failed-img.png';
// redux
import { saveUserBank } from "../../../../../redux/withdrawal/persistReducer";
// utils
import { getTds, truncate } from '../../../../../utils/util';
import { getProfileDetailsFetch } from '../../../../../redux/user/reducers';
import Tooltip from '../../../../../components/tooltip';
import InfoIcon from '../../../../../components/svg/infoIcon';

const TransactionFailed = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    sellData,
    userBankDetails,
    sellNetworkFee,
    sellReceiveData,
    bankDetails,
  } = useSelector((state) => state?.withdrawalPersistReducer);

  const { exchangeRateSell, sellMarkup, platformFee, applicationId, } = useSelector(
    (state) => state?.userReducer
  );

  const { isLoggedIn, isPanAadharLinked } = useSelector((state) => state?.userPersistReducer);
  const { tdsPercent, tdsMessage } = getTds(isLoggedIn, isPanAadharLinked);

  const formatAccountDetails = bankDetails
    ? `${bankDetails.accountNumber} - ${bankDetails.bankName}`
    : userBankDetails.label;

  let tdsPercentValue = truncate(
    ((sellData?.receiveCurrencyAmount * (exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup))) -
      sellNetworkFee?.payonrampFee) *
      tdsPercent) /
    100
  );

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Flex flexColumn justifyContent="between" className="p-4 height-100">
      <Flex flexColumn>
        <Flex flexColumn alignItems="center" className="mb-20">
          <Image src={TransactionFailedImg} alt="Purchase complete" />
        </Flex>
        <Text
          type="H3"
          text="Transaction Failed"
          className="text-center mb-10"
        />
        <Text
          type="PARAGRAPH"
          className="font-size-14 text-center mb-30"
          text="We couldn't detect your transaction. It is taking longer than expected. Please check 'Transaction History' page for the status.."
        />
        <LabelText
          lists={[
            {
              label: "Order ID",
              text: "#1394801351",
            },
            {
              label: "Sent",
              text: `${sellData?.receiveCurrencyAmount} ${sellReceiveData?.token}`,
            },
            {
              label: "Fee",
              text: `${truncate(sellNetworkFee?.payonrampFee)} INR`,
            },
            {
              label: <Flex alignItems="center" justifyContent="start">TDS ({tdsPercent}%)&nbsp;
                {!isPanAadharLinked && <Tooltip direction="right" text={tdsMessage}><InfoIcon width="15" height="15" /></Tooltip>}</Flex>,
              text: `${tdsPercentValue} INR`,
            },
            {
              label: `Recieved`,
              text: `${truncate(((sellData?.receiveCurrencyAmount) * (exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup)))) - tdsPercentValue - sellNetworkFee?.payonrampFee)} INR`,
            },
            {
              label: "Account No",
              text: `${formatAccountDetails}`,
            },
          ]}
        />
      </Flex>
      <Flex flexColumn>
        <Flex className="mb-10" flexColumn>
          <Button
            outline
            type="button"
            text="View Transaction History"
            onClick={() => {
              dispatch(getProfileDetailsFetch())
              dispatch(saveUserBank(null));
              if (applicationId) {
                openInNewTab(`https://payonramp.com`);
                return;
              }
              isLoggedIn ? navigate(`/history`) : navigate('/');
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TransactionFailed;
