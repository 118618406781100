import React from 'react'

const TickIcon = ({width, height, fill}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 26 26" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect 
                width="26" 
                height="26" 
                rx="13" 
                fill={fill}
            />
            <path 
                d="M10.6165 17.0717L10.6176 17.0727C10.7739 17.229 10.9726 17.2698 11.1351 17.2698C11.2179 17.2698 11.3044 17.2595 11.3913 17.226C11.4784 17.1925 11.553 17.1408 11.6195 17.0747L19.0294 9.97449L19.0295 9.97453L19.0333 9.97076C19.3143 9.68967 19.3137 9.26446 19.0795 8.98347L19.0722 8.97476L19.0642 8.96674C18.7831 8.68566 18.3579 8.68634 18.0769 8.92049L18.0704 8.92596L18.0642 8.93187L11.139 15.5551L8.96662 13.3827C8.68314 13.0993 8.24608 13.0993 7.96261 13.3827C7.67931 13.666 7.67913 14.1027 7.96207 14.3862C7.96225 14.3864 7.96243 14.3866 7.96261 14.3867L10.6165 17.0717Z" 
                fill="white" 
                stroke="white" 
                strokeWidth="0.5"
            />
        </svg>
    )
}

export default TickIcon

TickIcon.defaultProps = {
    width: '26',
    height: '26',
    fill: '#5A3CAE',
}