import React, { useState } from "react";
import Image from "../../components/image";
import Text from "../../components/text";
import AvatarProfileImg from "../../assets/img/avatar-profile-img.svg";
import DownArrowIcon from "../svg/dropdownIcon";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import {
    logoutFetch,
  } from "../../redux/user/reducers";

const AvatarProfileTop = (props) => {
  const { text, src } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen((state) => !state);
  };
  const logoutCallback = () => {
    navigate("/");
    // window.location.reload();
  };
  return (
    <div className="avatar">
      <div className="avatar--content">
        <div className="avatar--content-img">
          <Image src={src} alt={text} />
        </div>
        <Text type="H3" text={text} className="avatar--content-name" />
        <DownArrowIcon up={isOpen} width={11} height={6} />
      </div>
      <div className="avatar--dropdown">
        <div 
          onClick={() =>  dispatch(logoutFetch({ 
          logoutCallback }
          ))}>
          Logout
        </div>
      </div>
    </div>
  );
};

export default AvatarProfileTop;

AvatarProfileTop.defaultProps = {
  src: AvatarProfileImg,
  text: "",
};
