import React from 'react'

const GrowthIncentivesIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 42 42" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M8.02718 28.2962C7.72476 28.5843 7.24544 28.5724 6.95737 28.2699C6.66929 27.9675 6.68125 27.4882 6.98366 27.2001L15.948 18.6524C16.2074 18.405 16.5976 18.3787 16.8851 18.5681L25.2757 23.411L31.9485 14.2028L30.3653 14.6409C29.9631 14.7521 29.5459 14.5154 29.4348 14.1132C29.3236 13.7109 29.5603 13.2938 29.9625 13.1826L33.5652 12.1857C33.9674 12.0745 34.3846 12.3112 34.4958 12.7134L34.5191 12.8377L34.901 16.5558C34.9434 16.9712 34.6404 17.3435 34.225 17.3859C33.8097 17.4284 33.4373 17.1254 33.3949 16.71L33.2222 15.0282L26.1034 24.8519C25.8781 25.1597 25.4514 25.2565 25.1131 25.0623L16.5827 20.1382L8.02718 28.2962ZM0 0.759029C0 0.340069 0.340069 0 0.759029 0C1.17799 0 1.51806 0.340069 1.51806 0.759029V40.4819H41.241C41.6599 40.4819 42 40.822 42 41.241C42 41.6599 41.6599 42 41.241 42H0.759029C0.340069 42 0 41.6599 0 41.241V0.759029Z" 
                fill="#5A3CAE"
            />
        </svg>
    )
}

export default GrowthIncentivesIcon

GrowthIncentivesIcon.defaultProps = {
    width: '42',
    height: '42'
}