import { useCallback, useRef } from 'react';

const useDebounce = (callback, delay) => {
    const timerRef = useRef(null);

    const debouncedCallback = useCallback(
        (value) => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            timerRef.current = setTimeout(() => {
                callback(value);
            }, delay);
        },
        [callback, delay]
    );
    return debouncedCallback;
};

export default useDebounce;