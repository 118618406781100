import React from "react";
import Section from "../../../components/section";
import Text from "../../../components/text";
import classes from "./UserPolicyView.module.css";

const UserPolicyView = () => {
  const data = [
    {
      header: "Eligibility",
      text: [
        "1.1. By using our Services, you represent and warrant that you are at least 18 years old and possess the legal capacity to enter into a binding agreement. If you are using our Services on behalf of an organization, you also represent and warrant that you have the authority to bind that organization to this Policy.",
      ],
    },
    {
      header: "Account Registration",
      text: [
        "2.1. To access certain features of our Services, you may need to register an account. You are responsible for maintaining the confidentiality of your account information, including your username and password.",

        "2.2. You agree to provide accurate, current, and complete information during the registration process and to update such information to ensure its accuracy.",

        "2.3. You are solely responsible for all activities that occur under your account. If you suspect any unauthorized access or use of your account, you must notify us immediately.",
      ],
    },
    {
      header: "Use of Services",
      text: [
        "3.1. You agree to use our Services only for lawful purposes and in a manner consistent with this Policy, all applicable laws, and regulations.",

        "3.2. You will not use our Services to engage in any fraudulent, illegal, or abusive activities, including but not limited to money laundering, identity theft, or hacking.",

        "3.3. You will not attempt to gain unauthorized access to our systems, interfere with the security or integrity of our Services, or disrupt their normal operation.",
      ],
    },
    {
      header: "Transactions",
      text: [
        "4.1. When conducting transactions through our Services, you agree to provide accurate and complete information, including payment details and recipient addresses.",

        "4.2. You acknowledge that cryptocurrency transactions are irreversible, and you are responsible for ensuring the accuracy of recipient addresses.",
      ],
    },
    {
      header: "Fees",
      text: [
        "You agree to pay any fees associated with the use of our Services as described on our platform. These fees may include transaction fees, conversion fees, and other applicable charges.",
      ],
    },
    {
      header: "Content",
      text: [
        "Any content you post or share through our Services must not infringe upon the intellectual property rights of others or violate any laws. We reserve the right to remove or suspend content that we believe violates this Agreement.",
      ],
    },
    {
      header: "Security",
      text: [
        "You are responsible for maintaining the security of your account, including safeguarding your login credentials and securing your device. You agree not to share your account information with others.",
      ],
    },
    {
      header: "Intellectual Property",
      text: [
        "You must be at least 18 years old and capable of entering into a legally binding agreement to use our Services. By using our Services, you represent and warrant that you meet these eligibility requirements.",
      ],
    },
    {
      header: "Limitation of Liability",
      text: [
        "You must be at least 18 years old and capable of entering into a legally binding agreement to use our Services. By using our Services, you represent and warrant that you meet these eligibility requirements.",
      ],
    },
    {
      header: "Modification of Agreement",
      text: [
        "You must be at least 18 years old and capable of entering into a legally binding agreement to use our Services. By using our Services, you represent and warrant that you meet these eligibility requirements.",
      ],
    },
  ];

  return (
    <>
      <Section className="">
        <div>
          <Text type="H1" className="" text="User Policy" />
        </div>
        <div className={`row ${classes.mt}`}>
          <div className="col-12 col-md-4" style={{}}>
            <div style={{ backgroundColor: "#f1eaff", padding: 10 }}>
              <ol>
                {data.map((item) => (
                  <li key={item.header} className="" aria-current="true">
                    <a
                      href={`#${item.header}`}
                      style={{ textDecoration: "none", color: "#160546" }}
                    >
                      <Text type="H5" className="" text={item.header} />
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <div
            className="col-12 col-md-8"
          >
            <Text
              type="PARAGRAPH"
              text="This User Policy ('Policy') outlines the terms and conditions that govern your use of the PayOnRamp platform and its associated services ('Services'). This Policy is designed to provide you with comprehensive information about your rights, responsibilities, and obligations as a user of our Services. By accessing or using our Services, you acknowledge and agree to abide by the terms set forth in this Policy. If you do not agree with any part of this Policy, please refrain from using our Services."
            />

            {data.map((item) => (
              <>
                <div id={item.header} key={item.header}>
                  <Text type="H2" className="" text={item.header} />

                  {item.text.map((text) => (
                    <Text key={item.header} type="PARAGRAPH" text={text} />
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
};

export default UserPolicyView;
