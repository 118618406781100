import React from "react";
import Text from "../../components/text";
import Button from "../../components/button";

const DeletePaymentMethodForm = ({ goToBack, deletePaymentMethod }) => {
  return (
    <div className="flow-box">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: 50,
        }}

        // className="p-5 height-100"
      >
        <Text
          type="H3"
          text="Delete Payment Method"
          className="text-center mb-30"
        />
        <Text
          type="PARAGRAPH"
          text="Are you sure you want to delete the payment method?"
          className="text-center"
        />
        <div className="row">
          <div
            className="col"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              text="Cancel"
              white={true}
              onClick={() => goToBack(false, null)}
            />
          </div>
          <div className="col">
            <Button
              text="Proceed"
              danger={true}
              onClick={() => {
                deletePaymentMethod();
                goToBack(false, null);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePaymentMethodForm;
