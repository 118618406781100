import React from "react";
import Text from "../text";

const Textarea = ({
  secondary,
  resize,
  mb0,
  label,
  noLabel,
  type,
  value,
  name,
  id,
  placeholder,
  onChange,
  readonly,
  disabled,
  required,
  autoFocus,
  maxlength,
  rows,
  cols,
  errormessage,
  ref
}) => {
  return (
    <div
      className={`form-field ${secondary ? "form-field-secondary" : ""} ${
        mb0 ? "mb-0" : ""
      }`}
    >
      {!noLabel && <Text type="LABEL" text={label} className="form-label" />}
      <textarea
        style={{ resize: `${resize}` }}
        className={`form-control ${value ? "form-control-value" : ""} ${
          errormessage ? "error" : ""
        }`}
        type={type}
        name={name}
        value={value}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        readOnly={readonly}
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
        maxLength={maxlength}
        rows={rows}
        cols={cols}
        ref={ref}
      />
      {errormessage && type != "checkbox" && (
        <div className="message-error">
          <small>{errormessage}</small>
        </div>
      )}
    </div>
  );
};

export default Textarea;
