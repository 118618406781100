import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import CryptocurrencyImg from '../../../assets/img/cryptocurrency.png';
import SeizeImgOne from '../../../assets/img/seize-img-1.png';
import SeizeImgTwo from '../../../assets/img/seize-img-2.png';
import SeizeImgThree from '../../../assets/img/seize-img-3.png';
import SeizeImgFour from '../../../assets/img/seize-img-4.png';
import Accordion from '../../../components/accordion';
import Button from '../../../components/button';
import Card from '../../../components/card';
import Flex from '../../../components/flex';
import Icon from '../../../components/icon';
import Image from '../../../components/image';
import BitCoinLoading from '../../../components/loading/bitcoinLoading';
import Modal from '../../../components/modal';
import NavTab from '../../../components/navTab';
import NavTextIconList from '../../../components/navTextIconList';
import SearchCryptocurrencies from '../../../components/searchCryptocurrencies';
import Section from '../../../components/section';
import SeizeCard from '../../../components/seizeCard';
import ServiceCard from '../../../components/serviceCard';
import ArrowDownIcon from '../../../components/svg/arrowDownIcon';
import ArrowRightIcon from '../../../components/svg/arrowRightIcon';
import BankFillIcon from '../../../components/svg/bankFillIcon';
import CloseFillIcon from '../../../components/svg/closeFIllIcon';
import FileUserIcon from '../../../components/svg/fileUserIcon';
import HistoryFillIcon from '../../../components/svg/historyFillIcon';
import LogoutBoxIcon from '../../../components/svg/logoutBoxIcon';
import PartnerIcon from '../../../components/svg/partnerIcon';
import CurrencyConversionIcon from '../../../components/svg/service/currencyConversionIcon';
import CustomerSupportIcon from '../../../components/svg/service/customerSupportIcon';
import FlexibleSettlementIcon from '../../../components/svg/service/flexibleSettlementIcon';
import PricingIcon from '../../../components/svg/service/pricingIcon';
import SettingIcon from '../../../components/svg/service/settingIcon';
import SupportIcon from '../../../components/svg/supportIcon';
import Text from '../../../components/text';
import { logoutFetch } from '../../../redux/user/reducers';
import {
  saveWithdrawalLoginDaetails,
  saveWithdrwalTokenDetails,
} from '../../../redux/withdrawal/persistReducer';
import { initiateTransactionFetch } from '../../../redux/withdrawal/reducer';
import { APPLICATION_ID } from '../../../utils/constants';
import useHeader from '../../include/header/hooks/header.hook';
import BuyKYC from '../components/buy/buyKYC';
import KYCFailed from '../components/buy/buyKYC/kycFailed';
import KYCSuccess from '../components/buy/buyKYC/kycSuccess';
import CancelPurchase from '../components/buy/cancelPurchase';
import BuyLogin from '../components/buy/login';
import BuyOTP from '../components/buy/otp';
import BuyPaymentMethod from '../components/buy/paymentMethod';
import PurchasePending from '../components/buy/purchaseStatus/pending';
import PurchaseSuccess from '../components/buy/purchaseStatus/success';
import BuyReceivingINR from '../components/buy/receivingINR';
import ReviewPurchase from '../components/buy/reviewPurchase';
import WalletDetails from '../components/buy/walletDetails';
import BuyForm from '../components/buyForm';
import AddBank from '../components/sell/addBank';
import SellLogin from '../components/sell/login';
import SellOTP from '../components/sell/otp';
import SelectBank from '../components/sell/selectBank';
import SellKYC from '../components/sell/sellKYC';
import SendCrypto from '../components/sell/sendCrypto';
import SellStepsProcess from '../components/sell/stepsProcess';
import TransactionPending from '../components/sell/transactionStatus/pending';
import TransactionSuccess from '../components/sell/transactionStatus/successful';
import SellForm from '../components/sellForm';
import useHome from '../hooks/home.hook';
import AccountUnderVerification from '../../authentication/accountUnderVerification';
import { saveLoginDaetails } from '../../../redux/deposit/persistReducer';
import {
  saveUserLoginDetails,
  clearUserLoginDetails,
} from '../../../redux/user/persistReducer';
const faqList = [
  {
    question: 'What is PayOnRamp?',
    answer: (
      <ul>
        <li>
          PayOnRamp is a fiat-to-crypto payment gateway. This solves the
          important problem of allowing mainstream people and businesses to
          access crypto and blockchain. It does this by integrating local
          compliance, payment methods, and liquidity from around the world.
        </li>
      </ul>
    ),
  },
  {
    question: 'What kind of cryptocurrencies are supported on PayOnRamp?',
    answer: (
      <ul>
        <li>
          PayOnRamp supports over 150+ cryptocurrencies. Currently, we support
          BTC, TRX, ERC, BSC, and Polygon Blockchains.
        </li>
      </ul>
    ),
  },
  {
    question: 'What are the payment methods supported by PayOnRamp? ',
    answer: (
      <ul>
        <li>
          Our platform currently supports two distinct payment methods for INR:
          <ul>
            <li>
              UPI (Google Pay, Phone Pe): Utilize UPI-based payments through
              Google Pay, Phone Pe, and similar services.
            </li>
            <li>Bank Transfer: Make payments via direct bank transfers.</li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    question: "What is the source of PayOnRamp's liquidity?",
    answer: (
      <ul>
        <li>
          Currently, we are sourcing the Liquidity from Cryptoforce India.
          However, we are also working with multiple global liquidity providers.
          These partnerships ensure that we can offer our users the most
          competitive cryptocurrency rates through our platform.
        </li>
      </ul>
    ),
  },
  {
    question: 'Which integration options are supported by your platform?',
    answer: (
      <ul>
        <li>
          We provide support for:
          <ul>
            <li>
              widget/iframe integration (the user is directed to the PayOnRamp
              page).
            </li>
            <li>
              URL-based integration (the PayOnRamp page opens inside the web
              page).
            </li>
            <li>White label Integration Solution.</li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    question: 'How can I contact the support team of PayOnRamp?',
    answer: (
      <ul>
        <li>
          You can get in touch with the support team through two options:
          <ul>
            <li>You can send an email to support@payonramp.com.</li>
            <li>Initiate a support ticket from our Support Page.</li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    question: 'What are the daily and monthly transaction limits? ',
    answer: (
      <ul>
        <li>
          With Basic KYC, users can transact up to Rs 20,000 per day and up to Rs 2,00,000 per month.
        </li>
        <li>With Full KYC, users can transact up to Rs 50,000 per day and up to Rs 5,00,000 per month.</li>
        <li>For  transaction limits above Rs 50,000 please reach out to <a href="mailto:support@payonramp.com">support@payonramp.com</a></li>
      </ul>
    ),
  },
  {
    question: 'Guidelines for Submitting Documents for KYC Verification.',
    answer: (
      <ul>
        <li>Ensure all documents are clear, legible, and free of blur or distortion.</li>
        <li>Upload original documents issued by authorities; avoid photocopies.</li>
        <li>For identity proof (e.g., Aadhar card, PAN card), ensure clear photos without glare or reflections.</li>
        <li>When uploading Aadhar card, verify and upload the correct side as per instructions.</li>
      </ul>
    ),
  },
];

const HomeView = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  const Loading = useSelector((state) => state.withdrawalReducer.isLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    navTabStatus,
    navTabHandleclick,
    isOpenModal,
    onOpenModal,
    onCloseModal,
    isReviewPurchase,
    onOpenReviewPurchase,
    isLogin,
    onOpenLogin,
    goToBackReviewPurchase,
    isOTP,
    onOpenOTP,
    isBuyKYC,
    onOpenBuyKYC,
    goToBackLogin,
    isPaymentMethod,
    isCancelPurchase,
    onCancelPurchaseCancel,
    onProceedCancelPurchase,
    goToBackOTP,
    isReceivingINR,
    onOpenReceivingINR,
    isPurchasePending,
    onOpenPurchasePending,
    isPurchaseSuccess,
    handleClickDone,
    isOpenSellModal,
    onOpenSellModal,
    onCloseSellModal,
    isCancelPurchaseSell,
    onClosePurchasePending,
    onProceedCancelPurchaseSell,
    onCancelPurchaseCancelSell,
    isOTPSell,
    onOpenOTPSell,
    isSellKYC,
    goToBackLoginSell,
    isSelectBank,
    goToBackOTPSell,
    isAddBank,
    onSellModalClose,
    onOpenAddBank,
    goToBackSelectBankSell,
    isSendCrypto,
    onOpenSendCrypto,
    isStepsProcessSell,
    onOpenStepsProcessSell,
    handleClickDoneSell,
    isTransactionPending,
    onOpenTransactionPending,
    onCloseTransactionPending,
    isTransactionSuccess,
    isMenuLine,
    isSearchCryptocurrencies,
    onCloseMenuLine,
    onCloseSearchCryptocurrencies,
    onOpenSearchCryptocurrencies,
    isKYCSuccess,
    isKYCFailed,
    onOpenKycFailed,
    onOpenKycSuccess,
    VerifyOtpCallback,
    handleReceiveCurrencyChange,
    handleSellCurrencyChange,
    selectedReceiveCurrency,
    selectedSellCurrency,
    BuySellCallback,
    goToBackWalletDetails,
    goToBankDetails,
    verifyPaymentMethodCallback,
    openKYCPendingModal,
    onCloseKYCPendingModal,
    setSellKYCSuccess,
    onCloseKyc,
    reviewLogin,
  } = useHome();

  const { onOpenLoginModal } = useHeader();

  const { buyData, walletAddress, buyReceiveData } = useSelector(
    (state) => state?.depositPersistReducer
  );

  const { sellReceiveData } = useSelector((state) => state?.withdrawalPersistReducer);

  const { phoneNumber } = useSelector((state) => state.userPersistReducer);

  const { exchangeRateBuy, exchangeRateSell, markUp, sellMarkup, applicationId, walletInfo, dexQuote, voucher } =
    useSelector((state) => state?.userReducer);

  const callback = (res) => {
    if (res.status === 200) {
      if (res?.data?.verifyUser === true) {
        onOpenLogin();
        dispatch(clearUserLoginDetails());
      } else {
        dispatch(saveLoginDaetails(res?.data));
        dispatch(saveUserLoginDetails(res?.data));
        dispatch(saveWithdrawalLoginDaetails(res?.data));
        dispatch(saveWithdrwalTokenDetails(res?.data));
        BuySellCallback(res?.data);
      }
    }
  };

  const sellCallback = (res) => {
    if (res.status === 200) {
      if (res?.data?.verifyUser === true) {
        dispatch(clearUserLoginDetails());
        onOpenSellModal();
      } else {
        onOpenSellModal();
        dispatch(saveLoginDaetails(res?.data));
        dispatch(saveUserLoginDetails(res?.data));
        dispatch(saveWithdrawalLoginDaetails(res?.data));
        dispatch(saveWithdrwalTokenDetails(res?.data));
        BuySellCallback(res?.data);
      }
    }
  };

  const onInitialization = () => {
    if (isLoggedIn) {
      dispatch(
        initiateTransactionFetch({
          values: {
            amount: buyData?.buyCurrencyAmount,
            currency: buyReceiveData?.token,
            blockchain: buyReceiveData?.value,
            applicationId: applicationId || APPLICATION_ID,
            referenceId: '6e2d2626-55bb-41d5-9892-318e48a45f4b',
            transferType: 'BUY',
            phoneNumber: phoneNumber?.mobileNumber,
            walletAddress: walletInfo || walletAddress?.walletAddress,
            coinPrice:
              Number(exchangeRateBuy) +
              Number(exchangeRateBuy * parseFloat(markUp)),
            dexQuote: dexQuote,
            giftCard: voucher
          },
          callback,
        })
      );
    } else {
      onOpenLogin();
    }
  };
  const onSellInitialization = (values) => {
    if (isLoggedIn) {
      onOpenSellModal();
      dispatch(
        initiateTransactionFetch({
          values: {
            amount: values.receiveCurrencyAmount,
            blockchain: sellReceiveData?.value || 'TRON',
            applicationId: applicationId || APPLICATION_ID,
            referenceId: '6e2d2626-55bb-41d5-9892-318e48a45f4b',
            transferType: 'SELL',
            currency: sellReceiveData?.token || 'USDT',
            phoneNumber: phoneNumber?.mobileNumber,
            coinPrice:
              Number(exchangeRateSell) -
              Number(exchangeRateSell * parseFloat(sellMarkup)),
            dexQuote: {}
          },
          callback: sellCallback,
        })
      );
    } else {
      onOpenSellModal();
    }
  };

  const logoutCallback = () => {
    navigate('/');
    window.location.reload();
  };

  return (
    <>
      <Section className="section-hero">
        <div className="row">
          <div className="col-10 col-md-8 mx-auto">
            <Text
              type="H1"
              className="hero-title"
              text={
                <>
                  Tailored for a <strong>new era</strong> of transactions.
                </>
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-10 col-md-4 mx-auto">
            <Text
              type="PARAGRAPH"
              className="hero-desc"
              text="Accept payments in good old fiat & revolutionary crypto with PayOnRamp."
            />
          </div>
        </div>
        <div className="hero-form-wrapper">
          <div className="hero-form-main">
            <Card className="position-relative">
              <Flex justifyContent="between">
                <Flex>
                  <Text
                    type="H3"
                    text="PayOnRamp"
                    className="mb-0 ml-3 text-dark-100"
                  />
                </Flex>
              </Flex>
              <Flex justifyContent="center">
                <NavTab
                  lists={[
                    {
                      id: '0',
                      navTabStatus: navTabStatus.navTabItemType,
                      text: 'Buy',
                      navTabHandleclick: navTabHandleclick,
                    },
                    {
                      id: '1',
                      navTabStatus: navTabStatus.navTabItemType,
                      text: 'Sell',
                      navTabHandleclick: navTabHandleclick,
                    },
                  ]}
                />
              </Flex>
              {navTabStatus.navTabItemType === '0' && (
                <BuyForm
                  onOpenModal={onOpenModal}
                  onClick={onOpenSearchCryptocurrencies}
                  selectedCurrency={selectedReceiveCurrency?.token}
                  selectedFee={selectedReceiveCurrency?.fee}
                  networkFee={selectedReceiveCurrency?.networkFee}
                  code={selectedReceiveCurrency?.code}
                  source={selectedReceiveCurrency?.source}
                  blockchain={selectedReceiveCurrency?.value}
                  decimals={selectedReceiveCurrency?.decimals}
                />
              )}
              {navTabStatus.navTabItemType === '1' && (
                <SellForm
                  handleSubmit={onSellInitialization}
                  onClick={onOpenSearchCryptocurrencies}
                  selectedCurrency={selectedSellCurrency?.token}
                  selectedFee={selectedSellCurrency?.fee}
                  networkFee={selectedSellCurrency?.networkFee}
                  code={selectedSellCurrency?.code}
                  source={selectedSellCurrency?.source}
                  blockchain={selectedSellCurrency?.value}
                  decimals={selectedReceiveCurrency?.decimals}
                />
              )}
              {isSearchCryptocurrencies && (
                <SearchCryptocurrencies
                  goToBack={onCloseModal}
                  onClose={onCloseSearchCryptocurrencies}
                  selectedCurrency={selectedReceiveCurrency?.token}
                  onChange={(selectedOption) => {
                    navTabStatus.navTabItemType === '0'
                      ? handleReceiveCurrencyChange(selectedOption)
                      : handleSellCurrencyChange(selectedOption);
                    onCloseSearchCryptocurrencies();
                  }}
                  direction={
                    navTabStatus.navTabItemType === '0' ? 'buy' : 'sell'
                  }
                />
              )}
              {isMenuLine && (
                <div className="buysell-navmenu-wrp">
                  <Flex justifyContent="end">
                    <Button
                      linkBlack
                      text={<Icon white icon={<CloseFillIcon />} />}
                      onClick={onCloseMenuLine}
                    />
                  </Flex>
                  <NavTextIconList
                    lists={[
                      {
                        id: 1,
                        text: 'Buy',
                        icon: <ArrowDownIcon />,
                        onClick: () => {
                          navTabHandleclick({ id: '0' });
                          onCloseMenuLine();
                        },
                      },
                      {
                        id: 2,
                        text: 'Sell',
                        icon: <ArrowDownIcon up />,
                        onClick: () => {
                          navTabHandleclick({ id: '1' });
                          onCloseMenuLine();
                        },
                      },
                      {
                        id: 3,
                        text: 'History',
                        icon: <HistoryFillIcon />,
                        onClick: () => {
                          navigate('/history');
                        },
                      },
                      {
                        id: 4,
                        text: 'Profile',
                        icon: <FileUserIcon />,
                        onClick: () => {
                          navigate('/profile');
                        },
                      },
                      {
                        id: 5,
                        text: 'Partner',
                        icon: <PartnerIcon />,
                        onClick: () => {
                          navigate('/partnership');
                        },
                      },
                      {
                        id: 6,
                        text: 'Bank Accounts',
                        icon: <BankFillIcon />,
                        onClick: () => {
                          navigate('/profile');
                        },
                      },
                      {
                        id: 7,
                        text: 'Support',
                        icon: <SupportIcon />,
                        onClick: () => {
                          navigate('/support');
                        },
                      },
                      isLoggedIn
                        ? {
                          id: 8,
                          text: 'Logout', // Change the text to "Logout" if logged in
                          icon: <LogoutBoxIcon up />,
                          onClick: () => {
                            dispatch(
                              logoutFetch({
                                logoutCallback,
                              })
                            );
                          },
                        }
                        : {
                          id: 8,
                          text: 'Login', // Change the text to "Login" if not logged in
                          icon: <LogoutBoxIcon up />,
                          onClick: () => {
                            onOpenLoginModal();
                            onCloseMenuLine();
                          },
                        },
                    ]}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
      </Section>
      {isOpenModal && (
        <Modal
          open={isOpenModal}
          smallMedium
          minHeight
          onClose={isReceivingINR ? onClosePurchasePending : onCloseModal}
        >
          {isCancelPurchase ? (
            <CancelPurchase
              onCancel={onCancelPurchaseCancel}
              onProceed={onProceedCancelPurchase}
            />
          ) : Loading ? (
            <BitCoinLoading />
          ) : isPurchaseSuccess ? (
            <PurchaseSuccess handleClick={handleClickDone} />
          ) : isPurchasePending ? (
            <PurchasePending handleClick={onClosePurchasePending} />
          ) : isReceivingINR ? (
            <BuyReceivingINR handleClick={onOpenPurchasePending} />
          ) : isPaymentMethod ? (
            <BuyPaymentMethod
              handleSubmit={onOpenReceivingINR}
              verifyPaymentMethodCallback={verifyPaymentMethodCallback}
              goToBack={goToBackOTP}
            />
          ) : openKYCPendingModal ? (
            <AccountUnderVerification />
          ) : isKYCSuccess ? (
            <KYCSuccess onClick={onCloseKyc} />
          ) : isKYCFailed ? (
            <KYCFailed onClick={onOpenBuyKYC} />
          ) : isBuyKYC ? (
            <BuyKYC
              handleSubmit={onOpenKycSuccess}
              KYCFailed={onOpenKycFailed}
              handleClose={onCloseModal}
            />
          ) : isOTP ? (
            <BuyOTP
              goToBack={goToBackLogin}
              VerifyOtpCallback={VerifyOtpCallback}
            />
          ) : isLogin ? (
            <BuyLogin
              handleSubmit={onOpenOTP}
              goToBack={goToBackReviewPurchase}
            />
          ) : isReviewPurchase ? (
            <ReviewPurchase
              handleSubmit={onInitialization}
              goToBack={goToBackWalletDetails}
              networkFee={selectedReceiveCurrency?.networkFee}
              code={selectedReceiveCurrency?.code}
            />
          ) : (
            <WalletDetails handleSubmit={reviewLogin} />
          )}
        </Modal>
      )}
      {isOpenSellModal && (
        <Modal
          open={isOpenSellModal}
          smallMedium
          minHeight
          onClose={
            isStepsProcessSell
              ? onCloseTransactionPending
              : isCancelPurchaseSell
                ? onCloseTransactionPending
                : onCloseSellModal
          }
        >
          {isKYCSuccess ? (
            <KYCSuccess onClick={onCloseKyc} />
          ) : isCancelPurchaseSell ? (
            <CancelPurchase
              onCancel={onCancelPurchaseCancelSell}
              onProceed={onProceedCancelPurchaseSell}
            />
          ) : Loading ? (
            <BitCoinLoading />
          ) : isTransactionSuccess ? (
            <TransactionSuccess handleClick={handleClickDoneSell} />
          ) : isTransactionPending ? (
            <TransactionPending handleClick={onProceedCancelPurchaseSell} />
          ) : isStepsProcessSell ? (
            <SellStepsProcess handleClick={onOpenTransactionPending} />
          ) : isSendCrypto ? (
            <SendCrypto
              handleSubmit={onOpenStepsProcessSell}
              goToBack={goToBankDetails}
              networkFee={selectedSellCurrency?.networkFee}
              code={selectedSellCurrency?.code}
            />
          ) : Loading ? (
            <BitCoinLoading />
          ) : isAddBank ? (
            <AddBank
              handleSubmit={goToBackSelectBankSell}
              goToBack={goToBackSelectBankSell}
            />
          ) : isSelectBank ? (
            <SelectBank
              handleSubmit={onOpenSendCrypto}
              handleAddBank={onOpenAddBank}
              goToBack={goToBackOTPSell}
            />
          ) : openKYCPendingModal ? (
            <AccountUnderVerification onClick={onCloseKYCPendingModal} />
          ) : isKYCFailed ? (
            <KYCFailed onClick={onOpenBuyKYC} />
          ) : isSellKYC ? (
            <SellKYC
              handleSubmit={setSellKYCSuccess}
              KYCFailed={onOpenKycFailed}
              handleClose={onSellModalClose}
            />
          ) : isOTPSell ? (
            <SellOTP
              goToBack={goToBackLoginSell}
              VerifyOtpCallback={VerifyOtpCallback}
            />
          ) : (
            <SellLogin handleSubmit={onOpenOTPSell} />
          )}
        </Modal>
      )}
      <Section className="section-service">
        <Flex
          justifyContent="between"
          alignItems="center"
          className="mb-5"
          flexColumn={isMobile}
        >
          <Flex flexColumn className="col-10 col-md-7 col-lg-4 px-0">
            <Text
              type="H2"
              text="An experience that’s above the ordinary. Here’s why."
            />
          </Flex>
          <Link to={'/becomeAPartner'}>
            <Button text="Book a call" icon={<ArrowRightIcon />} />
          </Link>
        </Flex>
        <div className="row row-mrl-5 flex-column flex-md-row">
          <div className="col service-item">
            <ServiceCard
              text="Advanced Integration Capabilities"
              icon={<SettingIcon />}
            />
          </div>
          <div className="col service-item">
            <ServiceCard
              odd
              text="Transparent Pricing Models"
              icon={<PricingIcon />}
            />
          </div>
          <div className="col service-item">
            <ServiceCard
              text="Effortless Currency Conversion"
              icon={<CurrencyConversionIcon />}
            />
          </div>
          <div className="col service-item">
            <ServiceCard
              odd
              text="Flexible Settlement Options"
              icon={<FlexibleSettlementIcon />}
            />
          </div>
          <div className="col service-item">
            <ServiceCard
              text="Exceptional Customer Support"
              icon={<CustomerSupportIcon />}
            />
          </div>
        </div>
      </Section>
      <Section className="section-seize">
        <div className="row mb-5">
          <div className="col-12 col-md-7 col-lg-5 mx-auto text-center">
            <Text
              type="H2"
              text="Seize new opportunities and empower your business "
            />
            <Text
              type="PARAGRAPH"
              text="with PayOnRamp’s simple, fast and secure payment gateway."
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-11 col-lg-9 mx-auto">
            <div className="row">
              <div className="col-12 col-md-6">
                <SeizeCard
                  className="seize-one"
                  icon={SeizeImgOne}
                  title="India’s most advanced payment gateway"
                  description="Provide your customers with the unique experience of paying through Fiat or crypto!"
                />
              </div>
              <div className="col-12 col-md-6">
                <SeizeCard
                  className="seize-two"
                  icon={SeizeImgTwo}
                  title="Secure & Fraud Resistant"
                  description="A trusted and safe medium to send and receive funds, be it fiat or crypto."
                />
              </div>
              <div className="col-12 col-md-6">
                <SeizeCard
                  className="seize-three"
                  icon={SeizeImgThree}
                  title="Diverse Cryptocurrency Support"
                  description="We support a wide range of cryptocurrencies, like Bitcoin, Ethereum, Litecoin, Ripple, and many more."
                />
              </div>
              <div className="col-12 col-md-6">
                <SeizeCard
                  className="seize-four"
                  icon={SeizeImgFour}
                  title="Instant Settlements"
                  description="With PayOnRamp, you can enjoy near-instant settlements, ensuring that your funds are always available for your business needs."
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section className="section-whous">
        <div className="row">
          <div className="col-12 col-md-4">
            <Flex flexColumn className="whous-left">
              <Text type="H2" text="Who is PayOnRamp suitable for?" />
              <Text
                type="PARAGRAPH"
                className="mb-5"
                text="Any and every business that wants to evolve, grow and succeed!"
              />
              <Text
                type="PARAGRAPH"
                className="mb-0 font-size-14"
                text="Whether you're an online retailer, a service provider, or a brick-and-mortar store, PayOnRamp is your ideal solution for an inclusive and advanced payment gateway system."
              />
            </Flex>
          </div>
          <div className="col-12 col-md-8 d-flex justify-content-end">
            <Image src={CryptocurrencyImg} alt="" />
          </div>
        </div>
      </Section>
      <Section className="section-faqs">
        <div className="row">
          <div className="col-12 col-md-8">
            <Text type="H2" text="Frequently asked questions" />
            <Accordion accordionList={faqList} />
          </div>
        </div>
      </Section>
    </>
  );
};
export default HomeView;
