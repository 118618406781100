import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import Modal from '../modal';
import Button from '../button';
import * as Yup from 'yup';
import './documentUploadModal.scss';
import { getProfileDetailsFetch, submitFullKycBankStatement, submitFullKycVideo } from '../../redux/user/reducers';
import { useDispatch, useSelector } from 'react-redux';
import File from '../input/file';
import { toasts } from '../../utils/toast';
import Text from '../text';

const DocumentUploadModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { profileDetails } = useSelector((state) => state?.userReducer);
  const [videoPreview, setVideoPreview] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);

  const kycStatus = profileDetails?.kycInfo?.kycStatus;
  const videoStatus = profileDetails?.kycInfo?.fullKYCDocs?.video;
  const bankStatementStatus = profileDetails?.kycInfo?.fullKYCDocs?.bankStatement;

  let shouldShowVideoForm = false;
  let shouldShowBankStatementForm = false;

  if (kycStatus === "FULL_KYC_REJECTED" || kycStatus === "BASIC_KYC_SUCCESS") {
    shouldShowVideoForm = true;
    shouldShowBankStatementForm = false;
  } else if (kycStatus === "FULL_KYC_PENDING") {
    if ((videoStatus === 'PENDING' || videoStatus === '') && (bankStatementStatus === 'REJECTED' || bankStatementStatus === '')) {
      shouldShowBankStatementForm = true;
    } else if ((bankStatementStatus === 'PENDING' || bankStatementStatus === '') && (videoStatus === 'REJECTED' || videoStatus === '')) {
      shouldShowVideoForm = true;
    } else if (bankStatementStatus === 'REJECTED' || bankStatementStatus === 'APPROVED') {
      shouldShowBankStatementForm = true;
      shouldShowVideoForm = false;
    }
  }


  if (kycStatus === "FULL_KYC_PENDING" && (videoStatus === 'PENDING' || videoStatus === 'APPROVED') && bankStatementStatus === 'REJECTED') {
    shouldShowBankStatementForm = true;
    shouldShowVideoForm = false;
  }

  if (kycStatus === "FULL_KYC_PENDING" && (bankStatementStatus === 'PENDING' || bankStatementStatus === 'APPROVED') && videoStatus === 'REJECTED') {
    shouldShowVideoForm = true;
    shouldShowBankStatementForm = false;
  }

  const videoFormik = useFormik({
    initialValues: {
      video: null,
    },
    validationSchema: Yup.object({
      video: Yup.mixed().required('Please Record'),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('video', values.video);
      await dispatch(submitFullKycVideo({ formData }));

      if (bankStatementStatus === 'PENDING' || bankStatementStatus === 'APPROVED') {
        onClose()
      }
    },
  });

  const documentFormik = useFormik({
    initialValues: {
      bank_statement: null,
    },
    validationSchema: Yup.object({
      bank_statement: Yup.mixed().required('Please select a document'),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('bank_statement', values.bank_statement);
      await dispatch(submitFullKycBankStatement({ formData }));
      onClose();
    },
  });

  const startRecording = async () => {
    try {
      setVideoPreview(null);
      recordedChunksRef.current = []; // Reset the chunks array
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videoRef.current.srcObject = stream;

      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play().catch(err => toasts.error('Error playing video: ' + err.message));
      };

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.start(10); // Start recording and fire ondataavailable every 10ms
      setIsRecording(true);
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        toasts.error('Camera access was denied. Please allow camera access.');
      } else if (err.name === 'NotFoundError') {
        toasts.error('No camera was found on this device.');
      } else {
        toasts.error('Error accessing camera: ' + err.message);
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());

      const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
      const blobSizeKB = blob.size / 1024; // size in KB
      if (blobSizeKB >= 1 && blobSizeKB <= 25 * 1024) {
        const videoURL = URL.createObjectURL(blob);
        setVideoPreview(videoURL);
        videoFormik.setFieldValue('video', blob);
      } else {
        toasts.error('The video must be between 1KB and 25MB.');
      }
      setIsRecording(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    documentFormik.setFieldValue('bank_statement', file);
  };
  const getCurrentDate = () => {
    const date = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };
  const todayDate = getCurrentDate();
  const text = `I, ${profileDetails?.userInfo?.fullName}, am completing KYC verification with Payonramp on ${todayDate} for my account identified by  ${profileDetails?.userInfo?.phoneNumber}. I take full responsibility for all transactions conducted on my Payonramp account, including deposits and withdrawals involving INR and cryptocurrencies. Any liabilities or obligations arising from these transactions are exclusively my responsibility. I confirm that the funds used in these transactions are legitimately mine and have been obtained through lawful means.`

  return (
    <Modal open={open} onClose={onClose}>
      <div className="document-upload-modal">
        {shouldShowVideoForm && (
          <form onSubmit={videoFormik.handleSubmit}>
            <h3>Upload Video</h3>
            <Text type="H4" text="*Please read while recording VIDEO" />
            <Text type="H6" text={text} />
            <div className="video-upload">
              {!videoPreview && <video ref={videoRef} width="300" controls style={{ pointerEvents: 'none' }} />}
              {videoPreview && (
                <div>
                  <video width="300" controls>
                    <source src={videoPreview} type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
              <div>
                {isRecording ? (
                  <Button type="button" text="Stop Recording" onClick={stopRecording} />
                ) : (
                  <Button type="button" text="Start Recording" onClick={startRecording} />
                )}
                {videoFormik.errors.video && videoFormik.touched.video ? (
                  <div className="modal__form-error">{videoFormik.errors.video}</div>
                ) : null}
                {videoPreview && <Button type="submit" text="Submit Video" />}
              </div>
            </div>
          </form>
        )}

        {shouldShowBankStatementForm && (
          <form onSubmit={documentFormik.handleSubmit}>
            <h3>Upload Bank Statement</h3>
            <h4>Upload a minimum of 3 months' bank statements.</h4>
            <div className="form-box">
              <File
                label="Upload Bank Statement"
                type="file"
                name="document"
                selectedFile={documentFormik.values.bank_statement?.name}
                value=""
                onChange={handleFileChange}
                onBlur={documentFormik.handleBlur}
                mandatory
                className="file-add-attachment"
              />
              {documentFormik.errors.bank_statement && documentFormik.touched.bank_statement ? (
                <div className="modal__form-error">{documentFormik.errors.bank_statement}</div>
              ) : null}
            </div>
            <div className="modal_form_buttons">
              <Button type="button" text="Close" onClick={onClose} />
              <Button type="submit" text="Submit Document" />
            </div>
          </form>
        )}

      </div>
    </Modal>
  );
};

export default DocumentUploadModal;
