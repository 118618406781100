
import React from 'react'

const SupportCustomerIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 25 29" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M23.527 18.0864V18.0867C23.527 18.958 23.2135 19.4547 22.8126 19.7358C22.4084 20.0191 21.9074 20.0891 21.5245 20.0891H20.5996V11.2815H21.5502C21.9256 11.2815 22.4267 11.3477 22.8327 11.6292C23.2354 11.9083 23.5526 12.4048 23.5526 13.2837C23.5526 13.2837 23.5526 13.2838 23.5526 13.2839L23.527 18.0864ZM22.0875 10.1921C22.0639 5.08377 17.9155 0.95 12.8016 0.95C7.68771 0.95 3.53932 5.08379 3.51567 10.1921C2.86329 10.281 2.22823 10.5406 1.75256 11.0259C1.26552 11.5227 0.95 12.2518 0.95 13.2634V18.0867C0.95 19.2283 1.35019 20.0128 1.94539 20.511C2.53908 21.0079 3.32121 21.2153 4.07868 21.2153H5.5667C5.87768 21.2153 6.12982 20.9632 6.12982 20.6522V10.7184C6.12982 10.4074 5.87768 10.1552 5.5667 10.1552H4.64194C4.66884 5.67175 8.31177 2.04544 12.8016 2.04544C17.2914 2.04544 20.9343 5.67175 20.9613 10.1552H20.0365C19.7255 10.1552 19.4734 10.4074 19.4734 10.7184V20.6368C19.4734 20.9478 19.7255 21.1999 20.0365 21.1999H20.9592C20.8808 22.9245 20.4247 23.98 19.5859 24.6264C18.744 25.2752 17.5065 25.5206 15.843 25.5976V25.3062C15.843 24.7117 15.3612 24.2299 14.7668 24.2299H11.5393C10.9449 24.2299 10.4631 24.7117 10.4631 25.3062V26.9738C10.4631 27.5682 10.9449 28.05 11.5393 28.05H14.7771C15.3715 28.05 15.8533 27.5682 15.8533 26.9738V26.7239C17.4624 26.6476 18.9729 26.4388 20.1053 25.6591C21.2462 24.8734 21.9936 23.5156 22.086 21.1633C22.7389 21.0746 23.3746 20.815 23.8506 20.3293C24.3377 19.8325 24.6532 19.1034 24.6532 18.0918V13.2685C24.6532 12.2519 24.339 11.5215 23.8526 11.0246C23.3774 10.5392 22.7423 10.2809 22.0875 10.1921ZM5.00359 11.2456V20.0737H4.05302C3.67763 20.0737 3.17654 20.0075 2.7705 19.726C2.36778 19.4468 2.05057 18.9503 2.05057 18.0713V13.248C2.05057 12.3766 2.364 11.88 2.76495 11.5989C3.16909 11.3155 3.67017 11.2456 4.05302 11.2456H5.00359ZM14.7271 26.199V26.9289H11.5893V25.3613H14.7271V26.199Z" 
                fill="currentColor" 
                stroke="currentColor" 
                strokeWidth="0.1"
            />
        </svg>
    )
}

export default SupportCustomerIcon

SupportCustomerIcon.defaultProps = {
    width: '25',
    height: '29',
}