import React from 'react'

const ArrowDownIcon = ({width, height, left, up, right}) => {
    return (
        <svg
            style={{transform: left ? 'rotate(90deg)' : up ? 'rotate(180deg)' : right ? 'rotate(-90deg)' : 'rotate(0deg)', transition: 'transform ease-in 0.2s'}} 
            width={width} 
            height={height} 
            viewBox="0 0 34 34" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M17 2.83398C24.82 2.83398 31.1667 9.18065 31.1667 17.0007C31.1667 24.8207 24.82 31.1673 17 31.1673C9.18004 31.1673 2.83337 24.8207 2.83337 17.0007C2.83337 9.18065 9.18004 2.83398 17 2.83398ZM18.4167 17.0007V11.334H15.5834V17.0007H11.3334L17 22.6673L22.6667 17.0007H18.4167Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default ArrowDownIcon

ArrowDownIcon.defaultProps = {
    width: '34',
    height: '34'
}