
import React from 'react'

const GeneralQueriesIconIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 28 28" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M14 0C11.2311 0 8.52431 0.821086 6.22202 2.35943C3.91974 3.89777 2.12532 6.08427 1.06569 8.64243C0.00606598 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809206 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C28 12.1615 27.6379 10.341 26.9343 8.64243C26.2308 6.94387 25.1995 5.40053 23.8995 4.10051C22.5995 2.80049 21.0561 1.76925 19.3576 1.06569C17.659 0.362121 15.8385 0 14 0ZM14 26.6C11.508 26.6 9.07188 25.861 6.99982 24.4765C4.92776 23.092 3.31279 21.1242 2.35912 18.8218C1.40546 16.5195 1.15594 13.986 1.64211 11.5419C2.12829 9.0977 3.32832 6.8526 5.09046 5.09046C6.8526 3.32831 9.09771 2.12828 11.5419 1.64211C13.986 1.15593 16.5195 1.40545 18.8218 2.35912C21.1242 3.31278 23.092 4.92776 24.4765 6.99982C25.861 9.07188 26.6 11.508 26.6 14C26.6 17.3417 25.2725 20.5466 22.9096 22.9095C20.5466 25.2725 17.3417 26.6 14 26.6Z" 
                fill="currentColor"
            />
            <path 
                d="M14.0016 22.4001C14.7748 22.4001 15.4016 21.7733 15.4016 21.0001C15.4016 20.2269 14.7748 19.6001 14.0016 19.6001C13.2284 19.6001 12.6016 20.2269 12.6016 21.0001C12.6016 21.7733 13.2284 22.4001 14.0016 22.4001Z" 
                fill="currentColor"
            />
            <path 
                d="M14.0008 5.6001C12.8869 5.6001 11.8186 6.0426 11.0309 6.83025C10.2433 7.6179 9.80078 8.68619 9.80078 9.8001C9.80078 9.98575 9.87453 10.1638 10.0058 10.2951C10.1371 10.4263 10.3151 10.5001 10.5008 10.5001C10.6864 10.5001 10.8645 10.4263 10.9958 10.2951C11.127 10.1638 11.2008 9.98575 11.2008 9.8001C11.2008 9.05749 11.4958 8.3453 12.0209 7.8202C12.546 7.2951 13.2582 7.0001 14.0008 7.0001C14.7434 7.0001 15.4556 7.2951 15.9807 7.8202C16.5058 8.3453 16.8008 9.05749 16.8008 9.8001C16.8008 11.3681 15.9678 12.1311 15.0928 12.9361C14.2178 13.7411 13.3008 14.5741 13.3008 16.1001C13.3008 16.2858 13.3745 16.4638 13.5058 16.5951C13.6371 16.7263 13.8151 16.8001 14.0008 16.8001C14.1864 16.8001 14.3645 16.7263 14.4958 16.5951C14.627 16.4638 14.7008 16.2858 14.7008 16.1001C14.7008 15.2321 15.1838 14.7491 16.0378 13.9651C17.0038 13.0831 18.2008 11.9841 18.2008 9.8001C18.2008 8.68619 17.7583 7.6179 16.9706 6.83025C16.183 6.0426 15.1147 5.6001 14.0008 5.6001Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default GeneralQueriesIconIcon

GeneralQueriesIconIcon.defaultProps = {
    width: '26',
    height: '26',
}