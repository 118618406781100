import React from 'react'

const LogoutBoxIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 34 34" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M7.08329 31.1673C6.3009 31.1673 5.66663 30.5331 5.66663 29.7507V4.25065C5.66663 3.46825 6.3009 2.83398 7.08329 2.83398H26.9166C27.6991 2.83398 28.3333 3.46825 28.3333 4.25065V8.50065H25.5V5.66732H8.49996V28.334H25.5V25.5007H28.3333V29.7507C28.3333 30.5331 27.6991 31.1673 26.9166 31.1673H7.08329ZM25.5 22.6673V18.4173H15.5833V15.584H25.5V11.334L32.5833 17.0007L25.5 22.6673Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default LogoutBoxIcon

LogoutBoxIcon.defaultProps = {
    width: '34',
    height: '34'
}