import { useState } from "react";

const useSupport = () => {
    const [navTabStatus, setNavTabStatus] = useState({
        navTabItemType: "0"
    });
    const navTabHandleclick = (item) => {
        setNavTabStatus({
            navTabItemType: item.id,
        });
    };
    return (
        {
            navTabStatus,
            navTabHandleclick,   
        }
    )
}

export default useSupport