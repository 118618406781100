import { useState } from 'react';
import '../../assets/scss/components/tooltip/index.scss';

const Tooltip = ({ children, direction = 'top', text }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="tooltip-container" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      {children}
      {isVisible && (
        <div className={`tooltip-box tooltip-${direction}`}>
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
