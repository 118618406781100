import { createSlice } from "@reduxjs/toolkit";

const userPersistReduerState = {
  isLoggedIn: false,
  otpReferenceDetails: null,
  isPanAadharLinked: false,
  pageUrl: null,
  phoneNumber: null,
  userPhoneNumber: null,
  authKeyLife: null,
  newUser: null,
};

export const userSlice = createSlice({
  name: "userPersistReducer",
  initialState: userPersistReduerState,
  reducers: {
    saveUserLoginDetails: (state, action) => {
      state.isLoggedIn = true;
      state.isPanAadharLinked = action.payload?.isPanAadharLinked;
    },
    clearUserLoginDetails: (state) => {
      state.isLoggedIn = false;
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
      localStorage.setItem('expiry', '');
    },
    saveLoginDetails: (state, action) => {
      state.isVerified = true;
      state.accessToken = action.payload.accessToken ? action.payload.accessToken : action.payload.accessAuthToken;
      state.refreshToken = action.payload.refreshToken ? action.payload.refreshToken : action.payload.refreshAuthToken;
      state.userInfo = action.payload?.kycApproved;
      state.kycVerified = action.payload?.kycApproved;
    },

    saveUserPhoneNUmber: (state, action) => {
      state.userPhoneNumber = action.payload;
    },
    saveNewUser: (state, action) => {
      state.newUser = action.payload.newUser;
    },
    saveOTPReferenceDetails: (state, action) => {
      state.otpReferenceDetails = action.payload;
    },
    savePhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    savePageUrl: (state, action) => {
      state.pageUrl = action.payload;
    },
    saveCurrency: (state, action) => {
      state.convertionCurrent = action.payload;
    },
  },
});

export const {
  saveUserLoginDetails,
  clearUserLoginDetails,
  saveOTPReferenceDetails,
  savePhoneNumber,
  savePageUrl,
  saveCurrency,
  saveUserPhoneNUmber,
  saveLoginDetails,
  saveNewUser,
} = userSlice.actions;
export default userSlice.reducer;
