import React from 'react'

const Icon = ({icon, gray, white}) => {
  return (
    <div className={`icon-wrap ${white ? 'icon-white' : gray ? 'icon-gray' : ''}`}>
        {icon}
    </div>
  )
}

export default Icon