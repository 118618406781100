import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Icon from "../icon";
import CopyIcon from "../svg/copyIcon";
import TickIcon from "../svg/tickIcon";

const CopyToClipboardCard = (props) => {
    const {title} = props;
    const [isCopied, setIsCopied] = useState(false);
    const onCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    };
    return (
        <CopyToClipboard onCopy={onCopy} text={title}>
            <div className="copy-clip-item">
                {isCopied ? (
                    <Icon icon={<TickIcon width={18} height={18} />} />
                ) : (
                    <Icon icon={<CopyIcon opacity={1} width={15} height={18} />} />
                )}
            </div>
        </CopyToClipboard>
    )
}

export default CopyToClipboardCard