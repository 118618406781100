import React, { useState } from 'react'
import Button from '../button'
import ChevronDownIcon from '../svg/chevronDownIcon'
import Text from '../text'
import TickTwoIcon from '../svg/tickTwoIcon'

const DropdownCheckbox = (props) => {
    const {text, list} = props;
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const handleClick = () => {
        setIsOpenDropdown(state=>!state);
    }
    const [checked, setChecked] = useState([]);
    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
          updatedList = [...checked, event.target.value];
        } else {
          updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };
    var isChecked = (item) => checked.includes(item) ? "checked-item" : "not-checked-item";
    return (
        <div className="dropdown-checkbox-wrap"
            onMouseEnter={() => setIsOpenDropdown(true)}
            onMouseLeave={() => setIsOpenDropdown(false)}
        >
            <Button
                white 
                type="button"
                text={text} 
                icon={<ChevronDownIcon opacity="1" up={isOpenDropdown} width={10} height={6} />}
                onClick={handleClick}
            />
            {isOpenDropdown && 
                <div className="dropdown-menu">
                    <div className="dropdown-main">
                        {list.map((item, index) => (
                            <div key={index} className="dropdown-item">
                                <div className={`checkbox-field-item ${isChecked(item)}`}>
                                    {checked.includes(item) && <TickTwoIcon />}                                    
                                    <input value={item} type="checkbox" className="checkbox-field" onChange={handleCheck} />
                                </div>
                                <Text type="SPAN" text={item} />
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}

export default DropdownCheckbox