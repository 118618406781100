import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import UserPolicyView from './views/UserPolicyView';
import Layout from '../include/layout';

const UserPolicyController = (props) => {
  useEffect(() => {
    document.body.classList.add('agreement-page');
    return () => {
      document.body.classList.remove('agreement-page');
    };
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>User Policy - PayOnRamp</title>
        <meta
          name="description"
          content="Learn about PayOnRamp's User Policy to understand how we ensure a secure and satisfactory user experience. Your trust and satisfaction are our top priorities."
        />
        <link rel="canonical" href="https://payonramp.com/userPolicy" />
      </Helmet>
      <UserPolicyView />
    </Layout>
  );
};

export default UserPolicyController;
