
import React from 'react'

const UPIIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 69 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M59.1167 18.7021L68.9988 9.68731L64.3179 0.361328L59.1167 18.7021Z" 
                fill="#097939"
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M55.7881 18.7021L65.6702 9.68731L60.9893 0.361328L55.7881 18.7021Z" 
                fill="#ED752E"
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M8.56208 0.465414L4.4014 15.2831L19.1721 15.3866L23.2293 0.465414H26.9739L22.1095 17.8119C21.9232 18.4743 21.2138 19.0125 20.5259 19.0125H1.59312C0.444962 19.0125 -0.235374 18.1155 0.0751238 17.0115L4.71466 0.465414H8.56208ZM54.2274 0.361914H57.972L52.7708 19.0125H48.9206L54.2274 0.361914ZM27.3907 8.13265L46.1144 8.02915L47.3633 4.09134H28.3263L29.4703 0.464724L49.7562 0.278425C51.0189 0.266695 51.771 1.24442 51.4384 2.46364L49.5272 9.44916C49.1939 10.6677 47.8988 11.6551 46.6361 11.6551H29.8871L27.9123 19.323H24.2691L27.3907 8.13265Z" 
                fill="#747474"
            />
        </svg>
    )
}

export default UPIIcon

UPIIcon.defaultProps = {
    width: '69',
    height: '20',
}