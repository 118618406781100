import React from "react";
import IconTextDescCard from "../../../../../components/iconTextDescCard";
import WarningSignImg from "../../../../../assets/img/warning-sign-img.png";
import Flex from "../../../../../components/flex";
const KYCFailed = (props) => {
  return (
    <Flex
    flexColumn
    justifyContent="center"
    alignItems="center"
    className="h-100"
  >
    <IconTextDescCard
      {...props}
      src={WarningSignImg}
      text="KYC Rejected"
      description="Your account has been rejected due to improper documents. Request you to click on the below button to re-verify account"
      btnText="Re-verify"
      onClick={props.onClick}
    />
    </Flex>
  );
};

export default KYCFailed;
