import React from 'react'
import Text from '../text';

const KYCDetailCard = (props) => {
    const {text, action, detailList, active} = props;
    return (
        <div className={`kyc-detail-card ${active ? 'active' : ''}`}>
            <div className="kyc-detail-head">
                <Text type="H5" className="kyc-detail-head-title" text={text} />
                <div className="kyc-action">
                    {action}
                </div>
            </div>
            <div className="kyc-detail-content">
                {detailList.map((item, id) => (
                    <div className="kyc-detail-lists" key={id}>
                        <Text type="H4" className="kyc-detail-list-title" text={item.title} />
                        <ul className="kyc-detail-list-ul">
                            {item.list.map((item, id) => (
                                <li key={id}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default KYCDetailCard