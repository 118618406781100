import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// components
import Flex from '../../../../../components/flex';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Image from '../../../../../components/image';
import LabelText from '../../../../../components/labelText';
import Modal from '../../../../../components/modal';
// images
import AmountSentImg from '../../../../../assets/img/transaction-success-img.png';
// redux
import { saveUserBank } from '../../../../../redux/withdrawal/persistReducer'
// utils
import { getTds, truncate } from '../../../../../utils/util';
import { getProfileDetailsFetch } from '../../../../../redux/user/reducers';
import InfoIcon from '../../../../../components/svg/infoIcon';
import Tooltip from '../../../../../components/tooltip';

const TransactionSuccessful = (props) => {
  const { handleClick } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sellData, userBankDetails, sellNetworkFee, sellReceiveData } =
    useSelector((state) => state?.withdrawalPersistReducer);
  const { exchangeRateSell, platformFee, sellMarkup } = useSelector(
    (state) => state?.userReducer
  );

  const { isLoggedIn, isPanAadharLinked } = useSelector(state => state?.userPersistReducer)
  const { tdsPercent, tdsMessage } = getTds(isLoggedIn, isPanAadharLinked);

  let tdsPercentValue = (
    truncate((sellData?.receiveCurrencyAmount * (exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup))) -
      sellNetworkFee?.payonrampFee) *
      tdsPercent) /
    100
  );

  return (
    <Modal
      smallMedium
      minHeight
      onClose={() => {
        handleClick();
        window.location.reload();
      }}
    >
      <Flex flexColumn justifyContent="between" className="p-4 height-100">
        <Flex flexColumn>
          <Text
            type="H3"
            text="Amount sent to your account"
            className="text-center mb-30"
          />
          <Flex flexColumn alignItems="center" className="mb-20">
            <Image src={AmountSentImg} alt="Purchase complete" />
          </Flex>
          <LabelText
            lists={[
              {
                label: 'Order ID',
                text: '#1394801351',
              },
              {
                label: 'Sent',
                text: `${sellData?.receiveCurrencyAmount} ${sellReceiveData?.token}`,
              },
              {
                label: 'Fee',
                text: `${truncate(sellNetworkFee?.payonrampFee)} INR`,
              },
              {
                label: <Flex alignItems="center" justifyContent="start">TDS ({tdsPercent}%)&nbsp;
                  {!isPanAadharLinked && <Tooltip direction="right" text={tdsMessage}><InfoIcon width="15" height="15" /></Tooltip>}</Flex>,
                text: `${tdsPercentValue} INR`,
              },
              {
                label: `Recieved`,
                text: `${truncate(((sellData?.receiveCurrencyAmount) * (exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup)))) - tdsPercentValue - sellNetworkFee?.payonrampFee)} INR`,
              },
              {
                label: 'Account No',
                text: `${userBankDetails?.label}`,
              },
            ]}
          />
        </Flex>
        <Flex flexColumn>
          <Flex className="mb-10" flexColumn>
            <Button
              type="button"
              text="Done"
              onClick={() => {
                handleClick();
                window.location.reload();
              }}
            />
          </Flex>
          <Flex className="mb-10" flexColumn>
            <Button
              outline
              type="button"
              text="View Transaction History"
              onClick={() => {
                dispatch(getProfileDetailsFetch())
                dispatch(saveUserBank(null));
                navigate('/history')
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default TransactionSuccessful;
