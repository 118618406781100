import React from "react";
import Header from "../header";
import Footer from "../footer";
import ScrollToTop from "../../../components/common/ScrollToTop";

const Layout = ({ children }) => {
  return (
    <div className="layout-wrapper">
      <ScrollToTop />
      <Header />
      <div className="layout-main-wrapper">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
