import React from "react";
import { useSelector } from "react-redux";

import Section from "../../../components/section";
import Text from "../../../components/text";
import Flex from "../../../components/flex";
import Button from "../../../components/button";
import Accordion from "../../../components/accordion";
import NavTab from "../../../components/navTab";
import useSupport from "../hooks/support.hook";
import IntegrationIcon from "../../../components/svg/integrationIcon";
import GeneralQueriesIconIcon from "../../../components/svg/generalQueriesIcon";
import SupportCustomerIcon from "../../../components/svg/supportCustomerIcon";
import ChatIcon from "../../../components/svg/chatIcon";
import { NavLink, useNavigate } from 'react-router-dom'

const supportFaqList = [
  {
    question: "What kind of cryptocurrencies are supported on PayOnRamp?",
    answer:
      <ul><li>PayOnRamp supports over 150+ cryptocurrencies. Currently, we support BTC, TRX, ERC, BSC, and Polygon Blockchains.</li></ul>
  },
  {
    question: "Which fiat currencies does PayOnRamp support?",
    answer:
      <ul><li>As of now, PayOnRamp supports Indian Rupee (INR). However, we are actively working to expand our offerings and will be introducing additional fiat currencies soon.</li></ul>
  },
  {
    question: "What are the payment methods supported by PayOnRamp? ",
    answer:  <ul>
    <li>
      Our platform currently supports two distinct payment methods for INR:
      <ul>
        <li>
           UPI (Google Pay, Phone Pe): Utilize UPI-based payments through Google Pay, Phone Pe, and similar services.
        </li>
        <li>
           Bank Transfer: Make payments via direct bank transfers.
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    question: "What is the source of PayOnRamp's liquidity?",
    answer:
      <ul><li>Currently, we are sourcing the Liquidity from Cryptoforce India. However, we are also working with multiple global liquidity providers. These partnerships ensure that we can offer our users the most competitive cryptocurrency rates through our platform.</li></ul>
  },
  {
    question:
      "Can I receive a personalized list of supported cryptocurrencies and blockchains? ",
    answer:
      <ul><li>Absolutely. For tailored information regarding available chains and cryptocurrencies, do not hesitate to contact us via email at support@payonramp.com. We're here to assist you."</li></ul>
  },
  {
    question: "Is it possible to Negotiate the fees?",
    answer:
      <ul><li>We can Negotiate the fees depending on the volume. Do reach us out for more information at support@payonramp.com."</li></ul>
  },
  {
    question: "How can I contact the support team of PayOnRamp?",
    answer:
    <ul>
    <li>
      You can get in touch with the support team through two options:
      <ul>
        <li>
          Send an email to <a href="mailto:support@payonramp.com">support@payonramp.com</a>.
        </li>
        <li>
          Initiate a support ticket from our <a href="/support">Support Page</a>.
        </li>
        </ul>
    </li>
  </ul>
  },
];

const generalQueriesList = [
  {
    question:
      "What should I do if my payment was successful, but the crypto was not transferred?",
    answer:
      <ul><li>We apologize for any inconvenience you have experienced. To address this issue, we kindly ask you to raise a support ticket from our support page with a screenshot of the transaction along with the Unique Transaction Reference (UTR) Number associated with the transaction. This will assist us in identifying your transaction promptly. Note: While we strive to resolve such cases promptly, it is important to be aware that certain factors, such as network congestion or blockchain-related issues, might lead to temporary delays in the transfer process. We recommend that you double-check the accuracy of the transaction details before proceeding.</li>
      </ul>
  },
  {
    question:
      "I have not received a confirmation message for my most recent transaction?",
    answer:
      <ul><li>We apologize for any inconvenience caused by the delay in receiving your confirmation message. We request you to check your Spam Messages and junk Folder once. If you are still facing the issue initiate a support ticket from our support page and provide details of your last transaction. Our team will promptly verify your transaction and ensure you receive a confirmation message.</li></ul>
  },
  {
    question: "What's the process for claiming a transaction that has failed?",
    answer:
      <ul><li>If you encounter a failed transaction, please Visit our support page and raise a ticket. Remember to include the Unique Transaction Reference (UTR) number and attach a screenshot of the transaction. Once we receive this information, we will promptly initiate the necessary processing to address your claim. Your satisfaction is important to us, and we are here to assist you throughout this process.</li></ul>
  },
  {
    question: "What is the source of PayOnRamp's liquidity?",
    answer:
      <ul><li>Currently, we are sourcing the Liquidity from Cryptoforce India. However, we are also working with multiple global liquidity providers. These partnerships ensure that we can offer our users the most competitive cryptocurrency rates through our platform.</li></ul>
  },
  {
    question: "I have not received the OTP.",
    answer:
    <ul><li>If you have not received the One-Time Password (OTP), you can try clicking the 'Resend OTP' button. Please be aware that slight delays in OTP delivery can occasionally happen due to network traffic or technical issues. Should the problem persist, kindly raise a support ticket, providing your account details (such as your mobile number), and our team will swiftly work to resolve the issue for you. Your patience is appreciated as we strive to provide a resolution promptly. For further assistance, do not hesitate to reach out to our dedicated support team, as your satisfaction remains our priority.</li></ul>
  },
  {
    question: "The crypto payment was done but did not receive INR.",
    answer:
    <ul><li>We apologize for any inconvenience you have experienced. To address this matter: We kindly ask you to initiate a support ticket, providing us with the recent transaction hash and all relevant details. This will enable us to identify and address the issue promptly. By doing so, we can ensure the earliest resolution and accurate tracking of your payment. We understand the importance of this situation and assure you that we're committed to resolving it as swiftly as possible. If you require further assistance or have additional questions, please feel free to reach out to our support team. Your satisfaction is our top priority.</li></ul>
  },
  {
    question: "I am unable to make the payment.",
    answer:
    <ul><li>We are here to assist you with the payment issue. To better understand your situation and provide you with an accurate solution: We kindly request you to raise a support ticket and attach a screenshot of the error message you are encountering. This will allow us to gain insight into your issue and offer a precise and effective solution. Your cooperation is appreciated as we work to resolve this matter.</li></ul>
  },
  {
    question: "How long does it take to complete a transaction?",
    answer:
    <ul><li>The entire transaction process typically takes approximately 15 minutes for completion. In case users face any challenges during this process, they can choose to raise a support ticket or reach out to us via email. This will allow us to provide them with a suitable solution to address any issues they might encounter.</li></ul>
  },
  {
    question:(
        <Text
        type="H3"
        className="accordion-heading-title"
        text={"Is KYC Mandatory?"}
      />
    ),
    answer: (        
      <table>
        <h9>No, KYC is not universally mandatory. We offer three tiers of KYC: Minimum, Basic, and Full. Each tier comes with varying transaction limits. The minimum KYC tier only requires phone number registration to proceed. This allows you to choose the level of KYC that suits your preferences and needs.</h9>
        <tr>
          <th>KYC Tier</th>
          <th>Minimum</th>
          <th>Basic</th>
          <th>Full KYC</th>
        </tr>
        <tr>
          <td>Requirements</td>
          <td>Mobile number + OTP verification before every transaction</td>
          <td>Mobile number + OVP (Official Document Verification)</td>
          <td>
            Mobile number + OVP (Official Document Verification) + Video KYC
          </td>
        </tr>
      </table>
    ),
  },
  {
    question: 'Guidelines for Submitting Documents for KYC Verification.',
    answer: (
      <ul>
            <li>Ensure all documents are clear, legible, and free of blur or distortion.</li>
            <li>Upload original documents issued by authorities; avoid photocopies.</li>
            <li>For identity proof (e.g., Aadhar card, PAN card), ensure clear photos without glare or reflections.</li>
            <li>When uploading Aadhar card, verify and upload the correct side as per instructions.</li>
      </ul>
    ),
  },
  {
    question: "Which fiat currencies does PayOnRamp support?",
    answer:
    <ul><li>As of now, PayOnRamp supports Indian Rupee (INR). However, we are actively working to expand our offerings and will be introducing additional fiat currencies soon.</li></ul>
  },
  {
    question:
      "What are the INR withdrawal hours on PayOnRamp ?",
    answer:
      <ul><li>Withdrawals are available during banking hours, which are from 10 AM to 5 PM.</li>
      </ul>
  },
  {
    question:
      "How long does it take for a refund in case of an incorrect transfer?",
    answer:
      <ul><li>The refund process for incorrect transfers typically takes 48-72 hours. Our CS team will contact you to initiate the process.</li>
      </ul>
  },
  {
    question: 'What are the daily and monthly transaction limits? ',
    answer: (
      <ul>
            <li>
            With Basic KYC, users can transact up to Rs 20,000 per day and up to Rs 2,00,000 per month.
            </li>
            <li>With Full KYC, users can transact up to Rs 50,000 per day and up to Rs 5,00,000 per month.</li>
            <li>For  transaction limits above Rs 50,000 please reach out to <a href="mailto:support@payonramp.com">support@payonramp.com</a></li>
      </ul>
    ),
  },
];

const integrationList = [
  {
    question: "Which integration options are supported by your platform?",
    answer:
    <ul>
      <li>
        We provide support for: 
      <ul>
        <li> 
          widget/iframe integration (the user is directed to the PayOnRamp page)
          </li>  
      <li>
         URL-based integration (the PayOnRamp page opens inside the web page). 
         </li> 
      <li>
        White label Integration Solution.
        </li> 
      </ul>
      </li>
      </ul>
  },
  {
    question:
      "Is it possible to utilize my own user interface (UI) to incorporate the product into my decentralized application (dApp)?",
    answer:
    <ul><li>Absolutely, the white-label solution allows the partner to design its own UI, leveraging the backend support provided by PayOnRamp.</li></ul>
  },
  {
    question:
      "Where can I find information regarding minimum purchase amounts, expected price, and other relevant details?",
    answer:
    <ul><li>For your convenience, all pertinent information, including minimum purchase thresholds, projected prices, and other relevant details, will be accessible in real-time through APIs. This ensures that you receive up-to-date and accurate information directly, allowing you to make informed decisions as a user.</li></ul>
  },
];

const SupportView = () => {
    const navigate = useNavigate();
    const { navTabStatus,
        navTabHandleclick } = useSupport();
    const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
    return (
        <>
            <Section className="section-support-search">
                <Text type="H1" className="supprt-search-title" text="Got a burning question?" />
                <Flex flexColumn>
                    {/* <div className="row">
                        <div className="col-12 col-md-4">
                            <SearchForm />
                        </div>
                    </div> */}
                </Flex>
            </Section>
            <Section className="section-support-content">
                {isLoggedIn ? <div className="ticket-card">
                    <div className="ticket-left">
                        <Text type="H4" text="Submit a ticket" className="mb-0 ticket-title" />
                        <Text type="PARAGRAPH" className="mb-0 ticket-desc" text="Got a query/issue? Describe your problem by filling the form and submitting to the support team." />
                    </div>
                    <Button secondary type="button" onClick={()=> navigate('/tickets')} text="Raise a ticket" />
                </div> : <></>}
                <Flex flexColumn className="mb-5 mt-4 ml-3">
                    <Flex>
                        <Text type="PARAGRAPH" className="mb-0 mr-1" text="You can also send us an email to" />
                        <NavLink to="mailto:support@payonramp.com" className="font-weight-700 text-primary">support@payonramp.com</NavLink>
                    </Flex>
                    <Flex>
                        <Text type="PARAGRAPH" text="Or Call us at +91-8069222059." />
                    </Flex>
                </Flex>
                <Flex flexColumn>
                    <NavTab 
                        className="support-nav-tab"
                        lists={[
                            {
                                id: "0", 
                                navTabStatus:navTabStatus.navTabItemType,
                                icon: <SupportCustomerIcon />,
                                text: "FAQ’s",
                                navTabHandleclick: navTabHandleclick
                            },
                            {
                                id: "1",
                                navTabStatus:navTabStatus.navTabItemType,
                                icon: <GeneralQueriesIconIcon />,
                                text: "General Queries",
                                navTabHandleclick: navTabHandleclick
                            },
                            {
                                id: "2",
                                navTabStatus:navTabStatus.navTabItemType,
                                icon: <IntegrationIcon />,
                                text: "Integration",
                                navTabHandleclick: navTabHandleclick
                            }
                        ]}
                    />
                </Flex>
                {navTabStatus.navTabItemType === "0" && (
                    <Accordion accordionList={supportFaqList} />
                )}
                {navTabStatus.navTabItemType === "1" && (
                      <Accordion accordionList={generalQueriesList} />
                )}
                {navTabStatus.navTabItemType === "2" && (
                     <Accordion accordionList={integrationList} />
                )}
            </Section>
            <div className="chat-bottom">
                <Button className="chat-icon" secondary text={<ChatIcon width={31} height={28} />} />
            </div>
        </>
    )
}

export default SupportView;
