import React from 'react'
import HistoryView from './views/HistoryView'
import Layout from '../include/layout'

const HistoryController = (props) => {
    return (
        <Layout>
            <HistoryView />
        </Layout>
    )
}

export default HistoryController