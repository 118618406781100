import React from 'react'
import Text from '../text';

const OptionSelectItem = (props) => {
    const {src, text, subText, min, code} = props;
    return (
        <div className="option-select-item">
            <div className="osi-left">
                {/* <div className="osi-img">
                    <Image src={src} alt={text} />
                </div> */}
                <div className="osi-text">
                    <Text type="LABEL" text={text} />
                    <Text type="SPAN" text={subText} />
                </div>
            </div>
            <div className="osi-number">
                {min && <Text type="SPAN" text={`min: ${min}`} />}
                {code && <Text type="SPAN" className="osi-number-code" text={code} />}
            </div>
        </div>
    )
}

export default OptionSelectItem