import React from 'react'
import Layout from '../include/layout'
import TicketsView from './views/TicketsView'

const Tickets = () => {
    return (
        <Layout>
            <TicketsView />
        </Layout>
    )
}

export default Tickets