import React, { useEffect, useRef } from 'react';
// components
import LaunchKyc from '../authentication/kyc';


const HyperVerge = () => {
  const kyc = useRef(null);

  useEffect(() => {
    document.body.classList.add('kyc-manual');
    return () => {
      document.body.classList.remove('kyc-manual');
    };
  }, []);

  useEffect(() => {
    kyc.current.onInitiateKyc();
  }, [kyc]);

  return (
    <>
      <LaunchKyc ref={kyc} />
    </>
  )
}

export default HyperVerge;