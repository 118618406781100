import React from "react";

const Flex = (props) => {
  return (
    <div
      className={`flex-class d-flex ${props.flexWrap ? 'flex-wrap' : '' } ${props.flexColumn ? "flex-column" : ""} ${props.className ? props.className : ""} ${props.alignItems === "start" ? "align-items-start" : ""} ${props.alignItems === "end" ? "align-items-end" : ""} ${props.alignItems === "center" ? "align-items-center" : ""} ${props.justifyContent === "start" ? "justify-content-start" : ""} ${props.justifyContent === "end" ? "justify-content-end" : ""} ${props.justifyContent === "center" ? "justify-content-center" : ""} ${props.justifyContent === "between" ? "justify-content-between" : ""} ${props.justifyContent === "around" ? "justify-content-around" : ""} ${props.justifyContent === "evenly" ? "justify-content-evenly" : ""}`}
    >
      {props.children}
    </div>
  );
};

export default Flex;
