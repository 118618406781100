import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Image from "../../../components/image";
import Text from "../../../components/text";
import Checkbox from "../../../components/input/checkbox";
import LoginImage from "../../../assets/img/login-image.png";
import Button from "../../../components/button";
import Flex from "../../../components/flex";
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { getUserOTPFetch } from "../../../redux/user/reducers";
import { saveUserPhoneNUmber } from "../../../redux/user/persistReducer";
import { FORMIK_REGEX, STATUS_CODE } from "../../../utils/constants";
import Loading from "../../../components/svg/loading";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      phonenumber: "",
      iCertify: false,
    },
    validationSchema: Yup.object({
      iCertify: Yup.boolean().oneOf([true], "Please certify"),
      phonenumber: Yup.string()
        .required("Please enter a valid Phone Number")
        .matches(
          FORMIK_REGEX.PHONE_NUMBER_REGEX,
          "Please enter a valid Phone Number"
        ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const callback = (response) => {
        setLoading(false);
        if (response.status === STATUS_CODE.successful) {
          props.onOpenVerifyModal();
        }
      };
      dispatch(getUserOTPFetch({ phonenumber: values?.phonenumber, callback }));
      dispatch(saveUserPhoneNUmber(values));
    },
  });

  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-left">
          <Image src={LoginImage} alt="Login" />
        </div>
        <div className="auth-right">
          <form onSubmit={formik.handleSubmit}>
            <Flex flexColumn justifyContent="between">
              <Flex flexColumn>
                <Text type="H2" className="auth-title" text="Login" />
                <div className="form-box">
                  <PhoneInput
                    className="phone-number-field"
                    defaultCountry={"IN"}
                    countries={["IN"]}
                    international
                    countryCallingCodeEditable={false}
                    addInternationalOption={false}
                    name="phonenumber"
                    placeholder="Enter phone number"
                    value={formik.values.phonenumber}
                    onChange={(value) =>
                      formik.setFieldValue("phonenumber", value)
                    }
                  />
                  <div className="message-error">
                    <small>
                      {formik.touched.phonenumber && formik.errors.phonenumber
                        ? formik.errors.phonenumber
                        : null}
                    </small>
                  </div>
                </div>
              </Flex>
              <Flex flexColumn>
                <div className="form-box">
                  <Checkbox
                    value={formik.values.iCertify}
                    label={
                      <Flex flexWrap>
                        <Text
                          type="SPAN"
                          text="I certify that I am 18 years of age or older, and agree to the"
                          className="d-inline-flex"
                        />
                        <NavLink
                          to="/userAgreement"
                          className="mr-1 font-weight-500"
                        >
                          User Agreement
                        </NavLink>{" "}
                        and{" "}
                        <NavLink
                          to="/userPolicy"
                          className="ml-1 font-weight-500"
                        >
                          Privacy Policy
                        </NavLink>
                        .
                      </Flex>
                    }
                    id="iCertify"
                    isChecked={formik.values.iCertify}
                    onChange={formik.handleChange}
                    errormessage={
                      formik.touched.iCertify && formik.errors.iCertify
                        ? formik.errors.iCertify
                        : null
                    }
                  />
                </div>
                <Flex className="action-button">
                  <Button type="submit" text={loading ? <Loading /> : "Login"} disabled={loading} />
                </Flex>
              </Flex>
            </Flex>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
