import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useTickets = () => {
    const navigate = useNavigate();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const onOpenModal = () => {
        setIsOpenModal(true);
    }
    const onCloseModal = () => {
        setIsOpenModal(false);
    }
    return (
        {
            navigate,
            isOpenModal,
            onOpenModal,
            onCloseModal
        }
    )
}

export default useTickets