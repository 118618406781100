import React from "react";
import Flex from "../../../../../components/flex";
import Text from "../../../../../components/text";
import Button from "../../../../../components/button";
import { saveWalletAddress } from "../../../../../redux/deposit/persistReducer";
import { saveUserBank } from "../../../../../redux/withdrawal/persistReducer";
import { useDispatch } from "react-redux";

const CancelPurchase = (props) => {
  const dispatch = useDispatch();
  const { onCancel, onProceed } = props;
  return (
    <Flex flexColumn justifyContent="between" className="p-4 height-100">
      <Flex flexColumn className="mt-5">
        <Text
          type="H2"
          className="text-center mt-5 mb-1"
          text="Cancel purchase?"
        />
        <Text
          type="PARAGRAPH"
          className="text-center font-size-14"
          text="Are you sure that you want to cancel the transaction? Please confirm the action."
        />
      </Flex>
      <Flex justifyContent="center" alignItems="center" className="mb-4">
        <Button
          type="button"
          style={{ textDecoration: "inherit" }}
          linkDark
          onClick={() => {
            onCancel();
          }}
          text="Cancel"
        />
        <Button
          type="button"
          danger
          onClick={() => {
            onProceed();
            dispatch(saveWalletAddress(''));
            dispatch(saveUserBank(null));
          }}
          text="Proceed"
        />
      </Flex>
    </Flex>
  );
};

export default CancelPurchase;
