import React from 'react'
import Text from '../text'

const ProgressBar = (props) => {
    const {percent, balance, maxAmount} = props
    return (
        <div>
            <div className="progress-bar-wrapper">
                <div className="progress-percent" style={{width: percent ? `${percent}%` : '100%'}}></div>
            </div>
            <div className="price-min-range-max">
                <div className="price-balance">
                    <Text type="SPAN" text={`₹ ${balance} used`} />
                </div>
                <Text type="SPAN" text={`max ₹ ${maxAmount}`} />
            </div>
        </div>
    )
}

export default ProgressBar