import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import ChevronDownIcon from "../../../../components/svg/chevronDownIcon";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpFetch } from "../../../../redux/withdrawal/reducer";
import { APPLICATION_ID } from "../../../../utils/constants";
import { getPhoneNumberOtpFetch } from "../../../../redux/withdrawal/reducer";

import { getUserOTPFetch, verifyUserOTPFetch } from "../../../../redux/user/reducers";

const BuyLogin = (props) => {
  const dispatch = useDispatch();
  const { otpReferenceDetails, phoneNumber } = useSelector(
    (state) => state?.userPersistReducer
  );
  const { exchangeRateBuy, markUp, applicationId, dexQuote, walletInfo  } = useSelector((state) => state?.userReducer);

  const { buyData, walletAddress, buyReceiveData } = useSelector(
    (state) => state?.depositPersistReducer
  );
  const exchangeRate = exchangeRateBuy;
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string(),
    }),
    onSubmit: (values) => {
      dispatch(
        verifyUserOTPFetch({
          values: {
            otpReferenceKey: otpReferenceDetails.otpReferenceKey,
            otp: values.otp,
            phonenumber: phoneNumber?.mobileNumber,
          },
          callback: props.VerifyOtpCallback,
        })
      );
    },
  });
  const phonenumber = phoneNumber?.mobileNumber;
  const [counter, setCounter] = useState(59);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const resendOtp = () => {
    dispatch(getUserOTPFetch({ values: { phonenumber } }));
    setCounter(counter + 59);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        formik.handleSubmit();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [formik]);

  return (
    <div className="flow-box">
      <form onSubmit={formik.handleSubmit} className="height-100">
        <Flex flexColumn justifyContent="between" className="p-4 height-100">
          <Flex flexColumn>
            <Button
              linkDark
              type="button"
              iconLeft={<ChevronDownIcon left={true} width="15" height="9" />}
              className="mback-btn"
              onClick={() => {
                props.goToBack();
              }}
            />
            <Text type="H3" text="Buy Crypto" className="text-center mb-30" />
            <Text type="LEGEND" text="OTP" />
            <div className="form-box">
              <div className="form-field">
                <Text type="LABEL" text={"Enter OTP"} className="form-label" />
                <OtpInput
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  containerStyle="otp-field mt-0 justify-content-start"
                  inputStyle="otp-control"
                  inputType={"number"}
                  value={formik.values.otp}
                  shouldAutoFocus={true}
                  onChange={(value) => {
                    formik.setFieldValue("otp", value);
                  }}
                />
                {formik.touched.otp && formik.errors.otp ? (
                  <div className="message-error">
                    <small>{formik.errors.otp}</small>
                  </div>
                ) : null}
              </div>
            </div>
            <Flex flexColumn alignItems="center">
              {!counter > 0 ? (
                <Button
                  onClick={resendOtp}
                  linkPrimary
                  className="font-size-13 text-primary mb-3 font-weight-400"
                  text="Resend OTP"
                />
              ) : (
                <Text
                  type="SPAN"
                  className="font-size-13 text-primary mb-3"
                  text={`Resend OTP in ${counter}sec...`}
                />
              )}
            </Flex>
          </Flex>
          <Flex flexColumn className="mt-3">
            <Button type="submit" text="Submit" />
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

export default BuyLogin;
