import React from "react";

const DownArrowIcon = ({ width, height, up }) => {
  return (
    <svg
      style={{
        transform: up ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform ease-in 0.2s",
      }}
      width={width}
      height={height}
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25005 9.50005C8.05823 9.50005 7.86623 9.42673 7.7198 9.2803L0.219797 1.7803C-0.0732656 1.48723 -0.0732656 1.01267 0.219797 0.719797C0.512859 0.426922 0.987422 0.426734 1.2803 0.719797L8.25005 7.68955L15.2198 0.719797C15.5129 0.426734 15.9874 0.426734 16.2803 0.719797C16.5732 1.01286 16.5734 1.48742 16.2803 1.7803L8.7803 9.2803C8.63386 9.42673 8.44186 9.50005 8.25005 9.50005Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DownArrowIcon;

DownArrowIcon.defaultProps = {
  width: "17",
  height: "10",
};
