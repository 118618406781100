
import React from 'react'

const AttachmentIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 26 20" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M23.3333 0H2C1.46957 0 0.960859 0.210713 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18.6667C0 19.0203 0.140476 19.3594 0.390524 19.6095C0.640573 19.8595 0.979711 20 1.33333 20H24C24.3536 20 24.6928 19.8595 24.9428 19.6095C25.1929 19.3594 25.3333 19.0203 25.3333 18.6667V2C25.3333 1.46957 25.1226 0.960859 24.7475 0.585786C24.3725 0.210713 23.8638 0 23.3333 0ZM2 1.33333H23.3333C23.5101 1.33333 23.6797 1.40357 23.8047 1.5286C23.9298 1.65362 24 1.82319 24 2V14.83L18.3167 9.14667C18.1928 9.0227 18.0458 8.92435 17.8839 8.85725C17.7221 8.79016 17.5486 8.75562 17.3733 8.75562C17.1981 8.75562 17.0246 8.79016 16.8627 8.85725C16.7009 8.92435 16.5538 9.0227 16.43 9.14667L13.9567 11.62L8.98333 6.64667C8.8595 6.5227 8.71245 6.42435 8.55059 6.35725C8.38872 6.29015 8.21522 6.25562 8.04 6.25562C7.86478 6.25562 7.69128 6.29015 7.52941 6.35725C7.36755 6.42435 7.2205 6.5227 7.09667 6.64667L1.33333 12.4133V2C1.33333 1.82319 1.40357 1.65362 1.5286 1.5286C1.65362 1.40357 1.82319 1.33333 2 1.33333ZM2 18.6667C1.82319 18.6667 1.65362 18.5964 1.5286 18.4714C1.40357 18.3464 1.33333 18.1768 1.33333 18V14.3L8.04 7.59L13 12.5633C13.1238 12.6873 13.2709 12.7856 13.4327 12.8527C13.5946 12.9198 13.7681 12.9544 13.9433 12.9544C14.1186 12.9544 14.2921 12.9198 14.4539 12.8527C14.6158 12.7856 14.7628 12.6873 14.8867 12.5633L17.36 10.09L24 16.7167V18C24 18.1768 23.9298 18.3464 23.8047 18.4714C23.6797 18.5964 23.5101 18.6667 23.3333 18.6667H2Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default AttachmentIcon

AttachmentIcon.defaultProps = {
    width: '69',
    height: '20',
}