import React from 'react'

const ArrowRightIcon = ({width, height, left, up, down}) => {
    return (
        <svg
            style={{transform: left ? 'rotate(180deg)' : up ? 'rotate(-90deg)' : down ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform ease-in 0.2s'}} 
            width={width} 
            height={height} 
            viewBox="0 0 23 13" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M1.09323 7.3228H19.4337L15.9976 10.7589C15.0107 11.7458 16.4911 13.2262 17.4781 12.2392L20.6074 9.10508L22.6964 7.01283C23.1012 6.60555 23.1012 5.94779 22.6964 5.54052L17.4781 0.31615C17.2795 0.112138 17.0063 -0.00233163 16.7215 3.60124e-05C15.7817 0.000154394 15.3204 1.14491 15.9976 1.79665L19.4418 5.23271H1.03919C-0.408287 5.30453 -0.30021 7.39485 1.09323 7.3228Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default ArrowRightIcon

ArrowRightIcon.defaultProps = {
    width: '15',
    height: '15'
}