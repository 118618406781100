import { toast } from 'sonner';

class Toaster {
  success = (message) => {
    toast.success(message);
  };

  error = (message) => {
    toast.error(message);
  };

  info = (message) => {
    toast.info(message); 
  };

  warning = (message) => {
    toast.warning(message); 
  };

  promise = (promise, messages) => {
    toast.promise(promise, {
      loading: messages.loading,
      success: (data) => messages.success(data),
      error: messages.error,
    });
  };

}

export const toasts = new Toaster();
