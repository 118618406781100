import React from 'react'

const LinkedinIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 15 15" 
            fill="currentColor" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M13.9076 0H1.32095C0.71801 0 0.230469 0.476002 0.230469 1.06451V13.7031C0.230469 14.2916 0.71801 14.7705 1.32095 14.7705H13.9076C14.5105 14.7705 15.001 14.2916 15.001 13.706V1.06451C15.001 0.476002 14.5105 0 13.9076 0ZM4.61257 12.5867H2.42008V5.53605H4.61257V12.5867ZM3.51633 4.57539C2.81242 4.57539 2.2441 4.00707 2.2441 3.30605C2.2441 2.60503 2.81242 2.03671 3.51633 2.03671C4.21735 2.03671 4.78567 2.60503 4.78567 3.30605C4.78567 4.00419 4.21735 4.57539 3.51633 4.57539ZM12.8171 12.5867H10.6275V9.15944C10.6275 8.34302 10.6131 7.29005 9.48799 7.29005C8.34847 7.29005 8.17538 8.18147 8.17538 9.10174V12.5867H5.98865V5.53605H8.08883V6.49959H8.11768C8.40905 5.9457 9.1245 5.36007 10.189 5.36007C12.4075 5.36007 12.8171 6.81981 12.8171 8.71805V12.5867V12.5867Z"
            />
        </svg>
    )
}

export default LinkedinIcon

LinkedinIcon.defaultProps = {
    width: '15',
    height: '15'
}