import React from "react";
import Section from "../../../components/section";
import Text from "../../../components/text";
import Flex from "../../../components/flex";
import image1 from "../../../assets/img/becomePartner/header1.png";
import image2 from "../../../assets/img/becomePartner/header2.png";
import Image from "../../../components/image";
import BecomeAPartnerForm from "../../../components/becomeAPartnerForm/BecomeAPartnerForm";
const BecomeAPartnerView = () => {
  return (
    <>
      <Section containerWidth1100 className="pt-0 pb-0">
        <Flex flexColumn>
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-7">
              <div className="hero-partnership-caption" style={{paddingTop:80,paddingRight:120}}>
                <Text
                  type="H1"
                  className="supprt-search-title"
                  text="Partner with PayOnRamp to accelerate growth"
                />
                <Text
                  type="PARAGRAPH"
                  text="Join a global community of partners and help ambitious businesses with industry-leading payments and financial infrastructure."
                />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <BecomeAPartnerForm />
            </div>
          </div>
        </Flex>
      </Section>
      <Section className="">
        <div className="row">
          <div className="col-12 col-md-6 p-5 my-auto">
            <Text
              type="H2"
              className="text-center"
              text="PayOnRamp, your trusted gateway to the world of cryptocurrencies and digital finance."
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Image src={image2} alt="" />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-md-6">
            
            <Image src={image1} alt="" />
          </div>
          <div className="col-12 col-md-6 p-5 my-auto">
          <Text
              type="H2"
              className="d-flex"
              text="PayOnRamp is a cutting-edge cryptocurrency exchange and platform."
            />
          </div>
        </div>
      </Section>
    </>
  );
};

export default BecomeAPartnerView;
