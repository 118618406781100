import React from 'react'
import Icon from '../icon'
import TicketIcon from '../svg/ticketIcon'
import Text from '../text';
import Button from '../button';

const NoticketsRaised = (props) => {
    return (
        <div className="no-row-data-list-wrap">
            <TicketIcon />
            <Text type="H4" text="No tickets raised" className="mt-3 mb-1" />
            <Button type="button" text="Refresh" className="mt-2" />
        </div>
    )
}

export default NoticketsRaised