import React from 'react'

const BankFillIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 34 34" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M2.83325 28.334H31.1666V31.1673H2.83325V28.334ZM5.66659 17.0007H8.49992V26.9173H5.66659V17.0007ZM12.7499 17.0007H15.5833V26.9173H12.7499V17.0007ZM18.4166 17.0007H21.2499V26.9173H18.4166V17.0007ZM25.4999 17.0007H28.3333V26.9173H25.4999V17.0007ZM2.83325 9.91732L16.9999 2.83398L31.1666 9.91732V15.584H2.83325V9.91732ZM16.9999 11.334C17.7823 11.334 18.4166 10.6997 18.4166 9.91732C18.4166 9.13492 17.7823 8.50065 16.9999 8.50065C16.2175 8.50065 15.5833 9.13492 15.5833 9.91732C15.5833 10.6997 16.2175 11.334 16.9999 11.334Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default BankFillIcon

BankFillIcon.defaultProps = {
    width: '34',
    height: '34'
}