import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import BecomeAPartnerView from "./views/BecomeAPartnerView";
import Layout from "../include/layout";

const PartnershipController = (props) => {
  useEffect(() => {
    document.body.classList.add("become-a-partner-page");
    return () => {
      document.body.classList.remove("become-a-partner-page");
    };
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Collaborate with PayOnRamp for Seamless Payment Solutions</title>
        <meta
          name="description"
          content="Join forces with PayOnRamp to create a synergy of seamless payment solutions. Collaborate with us and unlock the potential for efficient and secure transactions in your business."
        />
        <link rel="canonical" href="https://payonramp.com/becomeAPartner" />
      </Helmet>
      <BecomeAPartnerView />
    </Layout>
  );
};

export default PartnershipController;
