import React from "react";
import TickIcon from "../svg/tickIcon";

const Checkbox = ({
  mb0,
  isChecked,
  readonly,
  value,
  id,
  onChange,
  label,
  mandatory,
  errormessage,
}) => {
  return (
    <div className={`form-check-field ${mb0 ? 'mb-0' : ''}`}>
      <div
        className={`form-check ${isChecked ? "form-check-checked" : ""}`}
      >
        <div className="form-check-box">
          <input
            className="form-check-input"
            type="checkbox"
            value={value}
            id={id}
            onChange={onChange}
            checked={isChecked}
            readOnly={readonly}
          />
          {isChecked && <TickIcon />}
        </div>
        <label className="form-check-label" htmlFor={id}>
          {label} {mandatory && <span className="text-mandatory">*</span>}
        </label>
      </div>
      {errormessage && (
        <div className="message-error">
          <small>{errormessage}</small>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
