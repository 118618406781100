import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "../../../components/modal";
import KYCSuccess from "../../home/components/buy/buyKYC/kycSuccess";

import {
  kycKeysFetch,
  updateKycStatusFetch,
} from "../../../redux/user/reducers";

const KYC = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [isKycModal, setKycModal] = useState(false);

  useImperativeHandle(ref, () => ({
    onInitiateKyc() {
      dispatch(
        kycKeysFetch({
          initiateKYCVerification,
          type: { kycType: "BASIC_KYC" },
        })
      );
    }
  }));

  const callback = () => {
    setKycModal(true);
  };

  const initiateKYCVerification = (kycKeys) => {
    try {
      const hyperKycConfig = new window.HyperKycConfig(
        kycKeys.accessToken,
        kycKeys.workflowId || "PayOnRampAML",
        kycKeys.transactionId || Math.random().toString()
      );
      const handler = (HyperKycResult) => {
        // eslint-disable-next-line default-case
        switch (HyperKycResult?.status) {
          case "user_cancelled": {
            window.parent.postMessage({ status: 'USER_CLOSE' }, "*");
            break;
          }
          case "error": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "error", kycID: kycKeys.transactionId },
              })
            );
            break;
          }
          case "failure": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "failure", kycID: kycKeys.transactionId },
              })
            );
            break;
          }
          case "auto_declined": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "need_review", kycID: kycKeys.transactionId },
                callback,
              })
            );
            break;
          }
          case "needs_review": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "need_review", kycID: kycKeys.transactionId },
                callback,
              })
            );
            break;
          }
          case "auto_approved": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "SUCCESS", kycID: kycKeys.transactionId },
                callback,
              })
            );
            break;
          }
        }
      };
      window.HyperKYCModule.launch(hyperKycConfig, handler);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <script
        type="text/javascript"
        src="https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@5.2.1/src/sdk.min.js"
        async
      />
      {isKycModal && (
        <Modal
          open={isKycModal}
          smallMedium minHeight onClose={() => setKycModal(false)}>
          <KYCSuccess onClick={() => setKycModal(false)} />
        </Modal>
      )}
    </>
  );
});

export default KYC;
