import React from 'react'

const FacebookIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 15 15" 
            fill="currentColor" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M14.7705 7.38525C14.7705 3.30648 11.464 0 7.38525 0C3.30648 0 0 3.30648 0 7.38525C0 11.0714 2.70066 14.1267 6.2313 14.6808V9.52004H4.35614V7.38525H6.2313V5.75818C6.2313 3.90726 7.3339 2.88486 9.02082 2.88486C9.82858 2.88486 10.674 3.0291 10.674 3.0291V4.84657H9.74276C8.82537 4.84657 8.53919 5.4159 8.53919 6.00051V7.38525H10.5874L10.26 9.52004H8.53919V14.6808C12.0698 14.1267 14.7705 11.0714 14.7705 7.38525Z"
            />
        </svg>
    )
}

export default FacebookIcon

FacebookIcon.defaultProps = {
    width: '15',
    height: '15'
}