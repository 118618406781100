import React from "react";

const Button = (props) => {
  const {
    className,
    disabled,
    danger,
    secondary,
    white,
    linkBlack,
    linkDark,
    linkPrimary,
    outline,
    type,
    onClick,
    name,
    style,
    iconLeft,
    text,
    icon,
  } = props;
  return (
    <button
      className={`btn ${className ? className : ""} ${
        disabled
        ? "btn-disabled"
        : linkBlack
        ? "btn-link-black"
        : linkDark
        ? "btn-link-dark"
        : linkPrimary
        ? "btn-link-primary"
        : danger
        ? "btn-danger"
        : white
        ? "btn-white"
        : secondary
        ? "btn-secondary"
        : "btn-primary"
      } ${outline ? "btn-outline" : ""}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      name={name}
      style={style}
    >
      {iconLeft && <span className="btn-icon-left">{iconLeft}</span>}
      {text}
      {icon && <span className="btn-icon-right">{icon}</span>}
    </button>
  );
};

export default Button;
