import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
// components
import Section from '../../../components/section';
import Flex from '../../../components/flex';
import Text from '../../../components/text';
import DropdownCheckbox from '../../../components/dropdown/dropdownCheckbox';
import Input from '../../../components/input';
import CardTable from '../../../components/cardTable';
import Button from '../../../components/button';
import NoRowDataList from '../../../components/cardTable/noticketsRaised';
import ChevronRightIcon from '../../../components/svg/chevronRightIcon';
import Modal from '../../../components/modal';
import AddRaiseTicket from '../component/addRaiseTicket';
// hooks
import useTickets from '../hooks/tickets.hook';
// redux
import { fetchTickets } from '../../../redux/withdrawal/reducer';

const TicketsView = () => {
  const dispatch = useDispatch();
  const { tickets } = useSelector((state) => state.withdrawalReducer);

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    dispatch(
      fetchTickets()
    );
  }, []);

  useEffect(() => {
    setRowData(tickets);
  }, [tickets]);

  const { navigate, isOpenModal, onOpenModal, onCloseModal } = useTickets();
  const [searchValue, setSearchValue] = useState('');
  const onChangeHandler = (event) => {
    setSearchValue(event.target.value);
    setRowData(
      tickets.filter(
        (ticket) => {
          return ticket?.id == event.target.value ||
            ticket?.issueType?.includes(event.target.value)
        }
      )
    );
  };


  const columnDefs = [
    { headerName: 'S no', valueGetter: 'node.rowIndex + 1', width: 50 },
    { headerName: 'Ticket ID', field: 'id' },
    { headerName: 'Issue type', field: 'issueType' },
    { headerName: 'Requested on', field: 'createdAt' },
    { headerName: 'Status', field: 'status', cellClass: cellClass },
    // { headerName: '', field: 'action', cellRenderer: ActionRenderer },
  ];

  function cellClass(params) {
    return params.value === 'OPEN'
      ? 'bg-status-open'
      : params.value === 'PENDING'
        ? 'bg-status-in-progess'
        : params.value === 'CLOSED'
          ? 'bg-status-closed'
          : '';
  }
  // function ActionRenderer(params) {
  //   return (
  //     <Flex alignItemsCenter justifyContent="center" className="h-100">
  //       <Button
  //         type="button"
  //         linkDark
  //         text={<ChevronRightIcon />}
  //         onClick={() => navigate('/tickets/detail')}
  //       />
  //     </Flex>
  //   );
  // }
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);
  // const onCellClicked = () => navigate('/tickets/detail');

  const paginationPageSize = 10;
  const noRowsOverlayComponent = useMemo(() => {
    return NoRowDataList;
  }, []);
  return (
    <Section containerWidth1100 className="pt-0">
      <Flex justifyContent="between" className="filter-list-wrp mb-20">
        <Flex className="filter-list-left">
          <Text type="H1" text="My Tickets" className="page-title" />
        </Flex>
        <Flex className="filter-list-right">
          <div className="search-list-wrap">
            <Input
              mb0
              noLabel
              type="search"
              name="search"
              placeholder="Search for a ticket"
              value={searchValue}
              onChange={onChangeHandler}
            />
          </div>
          <Button small text="+ Raise New Ticket" onClick={onOpenModal} />
        </Flex>
      </Flex>
      <CardTable>
        <div className="ag-theme-alpine">
          <AgGridReact
            // onCellClicked={onCellClicked}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={paginationPageSize}
            noRowsOverlayComponent={noRowsOverlayComponent}
          ></AgGridReact>
        </div>
      </CardTable>
      {isOpenModal && (
        <Modal
          open={isOpenModal}
          smallMedium onClose={onCloseModal}>
          <AddRaiseTicket handleSubmit={onCloseModal} />
        </Modal>
      )}
    </Section>
  );
};

export default TicketsView;
