import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Image from "../../../components/image";
import Text from "../../../components/text";
import VerfityImage from "../../../assets/img/verfity-img.png";
import Button from "../../../components/button";
import Flex from "../../../components/flex";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import ArrowRightIcon from "../../../components/svg/arrowRightIcon";
import { getUserOTPFetch, verifyUserOTPFetch } from "../../../redux/user/reducers";
import Input from "../../../components/input";
import Loading from "../../../components/svg/loading";

const VerificationCode = (props) => {
  const { goToBack } = props;
  const dispatch = useDispatch();
  const { otpReferenceDetails, userPhoneNumber, newUser } = useSelector(
    (state) => state?.userPersistReducer
  );
  const { isLoading } = useSelector((state) => state?.withdrawalReducer);

  const formik = useFormik({
    initialValues: {
      otp: "",
      referralCode: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Please enter OTP"),
    }),
    onSubmit: (values) => {
      dispatch(
        verifyUserOTPFetch({
          values: {
            otpReferenceKey: otpReferenceDetails.otpReferenceKey,
            otp: values.otp,
            phonenumber: userPhoneNumber?.phonenumber,
            referralCode: values?.referralCode,
          },
          callback: props.VerifyUserOtpCallback,
        })
      );
    },
  });

  const phonenumber = userPhoneNumber?.phonenumber;
  const [counter, setCounter] = useState(59);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendOtp = () => {
    dispatch(getUserOTPFetch({ phonenumber }));
    setCounter(counter + 59);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        formik.handleSubmit();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [formik]);

  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-left">
          <Button
            linkDark
            icon={<ArrowRightIcon width="20" height="15" left />}
            className="auth-back-btn"
            onClick={() => {
              goToBack();
            }}
          />
          <Image src={VerfityImage} alt="Verification code" />
        </div>
        <div className="auth-right">
          <form onSubmit={formik.handleSubmit}>
            <Flex flexColumn justifyContent="between">
              <Flex flexColumn>
                <Text
                  type="H2"
                  className="auth-title"
                  text="Verification code"
                />
                <Text
                  type="PARAGRAPH"
                  className="font-size-13 text-primary mb-0"
                  text="Please enter the 6-digit verification code sent to"
                />
                <Text
                  type="PARAGRAPH"
                  className="font-size-13 text-primary mb-0"
                  text={userPhoneNumber?.phonenumber}
                />
                <div className="form-box">
                  <OtpInput
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    containerStyle="otp-field"
                    inputStyle="otp-control"
                    shouldAutoFocus={true}
                    inputType={"number"}
                    value={formik.values.otp}
                    onChange={(value) => {
                      formik.setFieldValue("otp", value);
                    }}
                  />
                  {formik.touched.otp && formik.errors.otp ? (
                    <div className="message-error">
                      <small>{formik.errors.otp}</small>
                    </div>
                  ) : null}
                </div>
                {newUser ? (
                  <div className="form-box">
                    <Input
                      label="Referral Code"
                      name="referralCode"
                      value={formik.values.referralCode}
                      placeholder="Enter Referral Code"
                      type="text"
                      onChange={(e) => {
                        formik.setFieldValue("referralCode", e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                ) : null}
              </Flex>
              <Flex flexColumn alignItems="start">
                {!counter > 0 ? (
                  <Button
                    onClick={resendOtp}
                    linkPrimary
                    className="font-size-13 text-primary mb-3 font-weight-400"
                    text="Resend OTP"
                  />
                ) : (
                  <Text
                    type="SPAN"
                    className="font-size-13 text-primary mb-3"
                    text={`Resend OTP in ${counter}sec...`}
                  />
                )}
                <Flex className="action-button">
                  <Button
                    type="submit"
                    text={isLoading ? <Loading /> : "Verify"}
                    disabled={isLoading}
                  />
                </Flex>
              </Flex>
            </Flex>
          </form>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
