import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PartnershipView from './views/PartnershipView';
import Layout from '../include/layout';

const PartnershipController = (props) => {
  useEffect(() => {
    document.body.classList.add('partnership-page');
    return () => {
      document.body.classList.remove('partnership-page');
    };
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>
          Unlock Business Opportunities with Inclusive Payment Solutions
        </title>
        <meta
          name="description"
          content="Open doors to new business possibilities with our inclusive payment solutions. Maximize revenue, reach a wider audience, and create a seamless payment experience for your customers."
        />
        <link rel="canonical" href="https://payonramp.com/parternship" />
      </Helmet>
      <PartnershipView />
    </Layout>
  );
};

export default PartnershipController;
