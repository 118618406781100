import React, { useEffect, useState } from 'react';
import SearchFileGif from '../../../../../assets/img/gif/search-file.gif';
import HolographicCoinBtcGif from '../../../../../assets/img/gif/holographic-coin-btc.gif';
import SendToBankGif from '../../../../../assets/img/gif/sendtobank.gif';
import Flex from '../../../../../components/flex';
import IconTextCard from '../../../../../components/iconTextCard';
import Stepper from '../../../../../components/stepper';
import FadeInOut from '../../../../../components/fadeInOut';
import TransactionPending from '../transactionStatus/pending';
import {
  verifyTransactionFetch,
  updateTransactionData,
} from '../../../../../redux/deposit/reducer';
import { useDispatch, useSelector } from 'react-redux';
import TransactionFailed from '../transactionStatus/failed';

const steps = [
  {
    id: '1',
    text: 'Looking',
  },
  {
    id: '2',
    text: 'Converting',
  },
  {
    id: '3',
    text: 'Sending INR',
  },
];

const StepsProcess = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const { transactionDetails, cryptoTransferRequest } = useSelector(
    (state) => state.depositReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentStep((t) => {
        if (t >= 4) clearInterval(intervalId);
        return t < 4 ? t + 1 : t;
      });
    }, 9000);
    return () => clearInterval(intervalId);
  }, []);

  let timerId = null;

  const verifyTransaction = (timeout) => {
    if (
      timeout <= 0 ||
      cryptoTransferRequest !== 'PENDING' ||
      cryptoTransferRequest === 'SUCCESS'
    ) {
      if (timerId) {
        clearInterval(timerId);
        timerId = null;
      }
      // if (cryptoTransferRequest === "SUCCESS") {
      //   <TransactionSuccessful {...props} />;
      // }
      if (cryptoTransferRequest === 'CANCELLED') {
        <TransactionFailed {...props} />;
      } else {
        dispatch(updateTransactionData(transactionDetails));
      }
      return;
    }
    dispatch(verifyTransactionFetch());
  };

  useEffect(() => {
    const timeoutDuration = 60 * 1000; // 1 minute in milliseconds
    let callsCount = 0;
    let timerId;

    const timeoutId = setTimeout(() => {
      if (cryptoTransferRequest !== 'PENDING') {
        const time = new Date().getTime();
        const refreshTime = new Date(
          transactionDetails?.transactionTime
        ).getTime();
        const timeout = 60 * 1000 * 1 - time + refreshTime;
        verifyTransaction(timeout);
      } else {
        dispatch(verifyTransactionFetch());

        timerId = setInterval(() => {
          if (callsCount * 15 * 1000 >= timeoutDuration) {
            // Stop the interval after 1 minute
            clearInterval(timerId);
          } else {
            const time = new Date().getTime();
            const refreshTime = new Date(
              transactionDetails?.transactionTime
            ).getTime();
            const timeout = 60 * 1000 * 1.5 - time + refreshTime;

            verifyTransaction(timeout);
            callsCount++;
          }
        }, 15 * 1000);
      }

      // Cleanup function to clear the interval when the component unmounts
      return () => {
        clearTimeout(timeoutId);
        clearInterval(timerId);
      };
    }, [cryptoTransferRequest, transactionDetails]);
  });

  return (
    <>
      {currentStep == '4' ? (
        <FadeInOut className="h-100" show={currentStep == '4'}>
          {(cryptoTransferRequest === 'PENDING' ||
            cryptoTransferRequest === 'SUCCESS') && (
            <TransactionPending {...props} />
          )}
          {/* {cryptoTransferRequest === "SUCCESS" && (
            <TransactionSuccessful {...props} />
          )} */}
          {cryptoTransferRequest === 'CANCELLED' && (
            <TransactionFailed {...props} />
          )}
        </FadeInOut>
      ) : (
        <Flex flexColumn justifyContent="between" className="p-4 h-100">
          <Flex flexColumn alignItems="center">
            {currentStep == '1' && (
              <FadeInOut show={currentStep == '1'}>
                <IconTextCard
                  icon={SearchFileGif}
                  text="Looking for Deposit "
                />
              </FadeInOut>
            )}
            {currentStep == '2' && (
              <FadeInOut show={currentStep == '2'}>
                <IconTextCard
                  icon={HolographicCoinBtcGif}
                  text="Converting Crypto to INR "
                />
              </FadeInOut>
            )}
            {currentStep == '3' && (
              <FadeInOut show={currentStep == '3'}>
                <IconTextCard
                  icon={SendToBankGif}
                  text="Sending INR to your bank"
                />
              </FadeInOut>
            )}
          </Flex>
          <Flex flexColumn>
            <Stepper
              currentStep={currentStep}
              complete={complete}
              steps={steps}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default StepsProcess;
