import React from "react";
import Section from "../../../components/section";
import Text from "../../../components/text";
import classes from "./PrivacyPolicyView.module.css";

const PrivacyPolicyView = () => {
  const data = [
    {
      header: "Information We Collect",
      info: "We gather various types of data to enhance and provide our services.",
      text: [
        {
          subHeader: "Personal Information",
          subText:
            "When you create an account, we may collect information such as your name, email address, and contact details. This information is used to facilitate account creation, provide customer support, and communicate with you regarding our Services.",
        },
        {
          subHeader: "Financial Information",
          subText:
            "To facilitate transactions and comply with legal requirements, we collect certain financial information, such as your payment method details and cryptocurrency wallet addresses.",
        },
        {
          subHeader: "Transaction Information",
          subText:
            "We collect information about your transactions on our platform, including transaction history, amount, and recipient's information. This information helps us process your transactions and provide you with relevant information.",
        },
        {
          subheader: "Device and Usage Information",
          subText:
            "We may collect information about the device you use to access our Services, including your IP address, browser type, operating system, and usage patterns. This data helps us improve our Services and enhance security.",
        },
        {
          subHeader: "Cookies and Similar Technologies",
          subText:
            "We use cookies and similar technologies to collect information about your interactions with our Services. This includes information about your browsing behavior and preferences.",
        },
      ],
    },
    {
      header: "How We Use Your Information",

      info: "We use the collected information for various purposes.",
      text: [
        {
          subHeader: "Providing Services",
          subText:
            "To facilitate transactions, process payments, and provide customer support.",
        },
        {
          subHeader: "Improving Services",
          subText:
            "To analyze usage patterns, troubleshoot issues, and enhance the functionality of our platform.",
        },
        {
          subHeader: "Communication",
          subText:
            "To send you transaction confirmations, updates, and promotional materials.",
        },
        {
          subHeader: "Legal Compliance",
          subText:
            "To comply with legal obligations, respond to legal requests, and prevent fraudulent activities.",
        },
        {
          subHeader: "Marketing",
          subText:
            "With your consent, we may use your information to send you marketing communications about our Services and related products.",
        },
      ],
    },
    {
      header: "Information Sharing",
      info: "Your data serves multiple purposes, from facilitating transactions and improving our services to communication and legal compliance.",
      text: [
        {
          subHeader: "With Service Providers",
          subText:
            "We may share your personal information with third-party service providers who assist us in providing and maintaining our Services. These service providers have access to your information only to perform tasks on our behalf and are obligated not to disclose or use it for any other purpose.",
        },
        {
          subHeader: "For Legal Reasons",
          subText:
            "We may disclose your information to comply with legal obligations, respond to legal requests, or protect our rights, privacy, safety, or property. This may include sharing information with law enforcement agencies, government entities, or authorized third parties as required by applicable laws and regulations.",
        },
        {
          subHeader: "With Your Consent",
          subText:
            "We may share your information for other purposes if we have obtained your explicit consent to do so. You have the right to withdraw your consent at any time by contacting us.",
        },
        {
          subHeader: "Business Transactions",
          subText:
            "In the event of a merger, acquisition, or other business transaction involving our company, your information may be transferred to another entity. We will notify you before your information is transferred if such a transaction occurs and becomes subject to a different privacy policy.",
        },
        {
          subHeader: "Aggregated and De-Identified Data",
          subText:
            "We may share aggregated and de-identified information that does not personally identify you for various purposes, including statistical analysis, research, and improving our Services. This information cannot be used to identify you.",
        },
      ],
    },
    {
      header: "Your Choices",
      info: "Your data serves multiple purposes, from facilitating transactions and improving our services to communication and legal compliance.",
      text: [
        {
          subHeader: "Account Information",
          subText:
            "You can review and update your account information at any time by logging into your account and making changes in your profile settings. You can also contact us to request changes or account deletion.",
        },
        {
          subHeader: "Communication Preferences",
          subText:
            "You can choose to opt in or opt out of receiving promotional communications from us by adjusting your communication preferences in your account settings. You can also unsubscribe from marketing emails using the provided link.",
        },
        {
          subHeader: "Cookie Settings",
          subText:
            "You have the option to manage your cookie preferences. You can adjust cookie settings in your browser to accept or reject cookies. However, please note that certain features of our Services may not function properly without cookies.",
        },
        {
          subHeader: "Withdraw Consent",
          subText:
            "If you have provided consent for the use of your personal information, you have the right to withdraw your consent at any time. You can do this by contacting us.",
        },
        {
          subHeader: "Do Not Track Signals",
          subText:
            "Some web browsers offer a 'Do Not Track' (DNT) feature that allows you to express your privacy preferences. Our Services do not currently respond to DNT signals, but you can adjust your preferences in your browser settings.",
        },
      ],
    },
    {
      header: "Security",
      info: "Your data serves multiple purposes, from facilitating transactions and improving our services to communication and legal compliance.",
      text: [
        {
          subHeader: "Account Information",
          subText:
            "You can review and update your account information at any time by logging into your account and making changes in your profile settings. You can also contact us to request changes or account deletion.",
        },
        {
          subHeader: "Communication Preferences",
          subText:
            "You can choose to opt in or opt out of receiving promotional communications from us by adjusting your communication preferences in your account settings. You can also unsubscribe from marketing emails using the provided link.",
        },
        {
          subHeader: "Cookie Settings",
          subText:
            "You have the option to manage your cookie preferences. You can adjust cookie settings in your browser to accept or reject cookies. However, please note that certain features of our Services may not function properly without cookies.",
        },
        {
          subHeader: "Withdraw Consent",
          subText:
            "If you have provided consent for the use of your personal information, you have the right to withdraw your consent at any time. You can do this by contacting us.",
        },
        {
          subHeader: "Do Not Track Signals",
          subText:
            "Some web browsers offer a 'Do Not Track' (DNT) feature that allows you to express your privacy preferences. Our Services do not currently respond to DNT signals, but you can adjust your preferences in your browser settings.",
        },
      ],
    },
    {
      header: "Contact Us",
      info: "Your data serves multiple purposes, from facilitating transactions and improving our services to communication and legal compliance.",
      text: [
        {
          subHeader: "Contact Information",
          subText:
            "You can reach our customer support team at [email address] or by using the contact form on our website. We are available to assist you with any questions or concerns you may have.",
        },
        {
          subHeader: "Mailing Address",
          subText:
            "Our physical mailing address is [mailing address]. Please note that this address is for postal correspondence and not for in-person visits.",
        },
        {
          subHeader: "Feedback and Inquiries",
          subText:
            "We welcome your feedback and inquiries. Whether you have suggestions for improvement or need assistance with our services, don't hesitate to get in touch with us.",
        },
        {
          subHeader: "Response Time",
          subText:
            "We strive to respond to customer inquiries and support requests promptly. Please allow up to [response time] for a response from our team, depending on the nature of your inquiry.",
        },
        {
          subHeader: "Emergency Contact",
          subText:
            "In case of emergencies related to your account or security concerns, please contact our emergency hotline at [emergency phone number]. This hotline is available 24/7.",
        },
      ],
    },
  ];

  return (
    <>
      <Section className="">
        <div>
          <Text type="H1" className="" text="Privacy policy" />
        </div>
        <div className={`row ${classes.mt}`}>
          <div className="col-12 col-md-4">
            <div style={{ backgroundColor: "#f1eaff", padding: 10 }}>
              <ol>
                {data.map((item) => (
                  <li key={item.header} className="" aria-current="true">
                    <a
                      href={`#${item.header}`}
                      style={{ textDecoration: "none", color: "#160546" }}
                    >
                      <Text type="H5" className="" text={item.header} />
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <div className="col-12 col-md-8">
            {data.map((item) => (
              <>
                <div id={item.header} key={item.header} className="mb-5">
                  <Text type="H2" className="" text={item.header} />
                  <Text type="PARAGRAPH" text={item.info} />
                  <ol>
                    {item.text.map((text) => (
                      <>
                        <li key={item.header}>
                          <Text type="H6" text={text.subHeader} />
                        </li>
                        <Text type="PARAGRAPH" text={text.subText} />
                      </>
                    ))}
                  </ol>
                </div>
              </>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
};

export default PrivacyPolicyView;
