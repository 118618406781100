import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// components
import Button from "../../../components/button";
import CardTable from "../../../components/cardTable";
import CopyToClipboardCard from "../../../components/copyToClipboardCard";
import Flex from "../../../components/flex";
import Input from "../../../components/input";
import SelectStyle from "../../../components/input/selectStyle";
import Pagination from "../../../components/pagination";
import Section from "../../../components/section";
import ExportIcon from "../../../components/svg/exportIcon";
import Text from "../../../components/text";
// redux
import { getTransactionListFetch } from "../../../redux/user/reducers";
// hooks
import useHome from '../../home/hooks/home.hook';
// utils
import { truncate } from '../../../utils/util';
import LoadingBitcoinFlip from "../../../components/loading/bitcoinFlipLoading";

const currencyOption = [
  {
    value: "ALL",
    label: "ALL",
  },
  {
    value: "BUY",
    label: "BUY",
  },
  {
    value: "SELL",
    label: "SELL",
  },
];

const HistoryView = () => {
  const [searchValue, setSearchValue] = React.useState("");
  const { selectedTransactionOption, handleTransactionOption } = useHome();
  const [transactionType, setTransactionType] = useState();
  const moment = require("moment");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const onChangeHandler = (event) => {
    setSearchValue(event.target.value);
  };
  const { transactionDetails, isLoading } = useSelector((state) => state.userReducer);
  const [hideOnBuy, setHideOnBuy] = useState(false);
  const [hideOnSell, setHideOnSell] = useState(false);


  const dateRender = (params) => {
    return <>{moment(params?.data?.createdAt).format("DD/MM/YYYY h:mm")}</>;
  };

  const dateTooltipRenderer = (params) => {
    return moment(params?.data?.createdAt).format("DD/MM/YYYY h:mm");
  };

  const amountRender = (params) => {
    return (
      <>
        {params?.data?.transferType === 'SELL'
          ? params?.data?.cryptoAmount + ' ' + params?.data?.receivedToken
          : truncate(params?.data?.amount) + ' ' + params?.data?.receivedToken}
      </>
    );
  };

  const renderFee = (params) => {
    return <>{params?.data?.internalTransfer ? 0 : truncate(params?.data?.amount * (params?.data?.depositPercent / 100))}</>; //0.2% of amount
  };

  const renderGST = (params) => {
    return <>{params?.data?.internalTransfer ? 0 : truncate(params?.data?.amount * (params?.data?.depositPercent / 100) * (18 / 100))}</>; //18% of FEE amount is GST
  };

  const renderTDS = (params) => {
    return (
      <>
        {params?.data?.transferType === 'SELL' && !params?.data?.internalTransfer
          ? truncate(
            ((params?.data?.amount) - //amount
              (params?.data?.amount * (params?.data?.depositPercent / 100)) -  //fee
              (params?.data?.amount * (params?.data?.depositPercent / 100) * (18 / 100))) //gst

            * (params?.data?.tds / 100))
          : '--'}
      </>
    );

    //1% of amount is TDS
  };

  const renderAddress = (params) => {
    return (
      <span className="card-horizontal-item">
        {params?.data?.transferType === "BUY" ? (
          <>
            <CopyToClipboardCard title={params?.data?.walletAddress} />
            {params?.data?.walletAddress}
          </>
        ) : params?.data?.transferType === "SELL" &&
          params?.data?.bank?.accountNumber ? (
          <>
            <CopyToClipboardCard title={params?.data?.bank?.accountNumber} />
            {params?.data?.bank?.accountNumber}
          </>
        ) : (
          <span>-</span>
        )}
      </span>
    );
  };

  const renderStatus = (params) => {
    return (
      <>
        {params?.data?.withdraw &&
          params?.data?.withdraw.status !== null &&
          params?.data?.withdraw.status !== undefined &&
          params?.data?.transferType === "SELL"
          ? params?.data?.withdraw.status
          : params?.data.status}
      </>
    );
  };

  const inrRender = (params) => {
    return (
      <>
        {params?.data?.transferType === 'SELL'
          ? truncate(params?.data?.amount)
          : '--'}
      </>
    );
  };

  const networkFeeRender = (params) => {
    return (
      <>
        {params?.data?.transferType === 'BUY' && !params?.data?.internalTransfer
          ? `${truncate(params?.data?.networkFee * params?.data?.coinPrice)}
           (${params?.data?.networkFee} ${params?.data?.currencyInfo?.currencyName
          })`
          : "0"}
      </>
    );
  };

  const amountReceivedRender = (params) => {
    const fee = params?.data?.internalTransfer ? 0 : params?.data?.amount * (params?.data?.depositPercent / 100);
    const gst = params?.data?.internalTransfer ? 0 : params?.data?.amount * (params?.data?.depositPercent / 100) * (18 / 100);
    const tds = params?.data?.internalTransfer ? 0 : (params?.data?.amount) * (params?.data?.tds / 100);
    const networkFee = params?.data?.internalTransfer ? 0 : params?.data?.networkFee * params?.data?.coinPrice;
    return (
      <>
        {

          `${params?.data?.transferType === 'SELL'
            ? ((params?.data?.amount) - tds)
            : (params?.data?.amount - fee - gst - networkFee) / params?.data?.coinPrice
          } ${params?.data?.cryptoName}`}
      </>
    );
  };

  const transactionHashRender = (params) => {
    return (
      <span className="card-horizontal-item">
        {params?.data?.transactions &&
          params?.data?.transactions.txnHash !== null &&
          params?.data?.transactions.txnHash !== undefined &&
          params?.data?.transferType === "BUY" ? (
          <>
            <CopyToClipboardCard title={params?.data?.transactions.txnHash} />
            {params?.data?.transactions.txnHash}
          </>
        ) : (
          <>
            <CopyToClipboardCard title={params?.data?.transactionHash} />
            {params?.data?.transactionHash}
          </>
        )}
      </span>
    );
  };

  const renderNetAmount = (params) => {
    const { transferType } = params?.data;
    if (transferType === "BUY") {
      let netAmount =
        params?.data?.amount - //amount
        params?.data?.amount * (params?.data?.depositPercent / 100) - //fee
        params?.data?.amount * (params?.data?.depositPercent / 100) * (18 / 100); //GST

      netAmount -= params?.data?.networkFee * params?.data?.coinPrice; // network FEE for buy

      return <>{truncate(netAmount)}</>;
    } else {
      return <>--</>;
    }
  };

  const exportNetAmount = (params) => {
    if (params.transferType === "BUY") {
      let netAmount =
        params.amount - //amount
        params.amount * (params?.depositPercent / 100) - //fee
        params.amount * (params?.depositPercent / 100) * (18 / 100); //GST

      netAmount -= params.networkFee * params.coinPrice;

      return truncate(netAmount);
    }
    return "--";
  };

  const exportAmountReceived = (params) => {
    return params.transferType === 'SELL'
      ? truncate(
        truncate(params.amount) -
        params.amount * (params?.depositPercent / 100) - // fee
        params.amount * (params?.depositPercent / 100) * (18 / 100) - // GST
        params.amount * (params?.tds / 100) // TDS
      )
      : truncate(
        (params.amount -
          (params.amount * (params?.depositPercent / 100) + //fee
            params.amount * (params?.depositPercent / 100) * (18 / 100) + //GST
            params.networkFee * params.coinPrice)) / // network fee
        params.coinPrice
      );
  };

  const exportAmount = (params) => {
    return params?.transferType === 'SELL'
      ? params?.cryptoAmount + ' ' + params?.receivedToken
      : truncate(params?.amount) + ' ' + params?.receivedToken;
  };

  const exportNetwork = (params) => {
    return params.transferType === 'BUY'
      ? `${truncate(params.networkFee * params.coinPrice)}`
      : '--';
  };

  const cryptoBlockchainRender = (params) => {
    return (
      <>
        {params?.data?.currencyInfo?.currencyName +
          " on " +
          params?.data?.currencyInfo?.tokenType}
      </>
    );
  };

  const filteredData = () => {
    if (searchValue) {
      const searchVal = searchValue?.toUpperCase();
      return transactionDetails?.data.filter(
        (transaction) =>
          transaction?.transactionHash?.toUpperCase()?.includes(searchVal) ||
          transaction?.walletAddress?.toUpperCase()?.includes(searchVal) ||
          transaction?.currencyInfo?.currencyName
            ?.toUpperCase()
            ?.includes(searchVal) ||
          transaction?.currencyInfo?.tokenType
            ?.toUpperCase()
            ?.includes(searchVal)
      );
    } else {
      return transactionDetails?.data;
    }
  };

  const columnDefs = [
    {
      headerName: "Date and Time",
      cellRenderer: dateRender,
      tooltipValueGetter: dateTooltipRenderer,
      width: 162,
    },
    {
      headerName: "Transaction Hash",
      cellRenderer: transactionHashRender,
      width: 150,
    },
    {
      headerName: "Crypto/Blockchain",
      cellRenderer: cryptoBlockchainRender,
      width: 170,
    },
    { headerName: "Price", field: "coinPrice", width: 110 },
    { headerName: "Amount", cellRenderer: amountRender, width: 200 },
    {
      headerName: "INR Amount",
      cellRenderer: inrRender,
      width: 170,
      hide: hideOnBuy,
    },
    { headerName: "Fee", cellRenderer: renderFee, width: 110 },
    { headerName: "GST", cellRenderer: renderGST, width: 100 },

    {
      headerName: "Network fee",
      cellRenderer: networkFeeRender,
      width: 180,
      hide: hideOnSell,
    },
    { headerName: "TDS", cellRenderer: renderTDS, width: 100, hide: hideOnBuy },
    {
      headerName: "Net amount",
      cellRenderer: renderNetAmount,
      width: 100,
      hide: hideOnSell,
    },
    {
      headerName: "Amount/Crypto received",
      cellRenderer: amountReceivedRender,
      width: 150,
    },
    { headerName: "Bank Acc/Address", cellRenderer: renderAddress, width: 150 },

    { headerName: "Type", field: "transferType", width: 100 },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: renderStatus,
      cellClass: cellClass,
      width: 97,
    },
  ];

  function cellClass(params) {
    return params.value === "Approved"
      ? "bg-status-approved"
      : params.value === "Rejected"
        ? "bg-status-rejected"
        : params.value === "Pending"
          ? "bg-status-pending"
          : "";
  }
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const paginationPageSize = 10;

  const getTransactions = (limit, offset, status) => {
    const body = {
      offset,
      limit,
      status: "ALL",
      transferType: transactionType?.value || "ALL",
    };
    dispatch(getTransactionListFetch({ body }));
  };

  useEffect(() => {
    getTransactions(paginationPageSize, (currentPage - 1) * paginationPageSize);
  }, [transactionType]);

  const exportData = () => {
    let csvData = [];
    csvData.push([
      "S no",
      "Date and Time",
      "Transaction Hash",
      "Crypto/Blockchain",
      "Price",
      "Amount",
      "INR Amount",
      "Fee",
      "GST",
      "Network fee",
      "TDS",
      "Net amount",
      "Amount/Crypto received",
      "Bank Acc/Address",
      "Type",
      "Status",
    ]);
    transactionDetails?.data.forEach((element, i) => {
      csvData.push([
        i + 1,
        moment(element.createdAt).format("DD/MM/YYYY h:mm"),
        element.transactionHash,
        element.currencyInfo.currencyName +
        " on " +
        element.currencyInfo.tokenType,
        element.coinPrice,
        exportAmount(element),
        element.transferType === 'SELL' ? truncate(element.amount) : '--',
        truncate(element.amount * (element?.depositPercent / 100)),
        truncate(element.amount * (element?.depositPercent / 100) * (18 / 100)),
        exportNetwork(element),
        element.transferType === 'SELL'
          ? truncate(element.amount * (1 / 100))
          : '--',
        exportNetAmount(element),
        `${exportAmountReceived(element)} ${element.cryptoName}`,
        element.walletAddress,
        element.transferType,
        element.status,
      ]);
    });
    let fileName = "";
    csvData.forEach((row) => {
      fileName += row.join(",");
      fileName += "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(fileName);
    hiddenElement.target = "_blank";
    hiddenElement.download = "History.csv";
    hiddenElement.click();
  };

  const onTransactionTypeChange = (selectedOption) => {
    setTransactionType(selectedOption);
    handleTransactionOption(selectedOption);
    if (selectedOption.value === "BUY") {
      setHideOnBuy(true);
      setHideOnSell(false);
    }
    if (selectedOption.value === "SELL") {
      setHideOnSell(true);
      setHideOnBuy(false);
    }
    if (selectedOption.value === "ALL") {
      setHideOnSell(false);
      setHideOnBuy(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingBitcoinFlip />
      ) : (
        <Section className="pt-0">
          <Flex justifyContent="between" className="filter-list-wrp mb-20">
            <Flex className="filter-list-left">
              <Text type="H1" text="History" className="page-title" />
              <div className="filter-show-dropdown">
                <Text type="LABEL" text="Show:" />
                {/* <DropdownCheckbox text="All" list={["Buy", "Sell"]} /> */}
                <SelectStyle
                  mb0
                  secondary
                  className={"dropdown-menu"}
                  isSearchable={true}
                  value={selectedTransactionOption[0]}
                  defaultValue={currencyOption[0]}
                  onChange={(selectedOption) => {
                    onTransactionTypeChange(selectedOption);
                  }}
                  options={currencyOption}
                />
              </div>
            </Flex>
            <Flex className="filter-list-right">
              <div className="search-list-wrap">
                <Input
                  mb0
                  noLabel
                  type="search"
                  name="search"
                  placeholder="Search for a transaction"
                  value={searchValue}
                  onChange={onChangeHandler}
                />
              </div>
              <Button
                iconLeft={<ExportIcon />}
                small
                text="Export"
                onClick={() => {
                  exportData();
                }}
              />
            </Flex>
          </Flex>
          <CardTable>
            <div className="ag-theme-alpine">
              <AgGridReact
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                rowData={filteredData() || []}
                columnDefs={columnDefs}
                pagination={false}
                suppressPaginationPanel={true}
                paginationPageSize={paginationPageSize}
                noRowsOverlayComponent={() => (
                  <Text
                    type="H5"
                    text="No Transactions to show"
                    className="text-white"
                  />
                )}
                type={"H6"}
              ></AgGridReact>
              <Pagination
                className=""
                currentPage={currentPage}
                onPageChange={(data) => {
                  setCurrentPage(data);
                  getTransactions(
                    paginationPageSize,
                    (data - 1) * paginationPageSize
                  );
                }}
                pageSize={paginationPageSize}
                totalCount={transactionDetails?.count || 0}
              />
            </div>
          </CardTable>
        </Section>)}
    </>
  );
};

export default HistoryView;
