import React from 'react'
import Image from '../image'
import Text from '../text';

const MissionBreakBarrierCard = (props) => {
    const { icon, text, description } = props;
    return (
        <div className="mbb-card">
            <div className="mbb-img-icon">
                <Image src={icon} alt={text} />
            </div>
            <Text type="H4" className="mbb-text" text={text} />
            <Text type="PARAGRAPH" className="mbb-desc" text={description} />
        </div>
    )
}

export default MissionBreakBarrierCard
