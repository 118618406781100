
import React from 'react'

const VisaIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 52 30" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_316_4831)">
                <path d="M31.1667 -0.000488281H2.83333C1.26853 -0.000488281 0 1.24734 0 2.7866V19.5092C0 21.0484 1.26853 22.2963 2.83333 22.2963H31.1667C32.7315 22.2963 34 21.0484 34 19.5092V2.7866C34 1.24734 32.7315 -0.000488281 31.1667 -0.000488281Z" fill="#252525"/>
                <path d="M12.7498 18.1156C16.6619 18.1156 19.8332 14.9961 19.8332 11.1479C19.8332 7.29974 16.6619 4.18018 12.7498 4.18018C8.83782 4.18018 5.6665 7.29974 5.6665 11.1479C5.6665 14.9961 8.83782 18.1156 12.7498 18.1156Z" fill="#EB001B"/>
                <path d="M21.2498 18.1156C25.1619 18.1156 28.3332 14.9961 28.3332 11.1479C28.3332 7.29974 25.1619 4.18018 21.2498 4.18018C17.3378 4.18018 14.1665 7.29974 14.1665 11.1479C14.1665 14.9961 17.3378 18.1156 21.2498 18.1156Z" fill="#F79E1B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9998 5.57324C18.7203 6.84444 19.8332 8.86835 19.8332 11.1479C19.8332 13.4275 18.7203 15.4514 16.9998 16.7226C15.2794 15.4514 14.1665 13.4275 14.1665 11.1479C14.1665 8.86835 15.2794 6.84444 16.9998 5.57324Z" fill="#FF5F00"/>
            </g>
            <g clip-path="url(#clip1_316_4831)">
                <path d="M48.8084 8.05127H21.1834C19.6186 8.05127 18.3501 9.29909 18.3501 10.8384V26.8641C18.3501 28.4034 19.6186 29.6512 21.1834 29.6512H48.8084C50.3732 29.6512 51.6418 28.4034 51.6418 26.8641V10.8384C51.6418 9.29909 50.3732 8.05127 48.8084 8.05127Z" fill="white" stroke="black" stroke-opacity="0.2" strokeWidth="0.708333"/>
                <path d="M21.9452 15.9447C21.2041 15.5449 20.3583 15.2233 19.4126 15.0001L19.4523 14.8263H23.3297C23.8552 14.8444 24.2817 15 24.4204 15.5493L25.2631 19.5013L25.5212 20.6915L27.8814 14.8263H30.4297L26.6417 23.4184H24.0932L21.9452 15.9447ZM32.3042 23.4276H29.8943L31.4016 14.8263H33.8115L32.3042 23.4276ZM41.0405 15.0366L40.7128 16.8939L40.4948 16.8026C40.0587 16.6287 39.4831 16.4549 38.6996 16.4733C37.7481 16.4733 37.3214 16.8482 37.3115 17.2143C37.3115 17.6169 37.8277 17.8823 38.6706 18.2759C40.0591 18.8889 40.7032 19.6392 40.6935 20.6183C40.674 22.4027 39.0278 23.5557 36.4991 23.5557C35.4179 23.5465 34.3766 23.3356 33.8113 23.098L34.1486 21.1672L34.4659 21.3046C35.2494 21.6252 35.7649 21.7621 36.7271 21.7621C37.4209 21.7621 38.1649 21.4965 38.1743 20.9203C38.1743 20.5451 37.8574 20.2704 36.925 19.8495C36.0128 19.4376 34.7931 18.7514 34.8129 17.516C34.8232 15.8416 36.4991 14.6704 38.879 14.6704C39.8112 14.6704 40.5652 14.8626 41.0405 15.0366ZM44.2436 20.3804H46.2467C46.1476 19.9504 45.6912 17.8916 45.6912 17.8916L45.5228 17.1504C45.4038 17.4706 45.1957 17.9923 45.2057 17.9739C45.2057 17.9739 44.442 19.8863 44.2436 20.3804ZM47.2184 14.8263L49.1626 23.4274H46.9312C46.9312 23.4274 46.7128 22.4392 46.6437 22.1372H43.5495C43.46 22.3658 43.0437 23.4274 43.0437 23.4274H40.515L44.0947 15.54C44.3427 14.9817 44.7794 14.8263 45.3543 14.8263H47.2184Z" fill="#171E6C"/>
            </g>
            <defs>
                <clipPath id="clip0_316_4831">
                    <rect width="34" height="22.2967" fill="white" transform="translate(0 -0.000488281)"/>
                </clipPath>
                <clipPath id="clip1_316_4831">
                    <rect width="34" height="22.2967" fill="white" transform="translate(17.9961 7.70264)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default VisaIcon

VisaIcon.defaultProps = {
    width: '52',
    height: '30',
}