import React from 'react'

const SupportIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 34 34" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M31.1668 24.0871C31.1654 28.1542 28.3074 31.5535 24.4904 32.3879L23.5865 29.6764C25.2922 29.398 26.7419 28.3582 27.5754 26.9173H24.0835C22.5186 26.9173 21.2502 25.6488 21.2502 24.084V18.4173C21.2502 16.8525 22.5186 15.584 24.0835 15.584H28.2458C27.5487 9.99338 22.7796 5.66732 17.0002 5.66732C11.2207 5.66732 6.45165 9.99338 5.75451 15.584H9.91683C11.4816 15.584 12.7502 16.8525 12.7502 18.4173V24.084C12.7502 25.6488 11.4816 26.9173 9.91683 26.9173H5.66683C4.10202 26.9173 2.8335 25.6488 2.8335 24.084V17.0007C2.8335 9.17661 9.17613 2.83398 17.0002 2.83398C24.8241 2.83398 31.1668 9.17661 31.1668 17.0007V18.4155V18.4173V24.084V24.0871Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default SupportIcon

SupportIcon.defaultProps = {
    width: '34',
    height: '34'
}