import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SellCryptoView from './views/SellCryptoView';

const SellCrypto = (props) => {
  useEffect(() => {
    document.body.classList.add('sellcrypto-page');
    return () => {
      document.body.classList.remove('sellcrypto-page');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Sell Crypto for INR- Easy and Reliable Transactions - PayOnRamp</title>
        <meta
          name="description"
          content="Sell Crypto for INR: Dependable and Convenient Transactions with PayOnRamp. Explore a hassle-free way to convert your cryptocurrencies into INR with our reliable services."
        />
        <link rel="canonical" href="https://payonramp.com" />
      </Helmet>
      <SellCryptoView />
    </>
  );
};

export default SellCrypto;
