import React from "react";
import { Link } from "react-router-dom";

const NavList = ({ lists }) => {
    return(
        <ul className="nav-list-wrapper">
            {lists.map((item, id) => (
                <li key={id} className="nav-list-item">
                    <Link 
                        className="nav-list-item-link" 
                        to={item.url}
                    >
                        {item.text}
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default NavList;