import React from 'react'

const ShakeFillIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 34 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.20159 15.5535H1.01709C0.455368 15.5535 0 15.0981 0 14.5364V1.91565C0 1.35393 0.455368 0.898558 1.01709 0.898558H3.20159C3.76332 0.898558 4.21869 1.35393 4.21869 1.91565V14.5364C4.21869 15.0981 3.76332 15.5535 3.20159 15.5535ZM30.7984 15.5337H32.9829C33.5446 15.5337 34 15.0784 34 14.5166V1.89589C34 1.33417 33.5446 0.878798 32.9829 0.878798H30.7984C30.2367 0.878798 29.7813 1.33417 29.7813 1.89589V14.5166C29.7813 15.0784 30.2367 15.5337 30.7984 15.5337ZM22.6509 2.63953L21.078 1.24764C19.7803 -0.260638 17.4161 -0.425989 15.9201 0.88599L11.182 5.04104C10.3749 5.74872 10.2995 7.03687 11.0235 7.83318C11.7352 8.6159 12.9661 8.69044 13.7691 7.99802L17.8971 4.39823C18.0214 4.28957 18.1828 4.23308 18.3477 4.24054C18.5126 4.248 18.6683 4.31883 18.7822 4.43826L27.6586 13.7036H28.2132V2.63953H22.6509Z" fill="currentColor"/>
            <path d="M24.445 13.1442L17.8669 6.25608L14.7546 9.08432C13.3762 10.3446 11.2366 10.2471 9.97857 8.86652C8.72354 7.48923 8.82089 5.35573 10.1961 4.09846L13.5742 1.0099C11.6791 0.0199819 9.27917 0.58999 8.0596 2.43682H5.86035V14.1917H7.78934L10.9157 17.3877C11.1662 17.6654 11.503 17.8507 11.8717 17.9136C12.2404 17.9765 12.6195 17.9133 12.948 17.7343C13.8466 17.2446 14.0841 16.0796 13.4868 15.2801L15.1179 17.1029C15.3402 17.3513 15.6349 17.5236 15.9604 17.5954C16.2858 17.6672 16.6256 17.635 16.9318 17.5032L16.9721 17.4858C18.0107 17.0388 18.3083 15.7143 17.5639 14.871C16.4795 13.6424 15.4819 12.471 15.4819 12.471L18.6151 16.0039C18.8647 16.2828 19.2 16.4706 19.5681 16.5379C19.9362 16.6051 20.3162 16.5479 20.6482 16.3753L20.6892 16.354C21.7163 15.8199 21.8096 14.3916 21.0307 13.5444C19.5355 11.9185 17.9139 10.0389 17.9139 10.0389C17.9139 10.0389 20.7737 13.1093 22.268 14.7048C22.4965 14.9488 22.7936 15.1178 23.1202 15.1896C23.4467 15.2614 23.7873 15.2326 24.0971 15.1069L24.1403 15.0894C24.9869 14.5623 24.8124 13.729 24.445 13.1442Z" fill="currentColor"/>
        </svg>
    )
}

export default ShakeFillIcon

ShakeFillIcon.defaultProps = {
    width: '34',
    height: '34'
}
