import React, { useEffect, useState } from 'react';

import Section from '../../components/section';
import Flex from '../../components/flex';
import Button from '../../components/button';
import File from '../../components/input/file';
import Input from '../../components/input';
import Modal from '../../components/modal';

import KYCSuccess from "../home/components/buy/buyKYC/kycSuccess";

// validations
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import { submitKyc } from '../../redux/user/reducers';
import { differenceInYears } from 'date-fns';
import Select from '../../components/input/select';


const ManualKyc = () => {
  useEffect(() => {
    document.body.classList.add('kyc-manual');
    return () => {
      document.body.classList.remove('kyc-manual');
    };
  }, []);

  const dispatch = useDispatch();

  const { applicationId, phoneNumber } = useSelector((state) => state?.userReducer);

  const [isKycModal, setKycModal] = useState(false);

  const callback = () => {
    setKycModal(true);
  };

  const genderOptions = [
    { value: '', label: 'Select your Gender', disabled: true },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'others', label: 'Others' },
  ];

  const formik = useFormik({
    initialValues: {
      name: '',
      gender: '',
      pan: '',
      aadhar: '',
      email: '',
      address: '',
      dob: '',
      state: '',
      aadharFrontImage: [],
      aadharBackImage: [],
      panImage: [],
      userPhoto: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter a your Name'),
      gender: Yup.string().required('Please enter a Gender'),
      dob: Yup.date()
      .nullable()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .test(
        'is-valid-age',
        'You must be at least 18 years old',
        value => !value || differenceInYears(new Date(), value) >= 18
      ),
      pan: Yup.string()
        .trim()
        .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid Pan Number')
        .required('Please enter a Pan Number'),
      aadhar: Yup.string().required('Please enter a Aadhar Number').test('len', 'Invalid Aadhar Number', val => val.length === 12),
      email: Yup.string().email('Invalid email').required('Please enter an Email'),
      address: Yup.string().required('Please enter a Address'),
      state: Yup.string().optional()
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      const formattedDob = values.dob ? new Date(values.dob).toISOString().split('T')[0] : '';

      formData.append("applicationId", applicationId);
      formData.append("phoneNumber", phoneNumber);
      formData.append("fullname", values.name);
      formData.append("gender", values.gender);
      formData.append("DOB", formattedDob);
      formData.append("pan", values.pan);
      formData.append("aadhar", values.aadhar);
      formData.append("email", values.email);
      formData.append("address", values.address);
      formData.append("state", values.state);
      formData.append("aadharFrontImage", values.aadharFrontImage);
      formData.append("aadharBackImage", values.aadharBackImage);
      formData.append("userPhoto", values.userPhoto);
      formData.append("panImage", values.panImage);

      dispatch(
        submitKyc({
          formData,
          callback,
        })
      );
    },
  });

  const handleFileChange = (event, field) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue(field, file);
  };

  const close = () => {
    setKycModal(false);
    window.parent.postMessage({ status: "SUCCESS" }, "*");
  }

  return (
    <>
      <Section className="section-hero section-new-hero">
        <div className="hero-form-wrapper">
          <div className="hero-form-main">
            <form className="manual-kyc-form" onSubmit={formik.handleSubmit}>
              <p className="font-weight-bold">Payonramp - KYC Form</p>
              <div className="form-box">
                <Input
                  label="Your Full Name"
                  type="text"
                  name="name"
                  placeholder="Enter your Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errormessage={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null
                  }
                />
              </div>
              <div className="form-box">
                <Select
                  label="Gender"
                  placeholder="Select your Gender"
                  name="gender"
                  value={formik.values.gender}
                  options={genderOptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  errormessage={formik.touched.gender && formik.errors.gender
                    ? formik.errors.gender
                    : null}
                />
              </div>
              <div className="form-box">
                <Input
                  label="Date of Birth"
                  type="date"
                  name="dob"
                  placeholder="Enter your DOB"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errormessage={
                    formik.touched.dob && formik.errors.dob
                      ? formik.errors.dob
                      : null
                  }
                />
              </div>
              <div className="form-box">
                <Input
                  label="Your Pan Number"
                  type="text"
                  name="pan"
                  placeholder="Enter your Pan Number"
                  value={formik.values.pan}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errormessage={
                    formik.touched.pan && formik.errors.pan
                      ? formik.errors.pan
                      : null
                  }
                />
              </div>
              <div className="form-box">
                <Input
                  label="Your Aadhar Number"
                  type="text"
                  name="aadhar"
                  placeholder="Enter your Aadhar Number"
                  value={formik.values.aadhar}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errormessage={
                    formik.touched.aadhar && formik.errors.aadhar
                      ? formik.errors.aadhar
                      : null
                  }
                />
              </div>
              <div className="form-box">
                <Input
                  label="Your Email id"
                  type="text"
                  name="email"
                  placeholder="Enter your Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errormessage={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                />
              </div>
              <div className="form-box">
                <Input
                  label="Your Address"
                  type="text"
                  name="address"
                  placeholder="Enter your Address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errormessage={
                    formik.touched.address && formik.errors.address
                      ? formik.errors.address
                      : null
                  }
                />
              </div>
              <div className="form-box">
                <Input
                  label="State"
                  type="text"
                  name="state"
                  placeholder="Enter your State from India"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errormessage={
                    formik.touched.state && formik.errors.state
                      ? formik.errors.state
                      : null
                  }
                />
              </div>
              <div className="form-box">
                <File
                  label="Upload Aadhar card Front"
                  type="file"
                  name="addAttachment"
                  selectedFile={formik.values.aadharFrontImage?.name}
                  value=""
                  onChange={(event) => {
                    handleFileChange(event, 'aadharFrontImage')
                  }}
                  onBlur={formik.handleBlur}
                  mandatory
                  className="file-add-attachment"
                />
              </div>
              <div className="form-box">
                <File
                  label="Upload Aadhar card Back"
                  type="file"
                  name="addAttachment"
                  placeholder=""
                  value=""
                  selectedFile={formik.values.aadharBackImage?.name}
                  onChange={(event) => {
                    handleFileChange(event, 'aadharBackImage')
                  }}
                  onBlur={formik.handleBlur}
                  mandatory
                  className="file-add-attachment"
                />
              </div>
              <div className="form-box">
                <File
                  label="Upload Pan Card Front"
                  type="file"
                  name="addAttachment"
                  placeholder=""
                  value=""
                  selectedFile={formik.values.panImage?.name}
                  onChange={(event) => {
                    handleFileChange(event, 'panImage')
                  }}
                  onBlur={formik.handleBlur}
                  mandatory
                  className="file-add-attachment"
                />
              </div>
              <div className="form-box">
                <File
                  label="Upload a Photo with date"
                  type="file"
                  name="addAttachment"
                  placeholder=""
                  value=""
                  selectedFile={formik.values.userPhoto?.name}
                  onChange={(event) => {
                    handleFileChange(event, 'userPhoto')
                  }}
                  onBlur={formik.handleBlur}
                  mandatory
                  className="file-add-attachment"
                />
              </div>
              <Flex flexColumn >
                <Button type="submit" text="Submit Kyc" />
              </Flex>
            </form>
          </div>
        </div>
      </Section>
      {isKycModal && (
        <Modal
          open={isKycModal}
          smallMedium minHeight onClose={() => close()}>
          <KYCSuccess onClick={() => close()} />
        </Modal>
      )}
    </>
  )
}

export default ManualKyc;