import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import Section from "../../components/section";

const ErrorPage = ({ notFound }) => {
    window.history.replaceState(null, null, '/');
    const navigate = useNavigate();

    const handleGoHome = () => {
        console.log("Navigating to home");
        navigate('/', { replace: true });
    };

    return (
        <div className="vertically-centered h-screen">
            <div className="vertically-centered mx-auto">
                <h2 className="w-fit mx-auto">{notFound ? "Page Not Found" : "Something went wrong"}</h2>
                <Button
                    danger
                    onClick={handleGoHome}
                    className="w-fit mx-auto btn btn-secondary"
                    text="Go Home"
                />
            </div>
        </div>
    );
};

export default ErrorPage;