import React from 'react'

const GetAccessIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 46 46" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M22.7105 45.7739L22.7092 45.7735C16.2312 43.9003 10.5347 39.978 6.47372 34.5945C2.41276 29.2111 0.205935 22.6565 0.184 15.9132V13.6757C0.184 13.3947 0.295599 13.1253 0.494245 12.9267C0.692892 12.728 0.962314 12.6164 1.24324 12.6164C4.2596 12.6164 7.15241 11.4182 9.2853 9.2853C11.4182 7.15241 12.6164 4.2596 12.6164 1.24324C12.6164 0.962314 12.728 0.692892 12.9267 0.494245C13.1253 0.295599 13.3947 0.184 13.6757 0.184H32.3243C32.6052 0.184 32.8747 0.295599 33.0733 0.494245C33.272 0.692892 33.3836 0.962314 33.3836 1.24324C33.3836 4.2596 34.5818 7.15241 36.7147 9.2853C38.8476 11.4182 41.7404 12.6164 44.7567 12.6164C45.0377 12.6164 45.3071 12.728 45.5057 12.9267C45.7044 13.1253 45.816 13.3947 45.816 13.6757V15.9129C45.7941 22.6563 43.5873 29.211 39.5263 34.5945C35.4652 39.978 29.7688 43.9003 23.2908 45.7735L23.2894 45.7739C23.1006 45.83 22.8994 45.83 22.7105 45.7739ZM43.6975 15.9141V15.9135V14.863V14.695L43.5302 14.6797C40.3886 14.3931 37.4468 13.0146 35.2161 10.7839C32.9854 8.55323 31.6069 5.61139 31.3203 2.46977L31.305 2.30249H31.137H14.863H14.695L14.6797 2.46977C14.3931 5.61139 13.0146 8.55323 10.7839 10.7839C8.55323 13.0146 5.61139 14.3931 2.46977 14.6797L2.30249 14.695V14.863L2.30248 15.9135L2.30249 15.9141C2.32324 22.1364 4.33993 28.1879 8.05601 33.1787C11.7721 38.1695 16.9915 41.8363 22.9467 43.6399L23 43.656L23.0533 43.6399C29.0085 41.8363 34.2279 38.1695 37.944 33.1787C41.6601 28.1879 43.6767 22.1364 43.6975 15.9141Z" fill="#5A3CAE" stroke="#DDCAFF" strokeWidth="0.368"/>
            <path d="M27.1668 18.7048V18.8603L27.3202 18.8862C28.1458 19.0258 28.8954 19.453 29.4362 20.0922C29.977 20.7314 30.2741 21.5414 30.275 22.3787V29.838C30.275 30.7784 29.9014 31.6803 29.2364 32.3452C28.5715 33.0102 27.6696 33.3837 26.7292 33.3837H19.2698C18.3294 33.3837 17.4275 33.0102 16.7626 32.3452C16.0976 31.6803 15.724 30.7784 15.724 29.838V22.3787C15.7249 21.5414 16.022 20.7314 16.5628 20.0922C17.1036 19.453 17.8532 19.0258 18.6788 18.8862L18.8321 18.8603V18.7048V16.784C18.8321 15.6787 19.2712 14.6187 20.0527 13.8372C20.8343 13.0557 21.8942 12.6166 22.9995 12.6166C24.1047 12.6166 25.1647 13.0557 25.9463 13.8372C26.7278 14.6187 27.1668 15.6787 27.1668 16.784V18.7048ZM24.8644 18.8328H25.0484V18.6488V16.784C25.0484 16.2406 24.8325 15.7194 24.4483 15.3352C24.064 14.951 23.5429 14.7351 22.9995 14.7351C22.4561 14.7351 21.935 14.951 21.5507 15.3352C21.1665 15.7194 20.9506 16.2406 20.9506 16.784V18.6488V18.8328H21.1346H24.8644ZM27.7384 30.8472C28.0061 30.5796 28.1565 30.2165 28.1565 29.838V22.3786C28.1565 22 28.0061 21.637 27.7384 21.3693C27.4708 21.1017 27.1078 20.9513 26.7292 20.9513H19.2698C18.8912 20.9513 18.5282 21.1017 18.2606 21.3693C17.9929 21.637 17.8425 22 17.8425 22.3786V29.838C17.8425 30.2165 17.9929 30.5796 18.2606 30.8472C18.5282 31.1149 18.8912 31.2653 19.2698 31.2653H26.7292C27.1078 31.2653 27.4708 31.1149 27.7384 30.8472Z" fill="#5A3CAE" stroke="#DDCAFF" strokeWidth="0.368"/>
            <path d="M22.2517 26.5497V26.4449L22.1616 26.3915C21.8461 26.2043 21.6005 25.919 21.4622 25.5792C21.324 25.2394 21.3007 24.8637 21.3959 24.5095C21.4912 24.1552 21.6997 23.8418 21.9897 23.6171C22.2792 23.3928 22.634 23.2692 23.0001 23.2651C23.3663 23.2692 23.7211 23.3928 24.0106 23.6171C24.3006 23.8418 24.5091 24.1552 24.6043 24.5095C24.6996 24.8637 24.6763 25.2394 24.538 25.5792C24.3998 25.919 24.1542 26.2043 23.8387 26.3915L23.7486 26.4449V26.5497V28.2094C23.7486 28.4079 23.6697 28.5983 23.5294 28.7387C23.389 28.879 23.1986 28.9579 23.0001 28.9579C22.8016 28.9579 22.6113 28.879 22.4709 28.7387C22.3305 28.5983 22.2517 28.4079 22.2517 28.2094V26.5497Z" fill="#5A3CAE" stroke="#DDCAFF" strokeWidth="0.368"/>
        </svg>
    )
}

export default GetAccessIcon

GetAccessIcon.defaultProps = {
    width: '46',
    height: '46'
}