import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// components
import Flex from "../../../../../components/flex";
import Text from "../../../../../components/text";
import Button from "../../../../../components/button";
import Image from "../../../../../components/image";
import LabelText from "../../../../../components/labelText";
// redux
import { saveWalletAddress } from "../../../../../redux/deposit/persistReducer";
// images
import PurchasePendingImg from "../../../../../assets/img/purchase-pending-img.png";
// utils
import { truncate } from '../../../../../utils/util';
import { getProfileDetailsFetch } from "../../../../../redux/user/reducers";

const PurchasePending = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { walletAddress, buyNetworkFee, buyReceiveData } = useSelector(
    (state) => state?.depositPersistReducer
  );

  const { applicationId, walletInfo } = useSelector((state) => state?.userReducer);

  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);

  const calculateCrypto = () => {
    return truncate(
      (buyNetworkFee?.total) / buyNetworkFee.price
    );
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    // <Modal smallMedium minHeight onClose={handleClick}>
    <Flex flexColumn justifyContent="between" className="p-4 height-100">
      <Flex flexColumn>
        <Flex flexColumn alignItems="center" className="mb-20">
          <Image src={PurchasePendingImg} alt="Purchase complete" />
        </Flex>
        <Text type="H3" text="Purchase pending" className="text-center mb-10" />
        <Text
          type="PARAGRAPH"
          className="font-size-14 mb-30"
          text="The purchase is taking longer than expected. Please check the 'History' page for the status."
        />
        <LabelText
          lists={[
            {
              label: "Order ID",
              text: "#1394801351",
            },
            {
              label: "Total Sent",
              text: `${truncate(buyNetworkFee.total)} INR`,
            },
            {
              label: "Fee (incl. GST)",
              text: `${buyNetworkFee?.payonrampFee} INR`,
            },
            {
              label: "Network Fee",
              text: `${truncate(buyNetworkFee?.network)} INR`,
            },
            {
              label: buyReceiveData?.token ? `Received` : "Received",
              text: `${calculateCrypto()} ${buyReceiveData.token}`,
            },
            {
              label: "Wallet ID",
              text: `${walletInfo || walletAddress?.walletAddress}`,
            },
          ]}
        />
      </Flex>
      <Flex flexColumn>
        <Flex className="mb-10" flexColumn>
          {/* <Button type="button" text="Done" onClick={handleClick} /> */}
        </Flex>
        <Flex className="mb-10" flexColumn>
          {isLoggedIn === true && (
            <Button
              outline
              type="button"
              text="View Transaction History"
              onClick={() => {
                dispatch(getProfileDetailsFetch())
                dispatch(saveWalletAddress(""));
                if (applicationId) {
                  openInNewTab(`https://payonramp.com`);
                  return;
                }
                isLoggedIn ? navigate(`/history`) : navigate('/');
              }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
    // </Modal>
  );
};

export default PurchasePending;
