import React from "react";
import { useDispatch } from "react-redux";
// components
import Button from "../../../../../components/button";
// redux
import {
  kycKeysFetch,
  updateKycStatusFetch,
} from "../../../../../redux/user/reducers";
import { setLoading } from "../../../../../redux/withdrawal/reducer";

const SellKYC = (props) => {
  const { handleSubmit, handleClose } = props;
  const dispatch = useDispatch();
  
  const getKycKeys = () => {
    dispatch(
      kycKeysFetch({
        initiateKYCVerification,
        type: { kycType: "BASIC_KYC" },
      })
    );
    handleClose();
  };
  const callback = (res) => {
    dispatch(setLoading(true));
    if (res) {
      handleSubmit();
    }
  };
  const initiateKYCVerification = (kycKeys) => {
    try {
      const hyperKycConfig = new window.HyperKycConfig(
        kycKeys.accessToken,
        kycKeys.workflowId || "workflow_P7pSlLT",
        kycKeys.transactionId || Math.random().toString()
      );
      const handler = (HyperKycResult) => {
        // eslint-disable-next-line default-case
        switch (HyperKycResult.status) {
          case "user_cancelled": {
            break;
          }
          case "error": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "need_review", kycID: kycKeys.transactionId },
                callback,
              })
            );
            break;
          }
          case "auto_declined": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "need_review", kycID: kycKeys.transactionId },
                callback,
              })
            );
            break;
          }
          case "needs_review": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "need_review", kycID: kycKeys.transactionId },
                callback,
              })
            );
            break;
          }
          case "auto_approved": {
            dispatch(
              updateKycStatusFetch({
                values: { status: "need_review", kycID: kycKeys.transactionId },
                callback,
              })
            );
            break;
          }
        }
      };
      window.HyperKYCModule.launch(hyperKycConfig, handler);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="p-4 kyc-modal">
      <h3>Complete your KYC</h3>
      <p>
        Please ensure you have the following documents handy for a quick annd
        easy completion of your KYC requirements with us.
      </p>

      <div>
        <ul>
          <li>Pan Card</li>
          <li>Access to Digilocker/Aadhar Card</li>
        </ul>
      </div>

      <p className="kyc-modal--note">Note:</p>
      <p>
        Once you have your documents ready the initial step is Facial
        Verification. Please make sure you have a camera enabled device to make
        sure a hassle free KYC experience.
      </p>

      <Button
        className="kyc-modal--accept"
        onClick={getKycKeys}
        text="Start KYC"
      />
      {/* <Button
        className="kyc-modal--skip"
        onClick={handleClose} 
        text="Skip for now" 
      /> */}
    </div>
  );
};

export default SellKYC;
