import React from 'react'

const Card = (props) => {
    const {children, className} = props;
    return (
        <div className={`card-wrapper ${className ? className : '' }`}>
            <div className="card-main">
                {children}
            </div>
        </div>
    )
}

export default Card