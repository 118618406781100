import { all, fork } from "redux-saga/effects";
import userSaga from "./user/saga";
import depositSaga from "./deposit/saga";
import withdrawalSaga from "./withdrawal/saga";

function* rootSaga() {
  yield all([fork(userSaga)]);
  yield all([fork(depositSaga)]);
  yield all([fork(withdrawalSaga)])
}

export default rootSaga;
