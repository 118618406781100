import React from 'react'
import Text from '../text';

const LabelText = (props) => {
    const { lists } = props;
    return (
        <div className="label-text-lists">
            {lists.map((item, id) => (
                <div key={id} className="ltl-item">
                    <div className="ltl-item-left">
                        <Text type="LABEL" className="ltl-item-label" text={item.label} />
                        <Text type="SPAN" className="ltl-item-sym" text=":" />
                    </div>
                    <Text type="SPAN" className="ltl-item-text" text={item.text} />
                </div>
            ))}
        </div>
    )
}

export default LabelText