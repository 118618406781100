import React from 'react'

const CustomerSupportIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 34 38" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M29.6085 12.9989C29.6085 5.81979 23.7887 0 16.6097 0C9.43058 0 3.61079 5.81979 3.61079 12.9989C1.75485 13.23 0 14.4432 0 17.2596V24.0479C0 27.2254 2.22425 28.3808 4.33295 28.3808H6.42721C6.82603 28.3808 7.14937 28.0575 7.14937 27.6587V13.6777C7.14937 13.2789 6.82603 12.9555 6.42721 12.9555H5.05511C5.05511 6.57417 10.2283 1.40099 16.6097 1.40099C22.991 1.40099 28.1642 6.57417 28.1642 12.9555H26.7921C26.3933 12.9555 26.0699 13.2789 26.0699 13.6777V27.637C26.0699 28.0358 26.3933 28.3592 26.7921 28.3592H28.1642C27.962 33.3204 25.5789 34.4831 20.8198 34.6925V34.2087C20.8198 33.411 20.1732 32.7643 19.3755 32.7643H14.8331C14.0354 32.7643 13.3888 33.411 13.3888 34.2087V36.5557C13.3888 37.3534 14.0354 38 14.8331 38H19.39C20.1877 38 20.8343 37.3534 20.8343 36.5557V36.1368C25.4128 35.9274 29.3702 34.9814 29.6085 28.3158C31.4645 28.0848 33.2193 26.8715 33.2193 24.0551V17.2668C33.2193 14.436 31.4717 13.23 29.6085 12.9989ZM5.70505 14.3493V26.9149H4.29685C3.22805 26.9149 1.40821 26.5393 1.40821 24.0262V17.2379C1.40821 14.7465 3.20639 14.3493 4.29685 14.3493H5.70505ZM19.39 35.4652V36.5629H14.8331V34.2159H19.39V35.4652ZM31.775 24.0479C31.775 26.5393 29.9768 26.9365 28.8864 26.9365H27.5143V14.3998H28.9225C29.9913 14.3998 31.8111 14.7754 31.8111 17.2885L31.775 24.0479Z" 
                fill="#5A3CAE"
            />
        </svg>
    )
}

export default CustomerSupportIcon

CustomerSupportIcon.defaultProps = {
    width: '34',
    height: '38'
}