import React from 'react'
import Select from 'react-select'
import Text from '../text';

const SelectStyle = (props) => {
    const {
        secondary,
        mb0,
        label, 
        noLabel,
        type,
        errormessage
    } = props;
    return (
        <div className={`form-field ${secondary ? 'form-field-secondary' : ''} ${mb0 ? 'mb-0' : ''}`}>
            {!noLabel && <Text type="LABEL" text={label} className="form-label" />}
            <Select
                className="select-style-container"
                classNamePrefix="select-style"
                components={{
                    IndicatorSeparator: () => null
                }}
                {...props}
            />
            {errormessage && type != "checkbox" && (
                <div className="message-error">
                    <small>{errormessage}</small>
                </div>
            )}
        </div>
    )
}

export default SelectStyle