// import axios from "axios";
// import { PUBLIC_URL } from "./constants";
import { store } from "../redux/store";
import { 
  clearUserLoginDetails,
} from "../redux/user/persistReducer";

const onRequest = (config) => {
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response.status === 401) {
      store.dispatch(clearUserLoginDetails());
      return;
    }
    return error.response;
  });
  return axiosInstance;
};
