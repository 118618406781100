import React from 'react'

const FadeInOut = (props) => {
    const {show, children, className} = props;
    return (
        <div className={`${className ? className : ''} fadeinout ${show ? 'show' : ''}`}>
            {children}
        </div>
    )
}

export default FadeInOut