import React, { useState } from 'react';
import ArrowRightIcon from "../svg/arrowRightIcon";
import Text from '../text';

const Accordion = ({ accordionList }) => {
  const [openItem, setOpenItem] = useState(null);

  // Function to toggle an item's open/close state
  const toggleItem = (item) => {
    if (openItem === item.question) {
      // Clicked on an already open item, so close it
      setOpenItem(null);
    } else {
      // Clicked on a different item, open it and close the previous one
      setOpenItem(item.question);
    }
  };

  return (
    <div className="accordion-group">
      {accordionList.map((item, id) => (
        <div className="accordion-item" key={id}>
          <div className="accordion-heading" onClick={() => toggleItem(item)}>
            <Text
              type="H3"
              className="accordion-heading-title"
              text={item.question}
            />
            <div className="accordion-head-right">
              {openItem === item.question ? (
                <ArrowRightIcon up />
              ) : (
                <ArrowRightIcon down />
              )}
            </div>
          </div>
          {openItem === item.question && (
            <div className="accordion-content">{item.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
