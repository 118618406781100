import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// components
import Flex from "../../../../../components/flex";
import Text from "../../../../../components/text";
import Button from "../../../../../components/button";
import Image from "../../../../../components/image";
import LabelText from "../../../../../components/labelText";
// images
import PurchaseSuccessImg from "../../../../../assets/img/purchase-success-img.png";
// redux
import { saveWalletAddress } from "../../../../../redux/deposit/persistReducer";
// utils
import { truncate } from '../../../../../utils/util';
import { getProfileDetailsFetch } from "../../../../../redux/user/reducers";

const PurchaseComplete = (props) => {
  const { handleClick } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { walletAddress, buyNetworkFee, buyReceiveData } = useSelector(
    (state) => state?.depositPersistReducer
  );

  const { applicationId, walletInfo } = useSelector((state) => state?.userReducer);

  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);

  const calculateCrypto = () => {
    return truncate(
      (buyNetworkFee?.total) / buyNetworkFee.price
    );
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
      <Flex flexColumn justifyContent="between" className="p-4 height-100">
        <Flex flexColumn>
          <Text
            type="H3"
            text="Purchase complete"
            className="text-center mb-30"
          />
          <Flex flexColumn alignItems="center" className="mb-20">
            <Image src={PurchaseSuccessImg} alt="Purchase complete" />
          </Flex>
          <LabelText
            lists={[
              {
                label: "Order ID",
                text: "#1394801351",
              },
              {
                label: "Total Sent",
                text: `${buyNetworkFee.total} INR`,
              },
              {
                label: "Fee (incl. GST)",
                text: `${buyNetworkFee?.payonrampFee} INR`,
              },
              {
                label: "Network Fee",
                text: `${truncate(buyNetworkFee?.network)} INR`,
              },
              {
                label: buyReceiveData?.token ? `Received` : "Received",
                text: `${calculateCrypto()} ${buyReceiveData.token}`,
              },
              {
                label: "Wallet ID",
                text: `${walletInfo || walletAddress?.walletAddress}`,
              },
            ]}
          />
        </Flex>
        <Flex flexColumn>
          <Flex className="mb-10" flexColumn>
            <Button
              type="button"
              text="Done"
              onClick={() => {
                handleClick();
                window.location.reload();
              }}
            />
          </Flex>
          <Flex className="mb-10" flexColumn>
            {isLoggedIn === true && (
              <Button
                outline
                type="button"
                text="View Transaction History"
                onClick={() => {
                  dispatch(getProfileDetailsFetch())
                  dispatch(saveWalletAddress(""));
                  if (applicationId) {
                    openInNewTab(`https://payonramp.com`);
                    return;
                  }
                  isLoggedIn ? navigate(`/history`) : navigate('/');
                }}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
  );
};

export default PurchaseComplete;
