const Loading = ({ width = "25px", height = "25px" }) => {
    return (
        <svg
        className="loading"
        width={width}
        height={height}
        style={{
          verticalAlign: "middle",
          fill: "#ffffff",
          overflow: "hidden"
        }}
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M512 213.333333c-25.6 0-42.666667-17.066667-42.666667-42.666667L469.333333 42.666667c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 128C554.666667 196.266667 537.6 213.333333 512 213.333333z" />
        <path d="M512 1024c-25.6 0-42.666667-17.066667-42.666667-42.666667l0-128c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 128C554.666667 1006.933333 537.6 1024 512 1024z" />
        <path d="M170.666667 554.666667 42.666667 554.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667l128 0c25.6 0 42.666667 17.066667 42.666667 42.666667S196.266667 554.666667 170.666667 554.666667z" />
        <path d="M981.333333 554.666667l-128 0c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667l128 0c25.6 0 42.666667 17.066667 42.666667 42.666667S1006.933333 554.666667 981.333333 554.666667z" />
        <path d="M806.4 384c-12.8 0-29.866667-8.533333-38.4-21.333333-12.8-21.333333-4.266667-46.933333 17.066667-59.733333L896 238.933333c21.333333-12.8 46.933333-4.266667 59.733333 17.066667 12.8 21.333333 4.266667 46.933333-17.066667 59.733333l-110.933333 64C823.466667 384 814.933333 384 806.4 384z" />
        <path d="M106.666667 789.333333c-12.8 0-29.866667-8.533333-38.4-21.333333-12.8-21.333333-4.266667-46.933333 17.066667-59.733333l110.933333-64c21.333333-12.8 46.933333-4.266667 59.733333 17.066667 12.8 21.333333 4.266667 46.933333-17.066667 59.733333L128 785.066667C119.466667 789.333333 110.933333 789.333333 106.666667 789.333333z" />
        <path d="M682.666667 260.266667c-8.533333 0-12.8 0-21.333333-4.266667-21.333333-12.8-25.6-38.4-17.066667-59.733333L708.266667 85.333333c12.8-21.333333 38.4-25.6 59.733333-17.066667 21.333333 12.8 25.6 38.4 17.066667 59.733333l-64 110.933333C712.533333 251.733333 695.466667 260.266667 682.666667 260.266667z" />
        <path d="M277.333333 960c-8.533333 0-12.8 0-21.333333-4.266667-21.333333-12.8-25.6-38.4-17.066667-59.733333l64-110.933333C315.733333 768 341.333333 759.466667 362.666667 772.266667c21.333333 12.8 25.6 38.4 17.066667 59.733333L315.733333 938.666667C307.2 951.466667 290.133333 960 277.333333 960z" />
        <path d="M217.6 384C209.066667 384 200.533333 384 196.266667 379.733333L85.333333 315.733333C64 302.933333 55.466667 277.333333 68.266667 256 81.066667 234.666667 106.666667 230.4 128 238.933333l110.933333 64C260.266667 315.733333 264.533333 341.333333 251.733333 362.666667 247.466667 375.466667 230.4 384 217.6 384z" />
        <path d="M917.333333 789.333333c-8.533333 0-12.8 0-21.333333-4.266667l-110.933333-64c-21.333333-12.8-25.6-38.4-17.066667-59.733333 12.8-21.333333 38.4-25.6 59.733333-17.066667l110.933333 64c21.333333 12.8 25.6 38.4 17.066667 59.733333C947.2 780.8 934.4 789.333333 917.333333 789.333333z" />
        <path d="M341.333333 260.266667c-12.8 0-29.866667-8.533333-38.4-21.333333L238.933333 128C230.4 106.666667 234.666667 81.066667 256 68.266667 277.333333 55.466667 302.933333 64 315.733333 85.333333l64 110.933333C388.266667 217.6 384 243.2 362.666667 251.733333 354.133333 256 349.866667 260.266667 341.333333 260.266667z" />
        <path d="M746.666667 960c-12.8 0-29.866667-8.533333-38.4-21.333333l-64-110.933333c-12.8-21.333333-4.266667-46.933333 17.066667-59.733333 21.333333-12.8 46.933333-4.266667 59.733333 17.066667l64 110.933333c12.8 21.333333 4.266667 46.933333-17.066667 59.733333C759.466667 960 755.2 960 746.666667 960z" />
      </svg>
    );
}

export default Loading;
