import React from 'react'
import Text from '../text'

const Select = ({
    secondary,
    mb0,
    label, 
    noLabel,
    type, 
    value, 
    name, 
    id, 
    placeholder, 
    onChange, 
    readonly, 
    disabled, 
    required,
    options,
    defaultValue,
    multiple,
    errormessage}) => {
    return (
        <div className={`form-field ${secondary ? 'form-field-secondary' : ''} ${mb0 ? 'mb-0' : ''}`}>
            {!noLabel && <Text type="LABEL" text={label} className="form-label" />}
            <select
                className={`form-control ${value ? "form-control-value" : ""} ${errormessage ? "error" : ""}`}
                type={type}
                name={name}
                value={value}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                readOnly={readonly}
                disabled={disabled}
                required={required}
                multiple={multiple}
                defaultValue={defaultValue}
            >
                {options?.map((x, y) => (
                    <option key={y} value={x.value} disabled={x.disabled}>
                        {x.label}
                    </option>
                ))}
            </select>
            {errormessage && type != "checkbox" && (
                <div className="message-error">
                    <small>{errormessage}</small>
                </div>
            )}
        </div>
    )
}

export default Select