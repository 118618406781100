import React from "react";
import Text from "../text";

const NavTab = ({ lists, className }) => {
    return(
        <>
        <ul className={`nav-tab-wrapper ${className ? className : ''}`}>
            {lists.map((item, id) => (
                <li key={id} className={`nav-tab-item ${item.navTabStatus == id ? "active" : ""}`}>
                    <div className="nav-tab-item-link" onClick={() => item.navTabHandleclick(item)}>
                        {item.icon && <span className="nav-tab-item-icon">{item.icon}</span>}
                        <Text type="H6" className="nav-tab-item-title" text={item.text} />
                    </div>
                </li>
            ))}
        </ul>
        </>
    )
}

export default NavTab;