import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-phone-number-input/style.css";
import "./assets/scss/index.scss";
import { useSelector, useDispatch } from "react-redux";
// api
import { httpGet } from "./utils/axiosConnect";
// page
import Home from "./pages/home";
import History from "./pages/history";
import Profile from "./pages/profile";
import Support from "./pages/support";
import Partnership from "./pages/partnership";
import BecomeAPartner from "./pages/becomePartner";
import TermsAndConditions from "./pages/terms&conditions";
import PrivacyPolicy from "./pages/privacyPolicy";
import UserAgreement from "./pages/userAgreement";
import UserPolicy from "./pages/userPolicy";
import Tickets from "./pages/tickets";
import BuyCrypto from "./pages/buyCrypto";
import SellCrypto from "./pages/sellCrypto";
import AboutUs from "./pages/aboutus";
import Loader from "./pages/loader";
import ManualKyc from "./pages/manualKyc";
import HyperVergeKYC from "./pages/HyperVerge";

import { APPLICATION_ID } from "./utils/constants";

import { getProfileDetailsFetch, logoutFetch } from "./redux/user/reducers";

import { saveUserLoginDetails } from "./redux/user/persistReducer";

import {
  getAllCurrenciesSuccess,
  getPlatformCurrenciesFetch,
} from "./redux/user/reducers";
import ErrorBoundary from "./components/errorBoundary";
import ErrorPage from "./pages/error";

let idleTime = 0;
let idleInterval;
const params = new URLSearchParams(window.location.search);
const applicationId = params.get("applicationId");

function App() {
  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  const dispatch = useDispatch();

  const [login, setLogin] = useState(false);

  useEffect(() => {
    async function getDetails() {
      dispatch(getProfileDetailsFetch());
      const response = await httpGet(`user/portal/profile`, {});
      const currecies = await httpGet(
        `crypto/market/api/v1/${applicationId || APPLICATION_ID}/currencies`,
        {}
      );
      if (currecies) {
        dispatch(getAllCurrenciesSuccess(currecies?.data?.data));
        dispatch(getPlatformCurrenciesFetch());
      }
      if (response) dispatch(saveUserLoginDetails(response.data.data.kycInfo));
      setLogin(true);
    }

    getDetails();
  }, []);

  useEffect(() => {
    document.body.classList.add("body");
    if (isLoggedIn) {
      document.getElementById("app").addEventListener("mousemove", () => {
        idleTime = 0;
      });

      function logout() {
        idleTime++;
        if (idleTime >= 10) {
          clearInterval(idleInterval);
          idleTime = 0;
          document
            .getElementById("app")
            .removeEventListener("mousemove", () => {}, false);
          dispatch(logoutFetch());
        }
      }

      clearInterval(idleInterval);
      idleInterval = setInterval(logout, 60000);
    }
  }, [isLoggedIn]);

  return (
    <div className="App" id="app">
      {login ? (
        <BrowserRouter>
          <ErrorBoundary>
            <Routes>
              <Route path="/">
                <Route index={true} element={<Home />} />
                <Route
                  path="history"
                  element={
                    isLoggedIn ? <History /> : <Navigate to="/" replace />
                  }
                />
                <Route
                  path="profile"
                  element={
                    isLoggedIn ? <Profile /> : <Navigate to="/" replace />
                  }
                />
                <Route path="support" element={<Support />} />
                <Route path="partnership" element={<Partnership />} />
                <Route path="becomeAPartner" element={<BecomeAPartner />} />
                <Route
                  path="termsAndConditions"
                  element={<TermsAndConditions />}
                />
                <Route path="privacyPolicy" element={<PrivacyPolicy />} />
                <Route path="userAgreement" element={<UserAgreement />} />
                <Route path="userPolicy" element={<UserPolicy />} />
                <Route
                  path="tickets"
                  element={
                    isLoggedIn ? <Tickets /> : <Navigate to="/" replace />
                  }
                />
                {/* <Route path="tickets/detail" element={<TicketDetail />} /> */}
                <Route path="buy-crypto" element={<BuyCrypto />} />
                <Route path="sell-crypto" element={<SellCrypto />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="kyc" element={<ManualKyc />} />
                <Route path="hyper-verge-kyc" element={<HyperVergeKYC />} />
                <Route path="error" element={<ErrorPage />} />
                <Route path="*" element={<ErrorPage notFound />} />
              </Route>
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default App;
