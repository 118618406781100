import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import Textarea from "../../../../components/input/textarea";
import CopyToClipboardCard from "../../../../components/copyToClipboardCard";
import { saveWalletAddress } from "../../../../redux/deposit/persistReducer";
import { useDispatch, useSelector } from "react-redux";

const WalletDetails = (props) => {
  const dispatch = useDispatch();
  const { walletAddress } = useSelector(
    (state) => state?.depositPersistReducer
  );
  const formik = useFormik({
    initialValues: {
      walletAddress: walletAddress?.walletAddress || "",
    },
    validationSchema: Yup.object({
      walletAddress: Yup.string()
        .required("Wallet address is required")
        .matches(
          /^(?:[13][a-km-zA-HJ-NP-Z1-9]{25,34}|0x[a-fA-F0-9]{40}|r[0-9a-zA-Z]{24,34}|L[a-km-zA-HJ-NP-Z1-9]{25,34}|addr1[0-9a-zA-Z]{58}|G[0-9A-Z]{55}|D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}|tz[1-9A-HJ-NP-Za-km-z]{34}|EOS[1-5a-z]{49}|T[a-zA-Z0-9]{33}|A[0-9a-zA-Z]{33}|T[0-9a-zA-Z]{33})$/,
          "Invalid wallet address"
        ),
    }),
    onSubmit: (values) => {
      dispatch(saveWalletAddress(values));
      props.handleSubmit();
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className="height-100">
      <Flex flexColumn justifyContent="between" className="p-4 height-100">
        <Flex flexColumn>
          <Text type="H3" text="Buy Crypto" className="text-center mb-30" />
          <Text type="LEGEND" text="Wallet Details" />
          <div className="form-box copyclip-position-relative">
            <Textarea
              label="Enter your wallet address"
              type="text"
              name="walletAddress"
              placeholder=""
              value={formik.values.walletAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              mandatory
              rows={4}
              errormessage={
                formik.touched.walletAddress && formik.errors.walletAddress
                  ? formik.errors.walletAddress
                  : null
              }
            />
            <CopyToClipboardCard title={formik.values.walletAddress} />
          </div>
        </Flex>
        <Flex flexColumn>
          <Button type="submit" text="Proceed" />
        </Flex>
      </Flex>
    </form>
  );
};

export default WalletDetails;
