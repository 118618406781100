import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import ViewBreakupCard from "../../../../components/viewBreakupCard";
import useHome from "../../hooks/home.hook";
import SelectStyle from "../../../../components/input/selectStyle";
import OptionSelectItem from "../../../../components/optionSelectItem";
import INRIcon from "../../../../assets/img/icon/inr-icon.svg";
import USDTIcon from "../../../../assets/img/icon/usdt-icon.png";
import BTCIcon from "../../../../assets/img/icon/btc-icon.png";
import ETCIcon from "../../../../assets/img/icon/etc-icon.png";
import USDCIcon from "../../../../assets/img/icon/usdc-icon.png";
import Input from "../../../../components/input";
import Image from "../../../../components/image";
import ChevronDownIcon from "../../../../components/svg/chevronDownIcon";
import SelectBankRadio from "../../../../components/radioButton/selectBankRadio";
import CopyToClipboardCard from "../../../../components/copyToClipboardCard";
import { useSelector, useDispatch } from "react-redux";
import {
  walletDetailsFetch,
} from "../../../../redux/deposit/reducer";
import { verifyCryptoDepositFetch } from "../../../../redux/withdrawal/reducer";
import Input1 from "../../../../components/Input1";
import DotsLoading from "../../../../components/loading/dotsLoading";
import { getTds } from "../../../../utils/util";
import InfoIcon from "../../../../components/svg/infoIcon";
import Tooltip from "../../../../components/tooltip";

const currencyOption = [
  {
    value: "INR",
    label: (
      <OptionSelectItem src={INRIcon} text="INR" subText="Tether" min="100" />
    ),
  },
  {
    value: "USDT",
    label: (
      <OptionSelectItem src={USDTIcon} text="USDT" subText="Tether" min="100" />
    ),
  },
  {
    value: "BTC",
    label: (
      <OptionSelectItem src={BTCIcon} text="BTC" subText="Bitcoin" min="1" />
    ),
  },
  {
    value: "ETC",
    label: (
      <OptionSelectItem
        src={ETCIcon}
        text="ETC"
        subText="Ethereum Classic"
        min="20"
      />
    ),
  },
  {
    value: "USDC",
    label: (
      <OptionSelectItem
        src={USDCIcon}
        text="USDC"
        subText="USD Coin"
        min="100"
      />
    ),
  },
];

const SendCrypto = (props) => {
  const [isEdit, setIsEdit] = useState(true);
  const dispatch = useDispatch();
  const { sellReceiveData, Loading, sellData, userBankDetails } =
    useSelector((state) => state?.withdrawalPersistReducer);

  const { bankDetails } = useSelector(
    (state) => state?.withdrawalPersistReducer
  );

  const bankLabel = bankDetails ? bankDetails.bankName : userBankDetails?.label.split("-")[1];
  const bankNumber = bankDetails ? bankDetails.accountNumber : userBankDetails?.label.split("-")[0];


  const { convertionCurrent, isLoggedIn, isPanAadharLinked } = useSelector(
    (state) => state?.userPersistReducer
  );
  const { platformFee, profileDetails } = useSelector((state) => state?.userReducer);
  const feeWavier = profileDetails?.userInfo?.feeWaiver

  const { tdsPercent, tdsMessage } = getTds(isLoggedIn, isPanAadharLinked);

  const payonrampFee = !feeWavier ? platformFee?.sellFeePercent : 0
  const { paymentMethodIsLoading, walletDetails } = useSelector(
    (state) => state?.depositReducer
  );
  useEffect(() => {
    const body = {
      amount: sellData?.receiveCurrencyAmount,
      currency: sellReceiveData?.token,
    };
    dispatch(
      walletDetailsFetch({
        body,
      })
    );
  }, []);

  const { platformCurrencies } = useSelector((state) => state?.userReducer);

  const { exchangeRateSell, sellMarkup } = useSelector((state) => state?.userReducer);


  let allowedCurrenciesOptions = [];
  platformCurrencies?.currencies?.map((item, index) => {
    allowedCurrenciesOptions.push({
      label: item,
      value: item,
      key: index,
    });
  });
  const {
    selectedBuyCurrency,
    handleBuyCurrencyChange,
  } = useHome();

  const formik = useFormik({
    initialValues: {
      buyCurrencySelect: sellData?.buyCurrencySelect,
      buyCurrencyAmount: convertionCurrent,
      receiveCurrencySelect: sellReceiveData?.token,
      receiveCurrencyAmount: sellData?.receiveCurrencyAmount,
      walletAddress: walletDetails?.walletAddress,
      transactionHash: "",
      blockNumber: "",
    },
    validationSchema: Yup.object({
      buyCurrencySelect: Yup.string().required("Please enter a select"),
      buyCurrencyAmount: Yup.number()
        .required("Please enter a buy amount"),
      receiveCurrencySelect: Yup.string().required("Please enter a select"),
      receiveCurrencyAmount: Yup.string().required(
        "Please enter a receive amount"
      ),
      transactionHash: Yup.string().required("Please enter a transaction hash"),
      blockNumber: Yup.string().required("Please enter a block number"),
    }),
    onSubmit: (values) => {
      verifyCryptoDepositHandler(formik?.values);
    },
  });
  const callback = () => {
    props.handleSubmit();
  };
  const verifyCryptoDepositHandler = (values) => {
    const data = {
      transactionHash: values.transactionHash,
      blockNumber: values.blockNumber,
      amount: sellData?.receiveCurrencyAmount,
      currency: sellReceiveData?.token,
    };
    dispatch(
      verifyCryptoDepositFetch({
        data,
        callback,
      })
    );
  };
  var payonrampFeeValue = !feeWavier ? (
    convertionCurrent *
    payonrampFee *
    0.01
  ).toFixed(4) : 0;
  var tdsPercentValue = (
    (convertionCurrent - payonrampFeeValue) *
    (tdsPercent / 100)
  ).toFixed(4);

  const reqCurrencyObj = platformCurrencies?.currecies?.find(
    (item) => item?.aliasName === "USDT-TRON"
  );

  var receiveCurrencySelectDefault;
  if (formik.values.receiveCurrencySelect) {
    receiveCurrencySelectDefault = formik.values.receiveCurrencySelect;
  } else {
    receiveCurrencySelectDefault = reqCurrencyObj?.currency;
  }
  var propsCode;
  if (props.code) {
    propsCode = props.code;
  } else if (sellReceiveData?.code) {
    propsCode = sellReceiveData?.code;
  } else if (
    (!formik.values.receiveCurrencySelect ||
      typeof formik.values.receiveCurrencySelect === "undefined") &&
    reqCurrencyObj?.currency == "USDT"
  ) {
    propsCode = reqCurrencyObj?.tokenType;
  } else {
    propsCode = null;
  }

  return (
    <div className="flow-box">
      {Loading === true ? (
        <DotsLoading />
      ) : (
        <form onSubmit={formik.handleSubmit} className="height-100">
          <Flex flexColumn justifyContent="between" className="p-4 height-100">
            <Flex flexColumn>
              <Button
                linkDark
                iconLeft={<ChevronDownIcon left={true} width="15" height="9" />}
                className="mback-btn"
                type="button"
                onClick={() => {
                  props.goToBack()
                }}
              />
              <Text
                type="H3"
                text="Sell Crypto"
                className="text-center mb-30"
              />
              <Text type="LEGEND" text="Send Crypto" />
              {/* <Flex
                justifyContent="between"
                alignItems="center"
                className="mb-4"
              > */}
              {/* <div className="fsc-card bg-light-green align-items-center"> */}
              <Text
                type="H3"
                className="text-center mb-0"
                // alignItems= "center"
                text={`${formik?.values?.receiveCurrencyAmount} ${formik?.values?.receiveCurrencySelect}`}
              />
              {/* </div> */}
              {/* <Flex justifyContent="center" className="mx-3">
                <Icon gray icon={<ArrowRightIcon />} />
              </Flex> */}
              {/* </Flex> */}
              <Text
                type="LABEL"
                text="Send to wallet address"
                className="text-center mb-2"
              />
              {paymentMethodIsLoading ? (
                <div>Loading...</div>
              ) : (
                <div className="fsc-card">
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="fsc-label-text"
                  >
                    <CopyToClipboardCard title={walletDetails?.walletAddress} />
                  </Flex>
                  <Text
                    type="SPAN"
                    className="wa-text"
                    text={walletDetails?.walletAddress}
                  />
                </div>
              )}
              <Flex justifyContent="between">
                <Text
                  type="LABEL"
                  className="font-size-14 font-weight-500 mb-1"
                  text="Sell"
                />
                {/* commented edit icon */}

                {/* {isEdit ? (
                <Button
                  type="button"
                  linkPrimary
                  style={{ textDecoration: "none" }}
                  iconLeft={<EditIcon />}
                  onClick={handleEdit}
                  text="Edit"
                />
              ) : (
                <Button
                  type="button"
                  linkPrimary
                  style={{ textDecoration: "none" }}
                  iconLeft={<SaveIcon />}
                  onClick={handleSave}
                  text="Save Changes"
                />
              )} */}
              </Flex>
              <div className="card-field">
                <div className="row d-flex align-items-center">
                  <div className="col-5 col-md-5">
                    <div className="form-box">
                      <Button
                        mb0
                        secondary
                        isSearchable={true}
                        type="button"
                        className="btn1"
                        onClick={props.onClick}
                        text={
                          <>
                            {receiveCurrencySelectDefault}
                            {propsCode !== null && <br />}
                            {propsCode !== null && `on ${propsCode}`}
                          </>
                        }
                      />
                    </div>
                  </div>
                  <div className="col-7 col-md-7">
                    <div className="card-field-form-box">
                      <label className="card-field-label">
                        {formik.values.receiveCurrencySelect}
                      </label>
                      <Input
                        secondary
                        mb0
                        noLabel
                        type="text"
                        name="receiveCurrencyAmount"
                        placeholder="You will receive"
                        value={formik.values.receiveCurrencyAmount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        readonly={isEdit}
                        errormessage={
                          formik.touched.receiveCurrencyAmount &&
                            formik.errors.receiveCurrencyAmount
                            ? formik.errors.receiveCurrencyAmount
                            : null
                        }
                      />
                    </div>
                    <Flex flexColumn className="mt-12">
                      <Text
                        type="PARAGRAPH"
                        className="font-size-12 font-weight-500 mb-0"
                        text={`1 ${formik.values.receiveCurrencySelect} = ₹ ${exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup))}`}
                      />
                    </Flex>
                  </div>
                </div>
              </div>
              <Text
                type="LABEL"
                className="font-size-14 font-weight-500 mb-1"
                text="Receive"
              />
              <div className="card-field">
                <div className="row d-flex align-items-center">
                  <div className="col-5 col-md-5">
                    <div className="form-box">
                      <SelectStyle
                        mb0
                        secondary
                        isSearchable={true}
                        name="buyCurrencySelect"
                        value={selectedBuyCurrency[0]}
                        defaultValue={currencyOption[0]}
                        isDisabled={isEdit}
                        onChange={(selectedOption) => {
                          handleBuyCurrencyChange(selectedOption);
                          formik.handleChange("buyCurrencySelect")(
                            selectedOption.value
                          );
                        }}
                        options={currencyOption}
                        isReadOnly={isEdit}
                        errormessage={
                          formik.touched.buyCurrencySelect &&
                            formik.errors.buyCurrencySelect
                            ? formik.errors.buyCurrencySelect
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="col-7 col-md-7">
                    <div className="card-field-form-box">
                      <label className="card-field-label">
                        {formik.values.buyCurrencySelect === "INR" && (
                          <Image src={INRIcon} alt="INR" />
                        )}
                        {formik.values.buyCurrencySelect === "USDT" && (
                          <Image src={USDTIcon} alt="USDT" />
                        )}
                        {formik.values.buyCurrencySelect === "BTC" && (
                          <Image src={BTCIcon} alt="BTC" />
                        )}
                        {formik.values.buyCurrencySelect === "ETC" && (
                          <Image src={ETCIcon} alt="ETC" />
                        )}
                        {formik.values.buyCurrencySelect === "USDC" && (
                          <Image src={USDCIcon} alt="USDC" />
                        )}
                        <Text
                          type="SPAN"
                          className="ml-8"
                          text={formik.values.buyCurrencySelect}
                        />
                      </label>
                      <Input
                        secondary
                        mb0
                        noLabel
                        type="text"
                        name="buyCurrencyAmount"
                        placeholder="min 100"
                        value={(
                          convertionCurrent -
                          payonrampFeeValue -
                          tdsPercentValue
                        ).toFixed(4)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        readonly={isEdit}
                        errormessage={
                          formik.touched.buyCurrencyAmount &&
                            formik.errors.buyCurrencyAmount
                            ? formik.errors.buyCurrencyAmount
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <Text
                    type="LABEL"
                    className="font-size-14 font-weight-500 mb-1"
                    text="Transaction Hash *"
                  />
                  <div className="form-box">
                    <Input1
                      secondary
                      mb0
                      noLabel
                      type="text"
                      name="transactionHash"
                      placeholder="Enter transaction hash"
                      value={formik.values.transactionHash}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      mandatory
                      errormessage={
                        formik.touched.transactionHash &&
                          formik.errors.transactionHash
                          ? formik.errors.transactionHash
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <Text
                    type="LABEL"
                    className="font-size-14 font-weight-500 mb-1"
                    text="Block Number *"
                  />
                  <div className="form-box1">
                    <Input1
                      className="phone-number-field my-0"
                      secondary
                      mb0
                      noLabel
                      type="text"
                      name="blockNumber"
                      placeholder="Enter block number"
                      value={formik.values.blockNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      mandatory
                      errormessage={
                        formik.touched.blockNumber && formik.errors.blockNumber
                          ? formik.errors.blockNumber
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <p></p>
              <div>
                <Text
                  type="LABEL"
                  className="font-size-14 font-weight-500 mb-1"
                  text="Receive to Bank"
                />
                <div className="form-box sellPreview">
                  <SelectBankRadio
                    value={bankLabel}
                    bankNumber={bankNumber}
                    name="bank"
                    onChange={formik.handleChange}
                    readonly
                  />
                </div>
              </div>
              <Text
                type="PARAGRAPH"
                className="font-size-12 font-weight-500 text-dark-half mb-0 mt-3"
                text="Breakup"
              />
              <ViewBreakupCard
                active
                lists={[
                  {
                    text: `${formik.values.buyCurrencySelect + "Net Price"}`,
                    number: `₹${convertionCurrent}`,
                  },
                  {
                    text: "PayOnRamp Fee",
                    number: `₹${payonrampFeeValue}`,
                  },
                  {
                    text: <Flex alignItems="center" justifyContent="center">TDS ({tdsPercent}%)&nbsp;
                      {!isPanAadharLinked && <Tooltip direction="right" text={tdsMessage}><InfoIcon width="15" height="15" /></Tooltip>}</Flex>,
                    number: `₹${tdsPercentValue}`,
                  },
                  {
                    text: "Receive Total",
                    number: `₹${(
                      convertionCurrent -
                      payonrampFeeValue -
                      tdsPercentValue
                    ).toFixed(4)}`,
                  },
                ]}
              />
            </Flex>
            <Flex flexColumn className="mt-3">
              <Button
                type="submit"
                text="I have sent"
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
            </Flex>
          </Flex>
        </form>
      )}
    </div>
  );
};

export default SendCrypto;
