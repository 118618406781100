import { put, takeEvery } from "redux-saga/effects";
import { httpPost } from "../../utils/axiosConnect";
import API from "../../utils/api";
import { STATUS_CODE } from "../../utils/constants";
import {
  getQRCodeSuccess,
  getQRCodeFailure,
  utrTransferSuccess,
  utrTransferFailure,
  paymentOptionFailure,
  paymentOptionsSuccess,
  walletDetailsFailure,
  walletDetailsSuccess,
  bankDetailsFailure,
  bankDetailsSuccess,
  verifyTransactionSuccess,
  verifyTransactionFailure,
  updatCryptoTransferRequest,
  transactionDetailsSuccess,
  transactionDetailsFailure,
} from "./reducer";

import { toasts } from "../../utils/toast";

function* getQRCodeSaga({ payload }) {
  try {
    const response = yield httpPost(API.CPG.USER.GET_QR, {
      amount: payload?.amount,
    });
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getQRCodeSuccess(response.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(getQRCodeFailure());
      payload?.showError();
    } else {
      yield put(getQRCodeFailure());
      payload?.showError();
    }
  } catch (error) {
    yield put(getQRCodeFailure());
    payload?.showError();
  }
}

function* paymentOptionsSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.TTRANSACTIONS.PAYMENT_OPTIONS,
      payload
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(paymentOptionsSuccess(response.data.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(paymentOptionFailure());
    } else {
      yield put(paymentOptionFailure());
    }
  } catch (error) {
    yield put(paymentOptionFailure());
  }
}

function* utrTransferSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.TTRANSACTIONS.UTR_TRANSFER,
      payload?.formData
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(utrTransferSuccess(payload.transactionDetails));
      payload.callBack({responseStatus: true})
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden || STATUS_CODE.badRequest)
    ) {
      yield put(utrTransferFailure());
      toasts.error(response?.data?.message);
      payload.callBack({responseStatus: false})
    } else {
      yield put(utrTransferFailure());
      toasts.error(response?.data?.message);
      payload.callBack({responseStatus: false})
    }
  } catch (error) {
    yield put(utrTransferFailure());
    toasts.error(error?.message);
    payload.callBack({responseStatus: false})
  }
}

function* merchantWalletDetailsSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.TTRANSACTIONS.WALLET_DEATILS,
      payload?.body
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(walletDetailsSuccess(response.data.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden || STATUS_CODE.badRequest)  
    ) {
      yield put(walletDetailsFailure());
    } else {
      yield put(walletDetailsFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(walletDetailsFailure());
  }
}

function* bankDetailsSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.CPG.USER.BANK_DETAILS,
      payload?.values
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(bankDetailsSuccess(response.data?.bankAccount));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden || STATUS_CODE.badRequest)
    ) {
      yield put(bankDetailsFailure());
      toasts.error(response?.data?.message);
    } else {
      yield put(bankDetailsFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(bankDetailsFailure());
    payload?.showError();
  }
}

function* verifyTransactionSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.TTRANSACTIONS.TRANSACTION_STATUS,
      payload,
      {}
    );
    if (
      response?.status === STATUS_CODE?.successful &&
      response?.data?.status === "SUCCESS"
    ) {
      yield put(
        verifyTransactionSuccess({
          ...payload?.transactionDetails,
          transactionId: response?.data?.referenceId,
        })
      );
      yield put(updatCryptoTransferRequest("SUCCESS"));
      payload?.callback && payload?.callback();
    } else if (
      response?.status === STATUS_CODE?.successful &&
      response?.data?.status === "CANCELLED"
    ) {
      yield put(
        verifyTransactionFailure({
          ...payload?.transactionDetails,
          applicationId: payload?.applicationId,
        })
      );
      yield put(updatCryptoTransferRequest("CANCELLED"));
      payload?.callback && payload?.callback();
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden || STATUS_CODE.badRequests)
    ) {
      yield put(updatCryptoTransferRequest("PENDING"));
      payload?.callback && payload?.callback();
    } else {
      yield put(updatCryptoTransferRequest("PENDING"));
      payload?.callback && payload?.callback();
    }
  } catch (error) {
    yield put(updatCryptoTransferRequest("PENDING"));
    payload?.callback && payload?.callback();
  }
}

function* transactionDetailsSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.TTRANSACTIONS.TRANSACTION_DETAILS,
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(transactionDetailsSuccess(response.data?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden || STATUS_CODE.badRequest)
    ) {
      yield put(transactionDetailsFailure());
      toasts.error(response?.data?.message);
    } else {
      yield put(transactionDetailsFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(transactionDetailsFailure());
    payload?.showError();
  }
}

function* depositSaga() {
  yield takeEvery("depositReducer/getQRCodeFetch", getQRCodeSaga);
  yield takeEvery("depositReducer/utrTransferFetch", utrTransferSaga);
  yield takeEvery("depositReducer/paymentOptionsFetch", paymentOptionsSaga);
  yield takeEvery(
    "depositReducer/walletDetailsFetch",
    merchantWalletDetailsSaga
  );
  yield takeEvery("depositReducer/bankDetailsFetch", bankDetailsSaga);
  yield takeEvery(
    "depositReducer/verifyTransactionFetch",
    verifyTransactionSaga
  );
  yield takeEvery("depositReducer/transactionDetailsFetch", transactionDetailsSaga)
}

export default depositSaga;
