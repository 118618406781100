import React from 'react'

const CloseAlertIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                opacity="0.6" 
                d="M1.05337 15.9496L1.05327 15.9497L1.05854 15.9549C1.23436 16.1286 1.45839 16.2 1.6732 16.2C1.88801 16.2 2.11204 16.1286 2.28786 15.9549L8.59482 9.72277L14.7121 15.7675C14.888 15.9412 15.112 16.0126 15.3268 16.0126C15.5416 16.0126 15.7656 15.9412 15.9415 15.7675C16.2862 15.4269 16.2862 14.8867 15.9415 14.5461L9.82753 8.50468L15.9415 2.46331C16.2862 2.12268 16.2862 1.5825 15.9415 1.24187C15.5981 0.902553 15.0555 0.902553 14.7121 1.24187L8.59482 7.2866L2.28786 1.05449C1.94446 0.71517 1.40193 0.71517 1.05854 1.05449C0.71382 1.39512 0.71382 1.9353 1.05854 2.27593L1.05875 2.27614L7.38086 8.50489L1.05854 14.7522L1.19886 14.8942L1.05854 14.7522C0.729979 15.0768 0.737311 15.6132 1.05337 15.9496Z" 
                fill="currentColor" 
                stroke="currentColor" 
                strokeWidth="0.4"
            />
        </svg>
    )
}

export default CloseAlertIcon

CloseAlertIcon.defaultProps = {
    width: '17',
    height: '17'
}