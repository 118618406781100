import React from 'react'
import Text from '../text'
import AttachmentIcon from '../svg/attachmentIcon';

const File = ({
    mb0,
    label, 
    type, 
    value, 
    name, 
    id, 
    placeholder, 
    onChange, 
    readonly, 
    disabled, 
    required, 
    autoFocus, 
    maxlength,
    errormessage,
    className,
    selectedFile=''
}) => {
  return (
    <div className={`form-field form-file-field ${className ? className : ''} ${mb0 ? 'mb-0' : ''}`}>
        <Text type="LABEL" text={label} className="form-label" />
        <div className="form-file-main">
            <input
                className={`form-control ${value ? "form-control-value" : ""} ${errormessage ? "error" : ""}`}
                type={type}
                name={name}
                value={value}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                readOnly={readonly}
                disabled={disabled}
                required={required}
                autoFocus={autoFocus}
                maxLength={maxlength}
                selectedFile={selectedFile}
            />
            <div className="attachment-content">
                <div className="attachment-icon">
                    <AttachmentIcon />
                </div>
                <Text type="H5" className="attachment-text-title" text={selectedFile ? 'Selected' : 'Drag & Drop'} />
                <Text type="SPAN" className="attachment-text-or" text={selectedFile ? selectedFile : 'or'} /> 
                {selectedFile === '' && (
                   <Text type="SPAN" className="attachment-button" text='Upload Image' />
                )}               
            </div>
        </div>
        {errormessage && type != "checkbox" && (
            <div className="message-error">
                <small>{errormessage}</small>
            </div>
        )}
    </div>
  )
}

export default File