import React from 'react'

import ProfileView from './views/ProfileView'
import Layout from '../include/layout'

const ProfileController = () => {
    return (
        <Layout>
            <ProfileView />
        </Layout>
    )
}

export default ProfileController