import React from 'react'
import Text from '../text';

const SelectBankRadio = (props) => {
    const {value, name, isChecked, onChange, readonly, icon, bankNumber} = props;
    return (
        <div className={`select-bank-radio-card ${isChecked ? "active" : ""} ${readonly ? "readonly" : ""}`}>
            <input
                className="select-bank-radio-field"
                type="radio"
                value={value}
                id={name}
                name={name}
                checked={isChecked}
                onChange={onChange}
                readonly={readonly}
            />
            <div className="sbr-card">
                <div className="sbr-left">
                    <div className="sbr-icon">
                        {icon}
                    </div>
                    <Text type="SPAN" className="sbr-text" text={value} />
                </div>
                <Text type="SPAN" className="sbr-text" text={bankNumber} />
            </div>
        </div>
    )
}

export default SelectBankRadio