import React from "react";
import Flex from "../flex";
import Button from "../button";
import Text from "../text";
import SelectStyle from "../input/selectStyle";
import OptionSelectItem from "../optionSelectItem";
import { useSelector } from "react-redux";
import BackIcon from "../svg/backIcon";

const SearchCryptocurrencies = (props) => {

  const { platformCurrencies } = useSelector((state) => state?.userReducer);

  const currecies = props.direction === 'buy' ? platformCurrencies?.currecies : platformCurrencies?.sellCurrecies;
  let allowedCurrenciesOptions = [];
  
  currecies?.map((item, index) => {
    allowedCurrenciesOptions.push({
      label: (
        <OptionSelectItem
          text={item?.currency}
          subText={item?.tokenName}
          code={item?.tokenType}
        />
      ),
      value: item?.blockchain,
      token: item?.currency,
      fee: item?.minimumBuyLimit + item?.networkFee,
      networkFee: item?.networkFee,
      key: index,
      code: item?.tokenType,
      source: item?.source,
      name: item?.aliasName,
      decimals: item?.decimals,
    });
  });

  const customFilter = (option, searchText) => {
    return option.data.token.toLowerCase().includes(searchText.toLowerCase());
  };

  return (
    <div className="search-cryptocurrencies-wrapper">
      <div className="search-cryptocurrencies-main">
        <Flex alignItems="center" className="mb-4">
          <Button
            type="button"
            linkDark
            iconLeft={<BackIcon />}
            className="mback-btn"
            onClick={() => {
              props.onClose()
              window.location.reload();
            }}
          />
          <Text
            type="H3"
            text="Search for Cryptocurrencies"
            className="mb-0 ml-3"
          />
        </Flex>
        <Flex>
          <div className="form-box">
            <SelectStyle
              menuIsOpen={true}
              mb0
              isSearchable={true}
              value={props.selectedReceiveCurrency}
              type="button"
              className="btn1"
              onChange={props.onChange}
              options={allowedCurrenciesOptions}
              placeholder="Type a currency"
              filterOption={customFilter}
            />
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default SearchCryptocurrencies;
