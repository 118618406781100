import React, { useEffect, useState } from "react";
import Button from "../button";
import Icon from "../icon";
import RefreshIcon from "../svg/refreshIcon";
import CustomImage from "../image/index";
import QRScanImg from "../../../src/assets/img/qrScan.svg";

const QRCodeCard = ({ qrImage }) => {
  const [isRefresh, setIsRefresh] = useState(true);

  const generateQR = () => {
    setIsRefresh(false);
    setTimeout(() => {
      setIsRefresh(true);
    }, 40000);
  };
  useEffect(() => {
    generateQR();
  }, []);
  return (
    <div className="qr-code-wrapper">
      {isRefresh ? (
        <>
          <div className="qr-code-refresh">
            <Button
              linkPrimary
              onClick={generateQR}
              text={<Icon  icon={<RefreshIcon />} gray={undefined} />}
            />
          </div>
          <CustomImage
            src={QRScanImg}
            alt={"QR Image"}
            width={130}
            height={130}
          />
        </>
      ) : (
        <CustomImage src={qrImage} alt={"QR Image"} width={130} height={130} />
      )}
    </div>
  );
};

export default QRCodeCard;
