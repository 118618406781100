import React from 'react'

const ChevronRightIcon = ({width, height, opacity, up, left}) => {
    return (
        <svg
            style={{transform: left ? 'rotate(90deg)' : up ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform ease-in 0.2s'}} 
            width={width} 
            height={height} 
            viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity={opacity} d="M6 5.26252C6 5.45116 5.92797 5.63978 5.78422 5.78361L1.25832 10.3095C0.970413 10.5974 0.503627 10.5974 0.21584 10.3095C-0.0719467 10.0217 -0.0719467 9.55498 0.21584 9.26706L4.22061 5.26252L0.21598 1.25796C-0.0718069 0.970059 -0.0718069 0.503413 0.21598 0.21565C0.503767 -0.0723944 0.970553 -0.0723944 1.25846 0.21565L5.78436 4.74143C5.92814 4.88533 6 5.07395 6 5.26252Z" fill="currentColor"/>
        </svg>
    )
}

export default ChevronRightIcon

ChevronRightIcon.defaultProps = {
    width: '6',
    height: '11',
    opacity: '1'
}