
import React from 'react'

const BankIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 35 32" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M30.0532 10.6796L17.6204 4.47852L5.18799 10.6796V13.0812H30.0532V10.6796Z" 
                fill="#3D6CB4"
            />
            <path 
                d="M33.3447 11.4262L34.988 8.9119L17.528 0.006232L17.5261 0.00958755L17.5199 0L0 8.93587L1.64327 11.4501L17.5175 3.35317L33.3447 11.4262Z" 
                fill="#3D6CB4"
            />
            <path 
                d="M33.6127 30.1738H2.25781V31.4945H33.6127V30.1738Z" 
                fill="#3D6CB4"
            />
            <path 
                d="M32.4052 26.5317H3.46533V29.5144H32.4052V26.5317Z" 
                fill="#3D6CB4"
            />
            <path 
                d="M24.1675 25.622H28.4099L27.9473 13.8301H24.6305L24.1675 25.622Z" 
                fill="#3D6CB4"
            />
            <path 
                d="M15.6108 25.622H19.8532L19.3902 13.8301H16.0734L15.6108 25.622Z" 
                fill="#3D6CB4"
            />
            <path 
                d="M7.0542 25.622H11.2966L10.8335 13.8301H7.51727L7.0542 25.622Z" 
                fill="#3D6CB4"
            />
        </svg>
    )
}

export default BankIcon

BankIcon.defaultProps = {
    width: '35',
    height: '32',
}