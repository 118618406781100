import React from "react";
import IconTextDescCard from "../../../components/iconTextDescCard";
import MyProjectImg from "../../../assets/img/kyc_pending.png";
import { useNavigate } from "react-router-dom";

const AccountUnderVerification = (props) => {
  const navigate = useNavigate();
  return (
    <IconTextDescCard
      {...props}
      src={MyProjectImg}
      text="Account under verification"
      description="We will get back to you via text message once your account has been verified."
      btnText="Okay"
      onClick={() => {
        navigate("/profile");
      }}
    />
  );
};

export default AccountUnderVerification;
