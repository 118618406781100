import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../include/layout';
import AboutusView from './views/AboutusView';

const AboutUs = () => {
  useEffect(() => {
    document.body.classList.add('aboutus-page');
    return () => {
      document.body.classList.remove('aboutus-page');
    };
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Discover Our Story and Mission - About PayOnRamp</title>
        <meta
          name="description"
          content="Explore the journey and mission of PayOnRamp. Learn about our company's story, values, and commitment to delivering innovative payment solutions to empower businesses and customers alike."
        />
        <link rel="canonical" href="https://payonramp.com/about-us" />
      </Helmet>
      <AboutusView />
    </Layout>
  );
};

export default AboutUs;
