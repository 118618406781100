
import React from 'react'

const ChatIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5556 9.93056V12.2222C30.5556 17.5905 26.2743 21.9771 20.9466 22.1474L15.8178 27.2762C15.6716 27.4225 15.4764 27.5 15.2778 27.5C15.1792 27.5 15.0799 27.4809 14.9852 27.4419C14.6995 27.3235 14.5139 27.0451 14.5139 26.7361V22.1528H9.93056C4.455 22.1528 0 17.6982 0 12.2222V9.93056C0 4.45462 4.455 0 9.93056 0H20.625C26.1006 0 30.5556 4.45462 30.5556 9.93056Z" fill="currentColor"/>
        </svg>
    )
}

export default ChatIcon

ChatIcon.defaultProps = {
    width: '31',
    height: '28',
}