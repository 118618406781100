import React from 'react'

const SearchIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 20 20" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M17.826 18.9242L17.8264 18.9246C17.9722 19.0693 18.1694 19.1504 18.3749 19.15C18.5803 19.1496 18.7773 19.0678 18.9226 18.9226C19.0678 18.7773 19.1496 18.5803 19.15 18.3749C19.1504 18.1694 19.0693 17.9722 18.9246 17.8264L18.9242 17.826L14.8542 13.756C16.1159 12.2176 16.7517 10.2578 16.6307 8.26842C16.507 6.23292 15.6007 4.32419 14.1016 2.94175C12.6024 1.55932 10.6266 0.81035 8.58781 0.851619C6.54898 0.892889 4.60514 1.7212 3.16317 3.16317C1.7212 4.60514 0.892889 6.54898 0.851619 8.58781C0.81035 10.6266 1.55932 12.6024 2.94175 14.1016C4.32419 15.6007 6.23292 16.507 8.26842 16.6307C10.2578 16.7517 12.2176 16.1159 13.756 14.8542L17.826 18.9242ZM2.44222 8.76707C2.44223 7.51615 2.81318 6.29331 3.50816 5.2532C4.20315 4.21307 5.19097 3.40239 6.34669 2.92367C7.50242 2.44496 8.77415 2.3197 10.0011 2.56375C11.228 2.8078 12.355 3.41019 13.2395 4.29474C14.1241 5.1793 14.7265 6.30629 14.9705 7.53321C15.2146 8.76012 15.0893 10.0318 14.6106 11.1876C14.1319 12.3433 13.3212 13.3311 12.2811 14.0261C11.241 14.7211 10.0182 15.092 8.7673 15.0921C7.09036 15.0902 5.48264 14.4232 4.29686 13.2374C3.11111 12.0517 2.44412 10.444 2.44222 8.76707Z" 
                fill="currentColor" 
                stroke="currentColor" 
                strokeWidth="0.3"
            />
        </svg>
    )
}

export default SearchIcon

SearchIcon.defaultProps = {
    width: '20',
    height: '20'
}