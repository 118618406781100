import { createSlice } from "@reduxjs/toolkit";

const depositReducerState = {
  isLoading: false,
  theme: null,
  conversionRate: 0,
  otpSent: false,
  otpReferenceKey: null,
  qrData: null,
  cryptoTransferRequest: "PENDING",
  transactionDetails: null,
  bankDetails: null,
  kycKeys: null,
  paymentOptions: null,
  errorMessage: null,
  walletDetails: null,
  nativeTokenName: null,
  userTransactionDetails: null,
  userWithdrawTransactions: null,
  paymentMethodIsLoading: false,
  paypalTransactionId: "",
  supportDetails: null,
  trustlyPaymentKeys: null,
  orderTransactionDetails: null,
};

export const userSlice = createSlice({
  name: "depositReducer",
  initialState: depositReducerState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCurrentTheme: (state, action) => {
      state.theme = action.payload;
    },
    upadteTransactionDetailsFetch: (state, action) => {
      state.isLoading = true;
    },
    upadteTransactionDetailsSuccess: (state) => {
      state.isLoading = false;
    },
    upadteTransactionDetailsFailure: (state) => {
      state.isLoading = false;
    },

    addPersonalInfoFetch: (state, action) => {},
    addPersonalInfoSuccess: (state) => {
      state.isLoading = false;
    },
    addPersonalinfoFailure: (state) => {
      state.isLoading = false;
    },

    // get QR Code
    getQRCodeFetch: (state, action) => {
      state.paymentMethodIsLoading = true;
    },
    getQRCodeSuccess: (state, action) => {
      state.qrData = action.payload;
      state.paymentMethodIsLoading = false;
    },
    getQRCodeFailure: (state) => {
      state.paymentMethodIsLoading = false;
      state.qrData = null;
    },

    bankDetailsFetch: (state, action) => {
      state.paymentMethodIsLoading = true;
    },
    bankDetailsSuccess: (state, action) => {
      state.paymentMethodIsLoading = false;
      state.bankDetails = action.payload;
    },
    bankDetailsFailure: (state) => {
      state.paymentMethodIsLoading = false;
    },

    updatCryptoTransferRequest: (state, action) => {
      state.cryptoTransferRequest = action.payload;
    },

    utrTransferFetch: (state, action) => {
      state.isLoading = true;
    },
    utrTransferSuccess: (state, action) => {
      state.transactionDetails = action.payload;
      state.qrData = null;
      state.bankDetails = null;
      state.walletDetails = null;
    },
    utrTransferFailure: (state) => {
      state.isLoading = false;
    },

    // fetch kyc keys
    kycKeysFetch: (state, action) => {
      state.isLoading = true;
    },
    kycKeysSuccess: (state, action) => {
      state.isLoading = false;
      state.kycKeys = action.payload;
    },
    kycKeysFailure: (state) => {
      state.isLoading = false;
      state.kycKeys = null;
    },

    updateKycStatusFetch: (state, action) => {
      state.isLoading = true;
    },
    updateKycStatusSuccess: (state, action) => {
      state.isLoading = false;
      state.kycKeys = null;
    },
    updateKycStatusFailure: (state) => {
      state.isLoading = false;
      state.kycKeys = null;
    },

    verifyTransactionFetch: (state, action) => {
      state.isLoading = false;
    },
    verifyTransactionSuccess: (state, action) => {
      state.isLoading = false;
      state.transactionDetails = action.payload;
    },
    verifyTransactionFailure: (state, action) => {
      state.isLoading = false;
      state.transactionDetails = action.payload;
    },

    paymentOptionsFetch: (state, action) => {
      state.isLoading = true;
    },
    paymentOptionsSuccess: (state, action) => {
      state.isLoading = false;
      state.paymentOptions = {
        UPI: action.payload?.UPI,
        BANK_TRANSFER: action.payload?.BANKTRANSFER,
        DEBIT_CREDIT: action.payload?.CARDPAYMENT,
        INTERNET_BANKING: action.payload?.INTERNETBANKING,
        CRYPTO: action.payload?.CRYPTO,
        PAYPAL: action.payload?.PAYPAL ? action.payload?.PAYPAL : false,
      };
      // state.conversionRate = action.payload?.exchangeRate;
      // state.nativeTokenName = action.payload?.nativeTokenName;
    },
    paymentOptionFailure: (state) => {
      state.isLoading = false;
      state.paymentOptions = null;
    },

    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },

    initiateTransactionFetch: (state, action) => {
      state.isLoading = true;
    },
    initiatTransactionSuccess: (state, action) => {
      state.isLoading = false;
    },
    initiatTransactionFailure: (state) => {
      state.isLoading = false;
    },

    walletDetailsFetch: (state, action) => {
      state.paymentMethodIsLoading = true;
    },
    walletDetailsSuccess: (state, action) => {
      state.paymentMethodIsLoading = false;
      const { networkId, networkName, address, tokenAddress } =
        action.payload;
      state.walletDetails = {
        chainId: parseInt(networkId),
        network: networkName,
        walletAddress: address,
        contractAddress: tokenAddress,
      };
    },
    walletDetailsFailure: (state) => {
      state.isLoading = true;
    },

    verifyCryptoDepositFetch: (state, action) => {
      state.isLoading = true;
    },
    verifyCryptoDepositSuccess: (state, action) => {
      state.transactionDetails = action.payload;
      state.qrData = null;
      state.bankDetails = null;
      state.walletDetails = null;
    },
    verifyCryptoDepositFailure: (state) => {
      state.isLoading = false;
    },

    verifyTransferRequestFetch: (state, action) => {
      state.isLoading = true;
    },
    verifyTransferRequestSuccess: (state, action) => {
      state.isLoading = true;
    },
    verifyTransferRequestFailure: (state) => {
      state.isLoading = true;
    },
    getMobileOTPFetch: (state, action) => {
      state.isLoading = true;
    },
    getMobileOTPSuccess: (state) => {
      state.isLoading = false;
    },
    getMobileOTPFailure: (state) => {
      state.isLoading = false;
    },
    getTransactionListFetch: (state, action) => {
      state.isLoading = true;
    },
    getTransactionListSuccess: (state, action) => {
      state.isLoading = false;
      state.userTransactionDetails = action.payload;
    },
    getTransactionListFailure: (state) => {
      state.isLoading = false;
    },
    userWithdrawTransactionsFetch: (state, action) => {
      state.isLoading = true;
    },
    userWithdrawTransactionsSuccess: (state, action) => {
      state.isLoading = true;
      state.userWithdrawTransactions = action.payload;
    },
    userWithdrawTransactionsFailure: (state) => {
      state.isLoading = true;
    },
    acknowledgeCryptoWithdrawRequestFetch: (state, action) => {
      state.isLoading = true;
    },
    acknowledgeCryptoWithdrawRequestSuccess: (state) => {
      state.isLoading = true;
    },
    acknowledgeCryptoWithdrawRequestFailure: (state) => {
      state.isLoading = true;
    },

    acknowledgeINRWithdrawRequestFetch: (state, action) => {
      state.isLoading = true;
    },
    acknowledgeINRWithdrawRequestSuccess: (state) => {
      state.isLoading = true;
    },
    acknowledgeINRWithdrawRequestFailure: (state) => {
      state.isLoading = true;
    },

    paypalPaymentCreate: (state, action) => {
      state.isLoading = true;
    },
    paypalPaymentCreateSuccess: (state, action) => {
      state.isLoading = false;
      state.paypalTransactionId = action.payload.id;
    },
    paypalPaymentCreateFailure: (state) => {
      state.isLoading = false;
    },
    sendSupportFetch: (state, action) => {
      state.isLoading = true;
    },
    sendSupportSuccess: (state, action) => {
      state.isLoading = false;
      state.supportDetails = action.payload;
    },
    sendSupportFailure: (state) => {
      state.isLoading = false;
    },
    trustlyPaymentCreate: (state, action) => {
      state.isLoading = true;
    },
    trustlyPaymentCreateSuccess: (state, action) => {
      state.isLoading = false;
      state.trustlyPaymentKeys = action.payload;
    },
    trustlyPaymentCreateFailure: (state) => {
      state.isLoading = false;
      state.trustlyPaymentKeys = null;
    },

    clearTrustlyPaymentKeys: (state) => {
      state.trustlyPaymentKeys = null;
    },

    updateTransactionData: (state, action) => {
      state.transactionDetails = action.payload;
    },
    transactionDetailsFetch: (state, action) => {
      state.isLoading = true;
    },
    transactionDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.userTransactionDetails = action.payload;
    },
    transactionDetailsFailure: (state, action) => {
      state.isLoading = false;
      state.userTransactionDetails = null;
    },
  },
});

export const {
  setLoading,
  setCurrentTheme,

  getPhoneNumberOtpFetch,
  getPhoneNumberOtpSuccess,
  getPhoneNumberOtpFailure,

  verifyOtpFetch,
  verifyOtpSuccess,
  verifyOtpFailure,

  getDepositOtpFetch,
  getDepositOtpSuccess,
  getDepositOtpFailure,

  verifyDepositOtpFetch,
  verifyDepositOtpSuccess,
  verifyDepositOtpFailure,

  upadteTransactionDetailsFetch,
  upadteTransactionDetailsSuccess,
  upadteTransactionDetailsFailure,

  addPersonalInfoFetch,
  addPersonalInfoSuccess,
  addPersonalinfoFailure,

  getQRCodeFetch,
  getQRCodeSuccess,
  getQRCodeFailure,

  updatCryptoTransferRequest,

  utrTransferFetch,
  utrTransferSuccess,
  utrTransferFailure,

  bankDetailsFetch,
  bankDetailsFailure,
  bankDetailsSuccess,

  kycKeysFetch,
  kycKeysSuccess,
  kycKeysFailure,

  updateKycStatusFetch,
  updateKycStatusSuccess,
  updateKycStatusFailure,

  verifyTransactionFetch,
  verifyTransactionSuccess,
  verifyTransactionFailure,

  paymentOptionsFetch,
  paymentOptionsSuccess,
  paymentOptionFailure,

  setErrorMessage,

  walletDetailsFetch,
  walletDetailsSuccess,
  walletDetailsFailure,

  verifyCryptoDepositFetch,
  verifyCryptoDepositSuccess,
  verifyCryptoDepositFailure,

  verifyTransferRequestFetch,
  verifyTransferRequestSuccess,
  verifyTransferRequestFailure,

  getMobileOTPFetch,
  getMobileOTPSuccess,
  getMobileOTPFailure,

  getTransactionListFetch,
  getTransactionListSuccess,
  getTransactionListFailure,

  userWithdrawTransactionsFetch,
  userWithdrawTransactionsSuccess,
  userWithdrawTransactionsFailure,

  acknowledgeINRWithdrawRequestFetch,
  acknowledgeINRWithdrawRequestSuccess,
  acknowledgeINRWithdrawRequestFailure,

  acknowledgeCryptoWithdrawRequestFetch,
  acknowledgeCryptoWithdrawRequestSuccess,
  acknowledgeCryptoWithdrawRequestFailure,

  paypalPaymentCreate,
  paypalPaymentCreateSuccess,
  paypalPaymentCreateFailure,

  sendSupportFetch,
  sendSupportFailure,
  sendSupportSuccess,

  trustlyPaymentCreate,
  trustlyPaymentCreateFailure,
  trustlyPaymentCreateSuccess,

  clearTrustlyPaymentKeys,
  updateTransactionData,

  transactionDetailsFetch,
  transactionDetailsFailure,
  transactionDetailsSuccess,
} = userSlice.actions;

export default userSlice.reducer;
