import React from 'react'

const FileUserIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M22.6665 2.83398L29.7499 9.91732V29.7623C29.7499 30.5383 29.1196 31.1673 28.3426 31.1673H5.65719C4.87995 31.1673 4.24988 30.5223 4.24988 29.7623V4.23903C4.24988 3.46306 4.88022 2.83398 5.65719 2.83398H22.6665ZM16.9999 16.2923C18.9559 16.2923 20.5415 14.7066 20.5415 12.7507C20.5415 10.7946 18.9559 9.20898 16.9999 9.20898C15.0439 9.20898 13.4582 10.7946 13.4582 12.7507C13.4582 14.7066 15.0439 16.2923 16.9999 16.2923ZM10.6638 24.084H23.3359C22.9836 20.8965 20.2813 18.4173 16.9999 18.4173C13.7185 18.4173 11.0161 20.8965 10.6638 24.084Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default FileUserIcon

FileUserIcon.defaultProps = {
    width: '34',
    height: '34'
}