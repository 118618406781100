import React from 'react'

const HistoryFillIcon = ({width, height}) => {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 34 34" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M17 2.83398C24.824 2.83398 31.1667 9.17661 31.1667 17.0007C31.1667 24.8246 24.824 31.1673 17 31.1673C9.176 31.1673 2.83337 24.8246 2.83337 17.0007H5.66671C5.66671 23.2599 10.7408 28.334 17 28.334C23.2593 28.334 28.3334 23.2599 28.3334 17.0007C28.3334 10.7414 23.2593 5.66732 17 5.66732C13.5096 5.66732 10.3877 7.24524 8.30868 9.72668L11.3334 12.7507H2.83337V4.25065L6.29933 7.7165C8.89674 4.72536 12.7275 2.83398 17 2.83398ZM18.4167 9.91732L18.4164 16.4127L23.0104 21.0076L21.0069 23.011L15.5831 17.5857L15.5834 9.91732H18.4167Z" 
                fill="currentColor"
            />
        </svg>
    )
}

export default HistoryFillIcon

HistoryFillIcon.defaultProps = {
    width: '34',
    height: '34'
}