import { createSlice } from "@reduxjs/toolkit";

const withdrawalReducerState = {
  isLoading: false,
  otpSent: false,
  otpReferenceKey: null,
  bankDetails: null,
  transactionDetails: null,
  otpLoading: false,
  withdrawalRequest: "PENDING",
  bankAccountsDetails: null,
  UserLoginDetails: {},
  tickets: []
};

export const withdrawalSlice = createSlice({
  name: "withdrawalReducer",
  initialState: withdrawalReducerState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    initiateTransactionFetch: (state, action) => {
      state.isLoading = true;
    },
    initiatTransactionSuccess: (state, action) => {
      state.isLoading = false;
    },
    initiatTransactionFailure: (state) => {
      state.isLoading = false;
    },
    getPhoneNumberOtpFetch: (state, action) => {
      state.isLoading = true;

    },
    getPhoneNumberOtpSuccess: (state, action) => {
      state.otpSent = true;
      state.isLoading = false;
      state.otpReferenceKey = action.payload;
    },
    getPhoneNumberOtpFailure: (state) => {
      state.otpSent = false;
      state.otpReferenceKey = null;
      state.isLoading = false;
    },

    verifyOtpFetch: (state, action) => {
      state.isLoading = true;
    },
    verifyOtpSuccess: (state, action) => {
      state.isLoading = false;
    },
    verifyOtpFailure: (state) => {
      state.isLoading = false;
    },

    addBankAccountsFetch: (state, action) => {
      state.isLoading = true;
    },
    addBankAccountsSuccess: (state) => {
      state.isLoading = false;
    },
    addBankAccountsFailure: (state) => {
      state.isLoading = false;
    },

    getBankAccountsListFetch: (state, action) => {
      state.isLoading = true;
    },
    getBankAccountsListSuccess: (state, action) => {
      state.isLoading = false;
      state.bankAccountsDetails = action.payload;
    },
    getBankAccountsListFailure: (state) => {
      state.isLoading = false;
    },
    verifyCryptoDepositFetch: (state, action) => {
      state.isLoading = true;
    },
    verifyCryptoDepositSuccess: (state, action) => {
      state.transactionDetails = action.payload;
      state.qrData = null;
      state.bankDetails = null;
      state.walletDetails = null;
      state.isLoading = false;

    },
    verifyCryptoDepositFailure: (state) => {
      state.isLoading = false;
    },
    verifyBankAccountsFetch: (state, action) => {
      state.isLoading = true;
    },
    verifyBankAccountsSuccess: (state) => {
      state.isLoading = false;
    },
    verifyBankAccountsFailure: (state) => {
      state.isLoading = false;
    },
    addTicket: (state) => {
      state.isLoading = true;
    },
    addTicketSuccess: (state) => {
      state.isLoading = false;
    },
    addTicketFailure: (state) => {
      state.isLoading = false;
    },
    fetchTickets: (state) => {
      state.isLoading = true;
    },
    fetchTicketsSuccess: (state, action) => {
      state.tickets = action.payload;
      state.isLoading = false;
    },
    fetchTicketsFailure: (state) => {
      state.isLoading = false;
    },

    validateVoucherFetch:(state, action) => { 
    },
  },
});

export const {
  setLoading,

  initiatTransactionFailure,
  initiatTransactionSuccess,
  initiateTransactionFetch,

  getPhoneNumberOtpFailure,
  getPhoneNumberOtpFetch,
  getPhoneNumberOtpSuccess,

  verifyOtpFailure,
  verifyOtpFetch,
  verifyOtpSuccess,

  addBankAccountsFailure,
  addBankAccountsFetch,
  addBankAccountsSuccess,

  getBankAccountsListFetch,
  getBankAccountsListFailure,
  getBankAccountsListSuccess,

  verifyCryptoDepositFailure,
  verifyCryptoDepositFetch,
  verifyCryptoDepositSuccess,

  verifyBankAccountsFetch,
  verifyBankAccountsSuccess,
  verifyBankAccountsFailure,

  addTicket,
  addTicketSuccess,
  addTicketFailure,

  fetchTickets,
  fetchTicketsSuccess,
  fetchTicketsFailure,

  validateVoucherFetch,
} = withdrawalSlice.actions;

export default withdrawalSlice.reducer;
